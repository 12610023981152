import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../images/logo.svg";
import useDeviceCategory from "../../utils/useDimensions";
import { MobileNavBar } from "./MobileNavBar";
import "./style.css";
import venzo_home_logo from "../../images/venzo_home_logo_mobile.svg";
import { motion, sync, useCycle } from "framer-motion";

const navconfig = [
  {
    path: "/",
    label: "Home",
    key: "home",
  },
  {
    path: "product-development-company",
    label: "Product Development",
    key: "product-development-company",
  },
  {
    path: "app-development-company",
    label: "App Development",
    key: "app-development-company",
  },
  {
    path: "about-us",
    label: "Company",
    key: "about-us",
  },
  {
    path: "contact-us",
    label: "Contact",
    key: "contact-us",
  },
];
function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const deviceCategory = useDeviceCategory();
  const [isOpen, toggleOpen] = useCycle(false, true);

  const goto = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  const conditionBasedLogo = location.pathname !== "/";

  const headerClassName = `nav-header w-100 d-flex justify-content-between align-items-center ${
    conditionBasedLogo ? "" : "home-background-mobile"
  }`;

  if (deviceCategory === "mobile" || deviceCategory === "tablet") {
    return (
      <div className={headerClassName}>

        {/* <a href="/">
          <img src={conditionBasedLogo ? logo : isOpen ? logo : venzo_home_logo } className={conditionBasedLogo ? "nav-logo" : isOpen ? "nav-logo" : "venzo-home-mobile-logo" } alt="logo" /> */}

        <a href="/" onClick={(e) => goto(e, "/")}>
          <img
            src={conditionBasedLogo ? logo :  isOpen ? logo : venzo_home_logo}
            className={
              conditionBasedLogo ? "nav-logo" : isOpen ? "nav-logo" : "venzo-home-mobile-logo"
            }
            alt="logo"
          />

        </a>
        <div className="" style={{ fontSize: 40, cursor: "pointer" }}>
          <MobileNavBar navconfig={navconfig}  isOpen={isOpen} toggleOpen={toggleOpen} />
        </div>
      </div>
    );
  }

  return (
    <div className="nav-header w-100 d-flex justify-content-between align-items-center">
      <a href="/" onClick={(e) => goto(e, "/")}>
        <img src={logo} className="nav-logo" alt="logo" />
      </a>
      <div className="d-flex justify-content-start align-items-center gap-4 font-size-14 text-color-light-nav">
        {navconfig.map((n) => (
          <a
            key={n.path}
            href={n.path}
            onClick={(e) => goto(e, n.path)}
            className={location.pathname.includes(n.key) ? "active" : ""}
          >
            {n.label}
          </a>
        ))}
      </div>
    </div>
  );
}

export default Navbar;
