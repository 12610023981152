import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
// import caseStudyImg from "../../../images/caseStudiesImg.png";
import caseStudyImg from "../../../images/iot_image_lap.svg";
import CarvaganImage from "../../../images/carvagan_full_images.png";
import CaseStudiesViews from "./CaseStudiesViews";
import { useNavigate } from "react-router-dom";

SwiperCore.use([Navigation, Pagination]);

function CaseStudiesSlider() {
  const [isNextButtonClicked, setNextButtonClicked] = useState(false);
  const [isPrevButtonClicked, setPrevButtonClicked] = useState(false);
  const [showExploreButton, setShowExploreButton] = useState(false);
  const [showRemainingSlides, setShowRemainingSlides] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(2);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const handleNextButtonClick = () => {
    setNextButtonClicked(true);
    setPrevButtonClicked(false);
  };

  const handlePrevButtonClick = () => {
    setPrevButtonClicked(true);
    setNextButtonClicked(false);
  };
  const handleExploreButtonClick = () => {
    setShowRemainingSlides(!showRemainingSlides);
    //   const windowWidth = window.innerWidth;

    // if (windowWidth < 768) {
    //   setShowRemainingSlides(!showRemainingSlides);
    // }
  };

  const handleCaseStudies = (e) => {
   // navigate("/pages/pfm");
   e.preventDefault();
    window.open("/case-studies/healthcare-iot-for-smarter-device-monitoring", "_blank");
  };

  const handleCaseStudiesCarvagon = (e) => {
    //navigate("/pages/carvagan");
    e.preventDefault();
     window.open("/case-studies/Enhanced-user-engagement-to-boost-revenue", "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setIsMobile(window.innerWidth < 1200);
      if (windowWidth >= 1200) {
        setSlidesPerView(2);
        setShowExploreButton(false);
        setShowRemainingSlides(true);
      } else {
        setSlidesPerView(2);
        setShowExploreButton(true);
        setShowRemainingSlides(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {!isMobile ? (
        <div className="card-slider-container">
          <div className="row pb-3">
            <div className="col-lg-6 col-sm-12 col-xs-12 px-5">
              <h1
                style={{ fontSize: "45px", fontWeight: "500", font: "lexend" }}
                className="case-studies-heading"
              >
                Case Studies
              </h1>
            </div>
            <div className="col-6 px-5 swiper-slider-view-button">
              {/* <div className="swiper-navigation d-flex justify-content-end">
                <div className="">
                  {" "}
                  <button
                    className={`custom-button-prev custom-prev-button ${
                      isPrevButtonClicked ? "disabled" : ""
                    }`}
                    onClick={handlePrevButtonClick}
                    disabled={isPrevButtonClicked}
                  >
                    &#x2190;
                  </button>
                </div>
                <div className="">
                  {" "}
                  <button
                    className={`custom-button-next custom-next-button ${
                      isNextButtonClicked ? "disabled" : ""
                    }`}
                    onClick={handleNextButtonClick}
                    disabled={isNextButtonClicked}
                  >
                    &rarr;
                  </button>
                </div>
              </div> */}
            </div>
          </div>

          <Swiper
            navigation={{
              nextEl: ".custom-button-next",
              prevEl: ".custom-button-prev",
            }}
            //pagination={{ clickable: true }}
            slidesPerView={slidesPerView}
            spaceBetween={10}
            className="no-swiping"
          >
            <SwiperSlide>
              <div className="card" style={{ border: "none" }}>
                <div className="card-body text-start">
                  <div className="row">
                    <div className="col-10">
                      <h5 className="card-title card-title_view">Healthcare IoT for Smarter Device Monitoring</h5>
                    </div>
                    <div className="col-2 text-end">
                      <h5 className="card-title">01</h5>
                    </div>
                    {/* <div className="card-title text-end">{card.title}</div> */}
                  </div>

                  <p className="card-text card-text-view">
                      Our client is a prominent healthcare entity, manufacturing and trading medical equipment and devices. Their excellence extends to driving technology-forward solutions in the health and wellness sector.

                  </p>
                  <a
                    href=""
                    style={{ textDecoration: "none" }}
                    onClick={handleCaseStudies}
                   // target="_blank"
                  >
                    Learn More &rarr;
                  </a>
                </div>
                <div className="row" style={{ marginTop: "-9px" }}>
                  <div className="col">
                    {" "}
                    <img
                      src={caseStudyImg}
                      className="img_View"
                      alt="1"
                      style={{ width: "310px", paddingBottom: "2px" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide-carvagan">
              <div className="card" style={{ border: "none" }}>
                <div className="card-body text-start">
                  <div className="row">
                    <div className="col-10 ">
                      <h5 className="card-title card-title_view">Enhanced User Engagement to Boost Revenue</h5>
                    </div>
                    <div className="col-2 text-end">
                      <h5 className="card-title">02</h5>
                    </div>
                    {/* <div className="card-title text-end">{card.title}</div> */}
                  </div>

                  <p className="card-text card-text-view">
                      Our client is a pivotal part of the automotive industry, offering a dynamic platform that forges connections between vehicle users, service centers, specialized mechanics, and third-party service providers.
                  </p>
                  <a
                    href=""
                    style={{ textDecoration: "none" }}
                    onClick={handleCaseStudiesCarvagon}
                   // target="_blank"
                  >
                    Learn More &rarr;
                  </a>
                </div>
                <div className="row d-flex justify-content-center" style={{ marginTop: "-9px" }}>
                  <div className="col">
                    {" "}
                    <img
                      src={CarvaganImage}
                      className="img_View"
                      alt="1"
                      style={{ width: "310px" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* {showRemainingSlides && (
              <>
                <SwiperSlide>
                  <div className="card" style={{ border: "none" }}>
                    <div className="card-body text-start">
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="card-title card-title_view">Card 3</h5>
                        </div>
                        <div className="col-6 text-end">
                          <h5 className="card-title">03</h5>
                        </div>
                        
                      </div>

                      <p className="card-text card-text-view">
                        Our company boasts a talented team of experts
                        encompassing a wide range of skill sets, including
                        developers, designers, business analysts, and more
                      </p>
                      <a href="#" style={{ textDecoration: "none" }}>
                        Learn More &rarr;
                      </a>
                    </div>
                    <div className="row" style={{ marginTop: "-9px" }}>
                      <div className="col-xl-6 offset-xl-5">
                        {" "}
                        <img
                          src={caseStudyImg}
                          className="img_View"
                          alt="1"
                          style={{ width: "274px", paddingBottom: "2px" }}
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </>
            )} */}
          </Swiper>
          {showExploreButton && (
            <div className="text-center pt-4 pb-3">
              <button
                className="explore-button-view"
                onClick={handleExploreButtonClick}
              >
                Explore more courses &rarr;
              </button>
            </div>
          )}
        </div>
      ) : (
        <CaseStudiesViews />
      )}
    </div>
  );
}

export default CaseStudiesSlider;
