import dotted_vector from "../../../images/dotted_vector.png";

export default function Phase5(props) {
  const { openPopUp } = props;
  return (
    <div className="d-flex justify-content-space-between align-items-center gap-lg-5">
      <div className="phase-vector">
        <img alt="dotted_vector" src={dotted_vector} />
      </div>
      <div>
        <div className="phase-title">Validate & Iterate </div>
        <div className="phase-summary">
          <ul>
            <li>Collect Metrics</li>
            <li>Customer Behavior Study</li>
            <li>Strategize</li>
            <li>Iterate</li>
            {/* <li>High Concept Pitch</li> */}
          </ul>
          <button
            type="button"
            className="btn bg-transparent product-ctc-button" onClick={openPopUp}> Analyze Your Product Performance  &gt;</button>
        </div>
      </div>
    </div>
  );
}
