import React, { useState, useEffect, useRef } from "react";
import dnaImage_1 from "../../../images/dnaImage_1.png";
import dnaImage_2 from "../../../images/dnaImage_2.png";
import dnaImage_3 from "../../../images/dnaImage_3.png";
import dnaImage_4 from "../../../images/dnaImage_4.png";
import StrokeVector from "../../../images/stroke_vector.svg";
import ReactCardCarousel from "react-card-carousel";
import useDeviceCategory from "../../../utils/useDimensions";

function VenzoDna() {
  const names = [
    "Customer-Centric ",
    "Quality Assurance",
    "Expert Team",
    "Innovation",
    "Reliability",
  ];

  const descriptions = [
    "Your vision is our blueprint",
    "Excellence is non-negotiable.",
    "A league of extraordinary minds.",
    "Where challenges meet breakthroughs.",
    "Your trust, our unwavering promise.",
  ];

  //const images = [dnaImage_1, dnaImage_2, dnaImage_3, companyVideo, dnaImage_1 ];

  const [activeIndex, setActiveIndex] = useState(0);
  const ref = useRef(null);
  const deviceCategory = useDeviceCategory();

  useEffect(() => {
    const interval = setInterval(() => {
      // ref.current.goTo(activeIndex + 1);
      setActiveIndex((prevIndex) => (prevIndex + 1) % names.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleNameClick = (index) => {
    setActiveIndex(index);
  };

  let cardHeight = "210px",
    cardWidth = "350px";
  if (deviceCategory === "desktop") {
    cardHeight = "391px";
    cardWidth = "647px";
  } else if (deviceCategory === "tablet" || deviceCategory === "laptop") {
    cardHeight = "100%";
    cardWidth = "100%";
  }

  const CONTAINER_STYLE = {
    position: "relative",
    height: "220px",
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "middle",
  };

  const CARD_STYLE = {
    height: "391px",
    width: "647px",
    // paddingTop: "80px",
    textAlign: "center",
    //background: "#52C0F5",
    color: "#FFF",
    fontFamily: "sans-serif",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "10px",
    boxSizing: "border-box",
    boxShadow: "none",
  };

  const CONTAINER_STYLE_MOBILE = {
    mobile: {
      position: "relative",
      height: "220px",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "middle",
    },
    tablet: {
      position: "relative",
      height: "360px",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "middle",
    },
  };

  const CARD_STYLE_MOBILE = {
    mobile: {
      height: "210px",
      width: "350px",
      // paddingTop: "80px",
      textAlign: "center",
      //background: "#52C0F5",
      color: "#FFF",
      fontFamily: "sans-serif",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "10px",
      boxSizing: "border-box",
      boxShadow: "none",
    },
    tablet: {
      height: "350px",
      width: "600px",
      // paddingTop: "80px",
      textAlign: "center",
      //background: "#52C0F5",
      color: "#FFF",
      fontFamily: "sans-serif",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "10px",
      boxSizing: "border-box",
      boxShadow: "none",
    },
  };
  console.log(deviceCategory);

  const getCarousel = () => {
    if (deviceCategory === "mobile" || deviceCategory === "tablet") {
      return (
        <div className="col-12 mt-5 pt-5">
          <div style={CONTAINER_STYLE_MOBILE[deviceCategory]}>
            <ReactCardCarousel
              ref={ref}
              autoplay={true}
              alignment={"vertical"}
              spread={"narrow"}
              autoplay_speed={3000}
            >
              <div style={CARD_STYLE_MOBILE[deviceCategory]}>
                <img
                  src={dnaImage_1}
                  alt="dnaImage_1 1"
                  style={{
                    width: deviceCategory === "tablet" ? "100%" : 350,
                    height: deviceCategory === "tablet" ? "100%" : 210,
                  }}
                />
              </div>
              <div style={CARD_STYLE_MOBILE[deviceCategory]}>
                <img
                  src={dnaImage_2}
                  alt="dnaImage_1 1"
                  style={{
                    width: deviceCategory === "tablet" ? "100%" : 350,
                    height: deviceCategory === "tablet" ? "100%" : 210,
                  }}
                />
              </div>
              <div style={CARD_STYLE_MOBILE[deviceCategory]}>
                <img
                  src={dnaImage_3}
                  alt="dnaImage_1 1"
                  style={{
                    width: deviceCategory === "tablet" ? "100%" : 350,
                    height: deviceCategory === "tablet" ? "100%" : 210,
                  }}
                />
              </div>
              <div style={CARD_STYLE_MOBILE[deviceCategory]}>
                <img
                  src={dnaImage_4}
                  alt="dnaImage_1 1"
                  style={{
                    width: deviceCategory === "tablet" ? "100%" : 350,
                    height: deviceCategory === "tablet" ? "100%" : 210,
                  }}
                />
              </div>
            </ReactCardCarousel>
          </div>
        </div>
      );
    }

    return (
      <div className="col-lg-8 pt-5 mt-5">
        <div style={CONTAINER_STYLE}>
          <ReactCardCarousel
            ref={ref}
            autoplay={true}
            alignment={"vertical"}
            spread={"narrow"}
            autoplay_speed={3000}
          >
            <div style={CARD_STYLE}>
              <img
                src={dnaImage_1}
                alt="Image 1"
                style={{ width: cardWidth, height: cardHeight }}
              />
            </div>
            <div style={CARD_STYLE}>
              <img
                src={dnaImage_2}
                alt="Image 1"
                style={{ width: cardWidth, height: cardHeight }}
              />
            </div>
            <div style={CARD_STYLE}>
              <img
                src={dnaImage_3}
                alt="Image 1"
                style={{ width: cardWidth, height: cardHeight }}
              />
            </div>
            <div style={CARD_STYLE}>
              <img
                src={dnaImage_4}
                alt="Image 1"
                style={{ width: cardWidth, height: cardHeight }}
              />
            </div>
          </ReactCardCarousel>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="container mt-md-5 mt-2 pt-md-5">
        <div className="row pt-md-5 pt-3">
          <div className="col-lg-4 col-12">
            <div>
              <h1 className="venzo-dna-heading">Our DNA</h1>
              <p className="venzo-dna-content">
                Agility, Innovation, and Partnership - these are the
                cornerstones of Venzo. Our DNA comprises the passion to
                innovate, the flexibility to adapt to dynamic markets, and the
                commitment to enduring partnerships.
              </p>
            </div>
            <img src={StrokeVector} className="img-fluid" alt="Main Image" />
            <div>
              <ul className="venzo-dna-list-item pt-4">
                {names.map((name, index) => (
                  <li
                    key={index}
                    //onClick={() => handleNameClick(index)}
                    className={`list-marker-view ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    <div className="marker"></div>
                    {name}
                    <div className="description">
                      <p className="venzo-dna-description">
                        {activeIndex === index ? descriptions[index] : ""}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {getCarousel()}
        </div>
      </div>
    </div>
  );
}

export default VenzoDna;
