import React from 'react';
import logo from "../../../images/logo.svg";
import in_icon from "../../../images/in_icon.svg";
import fb_icon from "../../../images/fb_icon.svg";
import insta_icon from "../../../images/insta_icon.svg";
import youtube_icon from "../../../images/youtube_icon.svg";
import Vector_CopyRights from "../../../images/Vector_CopyRights.svg";
import { useNavigate } from 'react-router-dom';

function FooterViews() {
    const navigate = useNavigate();

    const goto = (path) => {
        navigate(path);
      };
    
  return (
    <div className="">
    <div className="" >
          <div className="">
              <footer className="footer" style={{backgroundColor:'#131b2ac7'}}>				
          <div className="container">
              <div className="row">
                
                  <div className="col-5 col-sm-6 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                          About
                      </div>	
                      <div className="footer-links">
                          <a href="" onClick={() => goto("/")}>
                              Home
                          </a>
                          <a href="" onClick={() => goto("/about-us")}>
                              Company
                          </a>
                          <a href="" onClick={() => goto("/contact-us")}>
                              Contact
                          </a>
                          {/* <a href="#">
                              Wallstreet
                          </a> */}
                      </div>
                  </div>
                  <div className="col-7 col-md-3 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                          Services
                      </div>	
                      <div className="footer-links">
                          <a href="" onClick={() => goto("/product-development-company")}>
                              Product Development
                          </a>
                          <a href="" onClick={() => goto("/app-development-company")}>
                            App Development
                          </a>
                          {/* <a href="#">
                              Website Development
                          </a>
                          <a href="#">
                              ECommerce Development
                          </a> */}
                      </div>
  
                      
                  </div>
               
              </div>
              {/* <div className='row pt-3'>
             
                  <div className="col-5 col-sm-6 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                          Other Links
                      </div>	
                      <div className="footer-links">
                          <a href="#">
                              Blog
                          </a>
                          <a href="#">
                              Carrers
                          </a>
                          <a href="#">
                              Terms & conditions
                          </a>
                          <a href="#">
                              Privacy policy
                          </a>
                      </div>
                  </div>
                  <div className="col-7 col-md-2 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                          Services
                      </div>	
                      <div className="footer-links">
                          <a href="#">
                              Digital Marketing
                          </a>
                          <a href="#">
                              UX UI Design
                          </a>
                          <a href="#">
                              QA Services
                          </a>
                          <a href="#">
                              Staff Augmentation
                          </a>
                      </div>
  
                      
                  </div>
              </div> */}
              <div className='row pt-3'>
              <div className="col-md-3 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                         
                            <a href="/">
                                <img src={logo}  className="footer_logo img_View" alt="logo" />
                            </a>	
                      </div>
                      
                      {/* <p className="white-text">
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 text of the printing.
                      </p> */}
                      <div className="footer-social-links m-t-30 d-flex" >
                        {/* <li style={{marginTop:'4px'}}> <p>Follow Us</p></li> */}
                          <li style={{marginLeft:'2px'}}>
                         
                              <a href="https://www.facebook.com/VenzoTechnologies/">
                                   <img src={fb_icon} className="nav-logo" alt="logo" />
                              </a>
                              <a href="https://www.instagram.com/venzo_tech/">
                                 <img src={in_icon} className="nav-logo" alt="logo" />
                              </a>
                              <a href="https://www.linkedin.com/company/venzo-technologies/">
                                 <img src={insta_icon} className="nav-logo" alt="logo" />
                              </a>
                              <a href="https://www.youtube.com/@venzotechnologies9121">
                                  <img src={youtube_icon} className="nav-logo" alt="logo" />
                              </a>
                          </li>
                      </div>
                      <div className="footer-bottom footer_dashed-lines">
            Copyright 2024 Venzo. All Rights Reserved
      </div>
                  </div>
              </div>
          </div>
      </footer>
      {/* <div className="footer-bottom footer_dashed-lines">
            Copyright 2023 Venzo. All Rights Reserved
      </div> */}
          </div>
      </div>
  </div>
  )
}

export default FooterViews