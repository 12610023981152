import React from 'react'

function BackDrop({onClose}) {
  return (
    <div>
         <div className="backdrop-views" onClick={onClose}></div>;
    </div>
  )
}

export default BackDrop