import dotted_vector from "../../../images/dotted_vector.png";

export default function Phase4(props) {
  const { openPopUp } = props;
  return (
    <div className="d-flex justify-content-space-between align-items-center gap-lg-5">
      <div className="phase-vector">
        <img alt="dotted_vector" src={dotted_vector} />
      </div>
      <div>
        <div className="phase-title blue">Build</div>
        <div className="phase-summary">
          <ul>
            <li>Technical Architecture</li>
            <li>Sprint Planning</li>
            <li>Development</li>
            <li>QA</li>
            <li>Go Live</li>
          </ul>
          <button
            type="button"
            className="btn bg-transparent product-ctc-button" onClick={openPopUp}>Collaborate With Our Agile Experts &gt;</button>
        </div>
      </div>
    </div>
  );
}
