import React, { useState } from "react";

import Slide1 from "../components/Company/CompanySection/Slide1";
import SuccessProcess from "../components/Company/CompanySection/SuccessProcess";
import VenzoDna from "../components/Company/CompanySection/VenzoDna";
import Culture from "../components/Company/CompanySection/Culture";
import LeadershipTeam from "../components/Company/CompanySection/LeadershipTeam";
import ManagementTeam from "../components/Company/CompanySection/ManagementTeam";
import LifeOfVenzo from "../components/Company/CompanySection/LifeOfVenzo";
import LifeVenzoBg from "../images/lifeVenzo_bg_2.png";
import CurrentOpenings from "../components/Company/CompanySection/CurrentOpenings";
import Footer from "../components/DigitalTrans/TransformBusiness/Footer";
//import FirstCompany_bg from "../images/company_first_bg.svg";
import FirstCompany_bg from "../images/company-main-image-bg.svg";
import companies_bg_mobile from "../images/companies_bg_mobile.png";
import useDeviceCategory from "../utils/useDimensions";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import JobOpeningsDetails from "../components/Company/CompanySection/JobOpeningsDetails";

function Companies() {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const deviceCategory = useDeviceCategory();

  let sectionHeight =
    deviceCategory === "mobile" || deviceCategory === "tablet" ? "auto" : 620;

    let sectionHeightJobOpenings =
    deviceCategory === "mobile" || deviceCategory === "tablet" ? "auto" : 995;

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="overflow-x-hidden"
    >
      <Helmet>
        <title>About Venzo Technologies</title>
        <meta
          name="description"
          content="Venzo Technologies mission is to help businesses leverage the power of technology to drive their growth and success"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section
        className="pt-md-5 p-md-5 pt-4 p-3 p-md-4 d-md-block d-flex justify-content-center"
        style={{
          backgroundImage: `url(${
            deviceCategory === "mobile" ? companies_bg_mobile : FirstCompany_bg
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Slide1 />
      </section>
      <section
        className="pt-md-5 p-md-5 pt-4 p-4 d-md-block d-flex justify-content-center custom-tooltip-views"
        style={{ backgroundColor: "#111827" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <SuccessProcess tooltipVisible={tooltipVisible} />
      </section>
      <section
        className="pt-md-5 pt-2 p-md-5 p-1 d-md-block d-flex justify-content-center"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <VenzoDna />
      </section>
      <section className="" style={{ backgroundColor: "#FFFFFF" }}>
        <Culture />
      </section>
      <section
        className="pt-5 p-md-5 p-3 d-md-block d-flex justify-content-center"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <LeadershipTeam />
      </section>
      {/* <section
        className="pt-5 p-md-5 p-3 d-md-block d-flex justify-content-center"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <ManagementTeam />
      </section> */}
      <section
        className="d-flex justify-content-center align-items-center life-at-venzo-bg"
        style={{
          backgroundImage: `url(${LifeVenzoBg})`,
          backgroundSize: "cover",
          height: sectionHeight,
        }}
      >
        <LifeOfVenzo />
      </section>
      <section
        className="d-flex justify-content-center align-items-lg-center pb-4 pt-2"
        // style={{ backgroundColor: "#FFFFFF", height: sectionHeight - 100 }}
        style={{ backgroundColor: "#FFFFFF", height: sectionHeightJobOpenings }}
      >
        <JobOpeningsDetails />
      </section>
      <section
        className="d-flex justify-content-center align-items-lg-center pb-4"
        style={{ backgroundColor: "#FFFFFF", height: sectionHeight - 100 }}
      >
        <CurrentOpenings />
      </section>
      <section
        className="pt-5 p-5 footerViews"
        style={{ backgroundColor: "#111827" }}
      >
        <Footer />
      </section>
    </motion.div>
  );
}

export default Companies;
