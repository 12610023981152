import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
// import caseStudyImg from "../../../images/caseStudiesImg.png";
import CarvaganImage from "../../../images/ERP_case_studies.png";
import caseStudyImg from "../../../images/FITA_case_studies.png";
import CaseStudiesViews from "./CaseStudiesViews";
import { useNavigate } from "react-router-dom";
import CaseStudiesAppDevelopViews from "./CaseStudiesAppDevelopViews";

SwiperCore.use([Navigation, Pagination]);

function CaseStudiesAppDevelopSlider() {
  const [isNextButtonClicked, setNextButtonClicked] = useState(false);
  const [isPrevButtonClicked, setPrevButtonClicked] = useState(false);
  const [showExploreButton, setShowExploreButton] = useState(false);
  const [showRemainingSlides, setShowRemainingSlides] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(2);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const handleNextButtonClick = () => {
    setNextButtonClicked(true);
    setPrevButtonClicked(false);
  };

  const handlePrevButtonClick = () => {
    setPrevButtonClicked(true);
    setNextButtonClicked(false);
  };
  const handleExploreButtonClick = () => {
    setShowRemainingSlides(!showRemainingSlides);
    //   const windowWidth = window.innerWidth;

    // if (windowWidth < 768) {
    //   setShowRemainingSlides(!showRemainingSlides);
    // }
  };

  const handleCaseStudies = (e) => {
   // navigate("/pages/pfm");
   e.preventDefault();
    window.open("/case-studies/streamlining-it-training-and-Placements-a-success-story", "_blank");
  };

  const handleCaseStudiesCarvagon = (e) => {
    //navigate("/pages/carvagan");
    e.preventDefault();
     window.open("/case-studies/bespoke-erp-solution-for-leading-cookware-manufacturer", "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setIsMobile(window.innerWidth < 1200);
      if (windowWidth >= 1200) {
        setSlidesPerView(2);
        setShowExploreButton(false);
        setShowRemainingSlides(true);
      } else {
        setSlidesPerView(2);
        setShowExploreButton(true);
        setShowRemainingSlides(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {!isMobile ? (
        <div className="card-slider-container">
          <div className="row pb-3">
            <div className="col-lg-6 col-sm-12 col-xs-12 px-5">
              <h1
                style={{ fontSize: "45px", fontWeight: "500", font: "lexend" }}
                className="case-studies-heading"
              >
                Case Studies
              </h1>
            </div>
            <div className="col-6 px-5 swiper-slider-view-button">
              {/* <div className="swiper-navigation d-flex justify-content-end">
                <div className="">
                  {" "}
                  <button
                    className={`custom-button-prev custom-prev-button ${
                      isPrevButtonClicked ? "disabled" : ""
                    }`}
                    onClick={handlePrevButtonClick}
                    disabled={isPrevButtonClicked}
                  >
                    &#x2190;
                  </button>
                </div>
                <div className="">
                  {" "}
                  <button
                    className={`custom-button-next custom-next-button ${
                      isNextButtonClicked ? "disabled" : ""
                    }`}
                    onClick={handleNextButtonClick}
                    disabled={isNextButtonClicked}
                  >
                    &rarr;
                  </button>
                </div>
              </div> */}
            </div>
          </div>

          <Swiper
            navigation={{
              nextEl: ".custom-button-next",
              prevEl: ".custom-button-prev",
            }}
            //pagination={{ clickable: true }}
            slidesPerView={slidesPerView}
            spaceBetween={10}
            className="no-swiping"
          >
            <SwiperSlide>
              <div className="card" style={{ border: "none" }}>
                <div className="card-body text-start">
                  <div className="row">
                    <div className="col-10">
                      <h5 className="card-title card-title_view">Streamlining IT Training and Placements: A Success Story </h5>
                    </div>
                    <div className="col-2 text-end">
                      <h5 className="card-title">01</h5>
                    </div>
                    {/* <div className="card-title text-end">{card.title}</div> */}
                  </div>

                  <p className="card-text card-text-view">
                  Our client, a premier IT training and placement agency, has a network of directly operated and franchise centers. They offer comprehensive technology training to students both online and offline, bridging them with seasoned IT professionals as instructors.Remarkably, our client boasts an impressive portfolio... 

                  </p>
                  <a
                    href=""
                    style={{ textDecoration: "none" }}
                    onClick={handleCaseStudies}
                   // target="_blank"
                  >
                    Learn More &rarr;
                  </a>
                </div>
                <div className="row" style={{ marginTop: "-9px" }}>
                  <div className="col">
                    {" "}
                    <img
                      src={caseStudyImg}
                      className="img_View"
                      alt="1"
                      style={{ width: "310px", paddingBottom: "2px" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide-carvagan">
              <div className="card" style={{ border: "none" }}>
                <div className="card-body text-start">
                  <div className="row">
                    <div className="col-10 ">
                      <h5 className="card-title card-title_view">Bespoke ERP Solution for Leading Cookware</h5>
                    </div>
                    <div className="col-2 text-end">
                      <h5 className="card-title">02</h5>
                    </div>
                    {/* <div className="card-title text-end">{card.title}</div> */}
                  </div>

                  <p className="card-text card-text-view">
                  Our client is a prominent stainless steel cookware and utensils manufacturer with an impressive annual turnover of 50 crore. They had previously attempted to implement industry-leading ERP systems from SAP and other providers but encountered setbacks, leading them to seek a custom solution. 
                  </p>
                  
                  <a
                    href=""
                    style={{ textDecoration: "none" }}
                    onClick={handleCaseStudiesCarvagon}
                   // target="_blank"
                  >
                    Learn More &rarr;
                  </a>
                </div>
                <div className="row d-flex justify-content-center" style={{ marginTop: "-9px" }}>
                  <div className="col">
                    {" "}
                    <img
                      src={CarvaganImage}
                      className="img_View"
                      alt="1"
                      style={{ width: "310px" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* {showRemainingSlides && (
              <>
                <SwiperSlide>
                  <div className="card" style={{ border: "none" }}>
                    <div className="card-body text-start">
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="card-title card-title_view">Card 3</h5>
                        </div>
                        <div className="col-6 text-end">
                          <h5 className="card-title">03</h5>
                        </div>
                        
                      </div>

                      <p className="card-text card-text-view">
                        Our company boasts a talented team of experts
                        encompassing a wide range of skill sets, including
                        developers, designers, business analysts, and more
                      </p>
                      <a href="#" style={{ textDecoration: "none" }}>
                        Learn More &rarr;
                      </a>
                    </div>
                    <div className="row" style={{ marginTop: "-9px" }}>
                      <div className="col-xl-6 offset-xl-5">
                        {" "}
                        <img
                          src={caseStudyImg}
                          className="img_View"
                          alt="1"
                          style={{ width: "274px", paddingBottom: "2px" }}
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </>
            )} */}
          </Swiper>
          {showExploreButton && (
            <div className="text-center pt-4 pb-3">
              <button
                className="explore-button-view"
                onClick={handleExploreButtonClick}
              >
                Explore more courses &rarr;
              </button>
            </div>
          )}
        </div>
      ) : (
        <CaseStudiesAppDevelopViews />
      )}
    </div>
  );
}

export default CaseStudiesAppDevelopSlider;
