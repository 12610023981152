import React from "react";
//import companyVideo from "../../../images/company_Image.png";
import companyVideo from "../../../images/company-image-resize.svg";
import playIcon from "../../../images/play_icon.png";
import CounterUp from "./CounterUp";

function Slide1() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div class="col-md-5 d-none d-md-block"></div>
          <div className="col-12 col-md-2">
            <div className="text-center d-flex justify-content-center">
              <p className="about-company">About Company</p>
            </div>
          </div>
          <div class="col-md-5 d-none d-md-block"></div>
        </div>
        <div className="row">
          <div class="col-md-1 d-none d-md-block"></div>
          <div className="col-12 col-md-10">
            <div className="text-center">
              <h1 className="company-titles">
                Innovating Your Digital Experience
              </h1>
              <p className="company-content">
                  Welcome to Venzo, where imagination meets implementation. We relentlessly pursue excellence in crafting bespoke, innovative digital solutions that set new benchmarks for success. 
              </p>
            </div>
          </div>
          <div class="col-md-1 d-none d-md-block"></div>
        </div>
        <div className="row">
          <div className="col">
            <div className="company-videos">
              <div className="position-absolute w-100 h-100 vidoe-overlay"></div>
              <img
                src={companyVideo}
                className="img-fluid companyImage"
                alt="Main"
              />
              <img src={playIcon} className="play-icon" alt="Play Icon" />
            </div>
          </div>
        </div>
        <CounterUp />
      </div>
    </div>
  );
}

export default Slide1;
