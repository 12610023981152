import React, { useState } from "react";
//import caseStudyImg from "../../../images/caseStudiesImg.png";
import { useNavigate } from "react-router-dom";
// import caseStudyImg from "../../../images/iot_image_lap.svg";
// import CarvaganImage from "../../../images/carvagan_full_images.png";
import CarvaganImage from "../../../images/ERP_case_studies.png";
import caseStudyImg from "../../../images/FITA_case_studies.png";

const CaseStudiesAppDevelopViews = ({ cards }) => {
  const [showExploreButton, setShowExploreButton] = useState(false);
  const [showRemainingSlides, setShowRemainingSlides] = useState(false);
  const navigate = useNavigate();

  const handleExploreButtonClick = () => {
    setShowRemainingSlides(!showRemainingSlides);
  };

  const handleCaseStudies = () => {
    navigate("/case-studies/streamlining-it-training-and-Placements-a-success-story");
  };

  const handleCaseStudiesCarvagon = () => {
    navigate("/case-studies/bespoke-erp-solution-for-leading-cookware-manufacturer");
  };


  return (
    <div className="card-carousel py-3 py-md-5">
      <div className="">
        <div className="">
          <div
            style={{
              fontSize: "45px",
              fontWeight: "500",
              font: "lexend",
              lineHeight: "51.57px",
            }}
          >
            <h1 className="case-studies-heading text-center">Case Studies</h1>
          </div>
        </div>
      </div>
      <div className="row pt-3 pl-5 mx-0">
        <div
          className="card cardViewCases"
          style={{ border: "none", boxShadow: "none" }}
        >
          <div className="card-body text-start">
            <div className="row">
              <div className="col-10">
                <h5 className="card-title card-title_view">Streamlining IT Training and Placements: A Success Story</h5>
              </div>
              <div className="col-2 text-end">
                <h5 className="card-title">01</h5>
              </div>
              {/* <div className="card-title text-end">{card.title}</div> */}
            </div>

            <p className="card-text card-text-view">
            Our client, a premier IT training and placement agency, has a network of directly operated and franchise centers. They offer comprehensive technology training to students both online and offline, bridging them with seasoned IT professionals as instructors. Remarkably, our client boasts an impressive portfolio encompassing 11 branches, a roster of over 1000 trainers, and an extensive catalog of 120+ courses. 
            </p>
            <a href="" style={{ textDecoration: "none" }} onClick={handleCaseStudies}>
              Learn More &rarr;
            </a>
          </div>
          <div className="row" style={{ marginTop: "-9px" }}>
            <div className="col-xl-6 offset-xl-5 d-flex justify-content-center">
              {" "}
              <img
                src={caseStudyImg}
                className="img_View"
                alt="1"
                style={{ width: "274px", paddingBottom: "2px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-3 pl-5 mt-3 mx-0">
        <div
          className="card cardViewCases"
          style={{ border: "none", boxShadow: "none" }}
        >
          <div className="card-body text-start">
            <div className="row">
              <div className="col-10">
                <h5 className="card-title card-title_view">Bespoke ERP Solution for Leading Cookware Manufacturer </h5>
              </div>
              <div className="col-2 text-end">
                <h5 className="card-title">02</h5>
              </div>
              {/* <div className="card-title text-end">{card.title}</div> */}
            </div>

            <p className="card-text card-text-view">
            Our client is a prominent stainless steel cookware and utensils manufacturer with an impressive annual turnover of 50 crore. They had previously attempted to implement industry-leading ERP systems from SAP and other providers but encountered setbacks, leading them to seek a custom solution. 
            </p>
            <a href="" style={{ textDecoration: "none" }} onClick={handleCaseStudiesCarvagon}>
              Learn More &rarr;
            </a>
          </div>
          <div className="row" style={{ marginTop: "-9px" }}>
            <div className="col-xl-6 offset-xl-5 text-center d-flex justify-content-center">
              {" "}
              <img
                src={CarvaganImage}
                className="img_View"
                alt="1"
                style={{ width: "274px", paddingBottom: "2px" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {showRemainingSlides && (
        <>
          <div className="row pt-3 pl-5">
            <div
              className="card cardViewCases"
              style={{ border: "none", boxShadow: "none" }}
            >
              <div className="card-body text-start">
                <div className="row">
                  <div className="col-6 ">
                    <h5 className="card-title card-title_view">Card3</h5>
                  </div>
                  <div className="col-6 text-end">
                    <h5 className="card-title">03</h5>
                  </div>
                  
                </div>

                <p className="card-text card-text-view">
                  Our company boasts a talented team of experts encompassing a
                  wide range of skill sets, including developers, designers,
                  business analysts, and more
                </p>
                <a href="#" style={{ textDecoration: "none" }}>
                  Learn More &rarr;
                </a>
              </div>
              <div className="row" style={{ marginTop: "-9px" }}>
                <div className="col-xl-6 offset-xl-5 text-center d-flex justify-content-center">
                  {" "}
                  <img
                    src={caseStudyImg}
                    className="img_View"
                    alt="1"
                    style={{ width: "274px", paddingBottom: "2px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}
      {/* <div className="text-center pt-4 pb-3">
        <button
          className="explore-button-view"
          onClick={handleExploreButtonClick}
        >
          Explore more courses &rarr;
        </button>
      </div> */}
    </div>
  );
};

export default CaseStudiesAppDevelopViews;
