import excel_icon from "../../images/react_icon.svg";
import code_icon from "../../images/code_icon.svg";
import laptop_icon from "../../images/laptop_icon.svg";
import bulb_icon from "../../images/new_bulb_icon.svg";
import html_icon from "../../images/cloud_icon.svg";
import dotted_bg from "../../images/dotted_lines.png";
import dotted_bg_mobile from "../../images/dotted_bg_mobile.png";
import device_half from "../../images/device_half.png";
import device_full from "../../images/device_full.png";
import js_icon from "../../images/js_icon.svg";
import report_icon from "../../images/report_icon.svg";

import { motion } from "framer-motion";
import useDeviceCategory from "../../utils/useDimensions";
const duration = 0.8;

const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;

const variants = {
  container: {
    forwardin: {
      y: [300, -50, 0],
    },
    forwardout: {
      y: -400,
      opacity: 0,
    },
    backwardin: {
      y: [-300, 50, 0],
      opacity: 1,
    },
    backwardout: {
      y: [0, 300],
    },
  },
  excel_icon: {
    forwardin: {
      x: [0.48 * screenWidth, 0.24 * screenWidth],
      y: [-0.25 * screenHeight, 0.51 * screenHeight],
      rotate: -40,
    },
    forwardout: {
      x: [0.24 * screenWidth, 0.55 * screenWidth],
      y: [0.51 * screenHeight, 0.25 * screenHeight],
      rotate: -40,
      scale: 0.5,
    },
    backwardin: {
      x: [0.55 * screenWidth, 0.24 * screenWidth],
      y: [0.25 * screenHeight, 0.51 * screenHeight],
      rotate: -40,
      scale: 1,
    },
    backwardout: {
      x: [0.24 * screenWidth, 0.48 * screenWidth],
      y: [0.51 * screenHeight, -0.25 * screenHeight],
      rotate: -20,
    },
  },
  code_icon: {
    forwardin: {
      x: [0.34 * screenWidth, 0.08 * screenWidth],
      y: [-0.25 * screenHeight, 0],
      rotate: 30,
    },
    forwardout: {
      x: [0.08 * screenWidth, 0.55 * screenWidth],
      y: [0, 0.25 * screenHeight],
      rotate: 30,
      scale: 0.5,
    },
    backwardin: {
      x: [0.55 * screenWidth, 0.08 * screenWidth],
      y: [0.25 * screenHeight, 0],
      rotate: 30,
      scale: 1,
    },
    backwardout: {
      x: [0.08 * screenWidth, 0.34 * screenWidth],
      y: [0, -0.25 * screenHeight],
      rotate: -20,
    },
  },
  bulb_icon: {
    forwardin: { x: 0.47 * screenWidth, y: -0.05 * screenHeight, rotate: 40 },
    forwardout: {
      x: [0.47 * screenWidth, 0.55 * screenWidth],
      y: [-0.05 * screenHeight, 0.07 * screenHeight],
      rotate: 40,
      scale: 0.5,
    },
    backwardin: {
      x: [0.55 * screenWidth, 0.47 * screenWidth],
      y: [0.07 * screenHeight, -0.05 * screenHeight],
      rotate: 40,
      scale: 1,
    },
    backwardout: {
      x: [0.47 * screenWidth, 0.55 * screenWidth],
      y: [0.07 * screenHeight, -0.25 * screenHeight],
      rotate: 10,
    },
  },
  laptop_icon: {
    forwardin: { x: 0.5 * screenWidth, y: 0.5 * screenHeight, rotate: 30 },
    forwardout: {
      x: [0.5 * screenWidth, 0.48 * screenWidth],
      y: [0.5 * screenHeight, 0.44 * screenHeight],
      rotate: 30,
      scale: 0.5,
    },
    backwardin: {
      x: [0.48 * screenWidth, 0.5 * screenWidth],
      y: [0.7 * screenHeight, 0.5 * screenHeight],
      rotate: 30,
      scale: 1,
    },
    backwardout: {
      x: [0.5 * screenWidth, 0.59 * screenWidth],
      y: [0.5 * screenHeight, -0.25 * screenHeight],
      rotate: 20,
    },
  },
  html_icon: {
    forwardin: {
      x: 0.6 * screenWidth,
      y: 0.32 * screenHeight,
      rotate: 20,
    },
    forwardout: {
      x: [0.6 * screenWidth, 0.41 * screenWidth],
      y: [0.32 * screenHeight, 0.25 * screenHeight],
      rotate: 20,
      scale: 0.5,
    },
    backwardin: {
      x: [0.48 * screenWidth, 0.6 * screenWidth],
      y: [0.25 * screenHeight, 0.32 * screenHeight],
      rotate: 20,
      scale: 1,
    },
    backwardout: {
      x: [0.6 * screenWidth, 0.66 * screenWidth],
      y: [0.32 * screenHeight, -0.25 * screenHeight],
      rotate: 20,
    },
  },
  html_icon_1: {
    forwardin: {
      x: -0.23 * screenWidth,
      y: 0.25 * screenHeight,
      rotate: 50,
    },
    forwardout: {
      x: [-0.23 * screenWidth, 0.69 * screenWidth],
      y: [0.25 * screenHeight, 0.19 * screenHeight],
      rotate: 50,
      scale: 0.5,
    },
    backwardin: {
      x: [0.69 * screenWidth, -0.23 * screenWidth],
      y: [0.32 * screenHeight, 0.25 * screenHeight],
      rotate: 50,
      scale: 1,
    },
    backwardout: {
      x: [-0.23 * screenWidth, 0.34 * screenWidth],
      y: [0.25 * screenHeight, -0.25 * screenHeight],
      rotate: 20,
    },
  },
  code_icon_1: {
    forwardin: {
      x: 0.5 * screenWidth,
      y: 0.08 * screenHeight,
      rotate: 30,
    },
    forwardout: {
      x: [0.5 * screenWidth, 0.41 * screenWidth],
      y: [0.08 * screenHeight, 0.4 * screenHeight],
      rotate: 50,
      scale: 0.5,
    },
    backwardin: {
      x: [0.41 * screenWidth, 0.5 * screenWidth],
      y: [0.4 * screenHeight, 0.08 * screenHeight],
      rotate: 50,
      scale: 1,
    },
    backwardout: {
      x: [0.5 * screenWidth, 0.55 * screenWidth],
      y: [0.08 * screenHeight, -0.25 * screenHeight],
      rotate: 20,
    },
  },
  device: {
    forwardin: { opacity: [0, 0.5, 1] },
    forwardout: { opacity: 0 },
    backwardin: { opacity: [0, 0.5, 1] },
    backwardout: { opacity: 0 },
  },
  device_full: {
    forwardin: { opacity: 0 },
    forwardout: { opacity: 1, y: -270, x: 0 },
    backwardin: { opacity: 0, y: [-100, 270], x: [300, 0] },
    backwardout: { opacity: 0 },
  },
  bottom_sheet: {
    forwardin: {
      y: [200, -10, 0],
    },
    backwardout: {
      y: [0, 300],
    },
  },
};

const mobile_variants = {
  excel_icon: {
    forwardin: {
      x: [0.48 * screenWidth, -0.1 * screenWidth],
      y: [-0.22 * screenHeight, 0.33 * screenHeight],
      rotate: -40,
    },
    forwardout: {
      x: 0.5 * screenWidth,
      y: [0.33 * screenHeight, 0.66 * screenHeight],
      rotate: -40,
    },
    backwardin: {
      x: [0, -0.1 * screenWidth],
      y: [0.66 * screenHeight, 0.33 * screenHeight],
      rotate: -40,
    },
    backwardout: {
      x: [0.5 * screenWidth, -0.1 * screenWidth],
      y: [0.33 * screenHeight, -0.22 * screenHeight],
      rotate: -20,
    },
  },
  code_icon: {
    forwardin: {
      x: 0.6 * screenWidth,
      y: [-0.22 * screenHeight, -0.05 * screenHeight],
      rotate: 40,
    },
    forwardout: {
      x: 0.5 * screenWidth,
      y: [0, 0.66 * screenHeight],
      rotate: 40,
    },
    backwardin: {
      x: 0.5 * screenWidth,
      y: [0.66 * screenHeight, 0],
      rotate: 40,
    },
    backwardout: {
      x: [0.5 * screenWidth, 0.6 * screenWidth],
      y: [0, -0.22 * screenHeight],
      rotate: 40,
    },
  },
  bulb_icon: {
    forwardin: {
      x: [0.24 * screenWidth, 0.15 * screenWidth],
      y: [-0.22 * screenHeight, -0.1 * screenHeight],
      rotate: 40,
    },
    forwardout: {
      x: 0.5 * screenWidth,
      y: [-0.1 * screenHeight, 0.66 * screenHeight],
      rotate: 40,
    },
    backwardin: {
      x: [0.24 * screenWidth, 0.15 * screenWidth],
      y: [0.5 * screenHeight, -0.1 * screenHeight],
      rotate: 40,
    },
    backwardout: {
      x: [0.5 * screenWidth, 0.15 * screenWidth],
      y: [-0.1 * screenHeight, 0.66 * screenHeight],
      rotate: 40,
    },
  },
  code_icon_1: {
    forwardin: {
      x: [0, 0.02 * screenWidth],
      y: [-0.22 * screenHeight, 0.05 * screenHeight],
      rotate: 40,
    },
    forwardout: {
      x: 0.5 * screenWidth,
      y: [0.05 * screenHeight, 0.66 * screenHeight],
      rotate: 40,
    },
    backwardin: {
      x: [0, 0.02 * screenWidth],
      y: [0.5 * screenHeight, 0.05 * screenHeight],
      rotate: 40,
    },
    backwardout: {
      x: [0.5 * screenWidth, 0.02 * screenWidth],
      y: [0.66 * screenHeight, 0.05 * screenHeight],
      rotate: 40,
    },
  },
  laptop_icon: {
    forwardin: {
      x: [0, 0.48 * screenWidth],
      y: [-0.22 * screenHeight, 0.32 * screenHeight],
      rotate: 40,
    },
    forwardout: {
      x: 0.5 * screenWidth,
      y: [0.32 * screenHeight, 0.66 * screenHeight],
      rotate: 40,
    },
    backwardin: {
      x: [0, 0.48 * screenWidth],
      y: [0.5 * screenHeight, 0.32 * screenHeight],
      rotate: 40,
    },
    backwardout: {
      x: [0.5 * screenWidth, 0.48 * screenWidth],
      y: [0.66 * screenHeight, 0.32 * screenHeight],
      rotate: 40,
    },
  },
  html_icon: {
    forwardin: {
      x: [0, -0.24 * screenWidth],
      y: [-0.22 * screenHeight, 0.43 * screenHeight],
      rotate: 30,
    },
    forwardout: {
      x: 0.5 * screenWidth,
      y: [0.39 * screenHeight, 0.66 * screenHeight],
      rotate: 30,
    },
    backwardin: {
      x: [0.5 * screenWidth, -0.24 * screenWidth],
      y: [0.66 * screenHeight, 0.39 * screenHeight],
      rotate: 30,
    },
    backwardout: {
      x: [-0.5 * screenWidth, -0.24 * screenHeight],
      y: [0.66 * screenHeight, 0.43 * screenHeight],
      rotate: 30,
    },
  },
  html_icon_1: {
    forwardin: {
      x: [0, 0.8 * screenWidth],
      y: [-0.22 * screenHeight, 0.05 * screenHeight],
      rotate: 30,
    },
    forwardout: {
      x: 0.5 * screenWidth,
      y: [0.05 * screenHeight, 0.66 * screenHeight],
      rotate: 40,
    },
    backwardin: {
      x: [0, 0.8 * screenWidth],
      y: [0.66 * screenHeight, 0.05 * screenHeight],
      rotate: 30,
    },
    backwardout: {
      x: [0.5 * screenWidth, 0.8 * screenWidth],
      y: [0.66 * screenHeight, -0.22 * screenHeight],
      rotate: 30,
    },
  },
  device: {
    forwardin: { opacity: [0, 0.5, 1] },
    forwardout: { opacity: 0 },
    backwardin: { opacity: [0, 0.5, 1] },
    backwardout: { opacity: 0 },
  },
  device_full: {
    forwardin: { opacity: 0 },
    forwardout: { opacity: 1, y: -300 },
    backwardin: { opacity: 0, y: [-100, 270], x: 0 },
    backwardout: { opacity: 0 },
  },
};

export default function Slide2(props) {
  const animation = `${props.animation}${props.direction}`;

  const deviceCategory = useDeviceCategory();

  if (deviceCategory === "mobile" || deviceCategory === "tablet") {
    return (
      <div style={{ zIndex: 20000 }}>
        <div
          style={{
            top: 0,
            left: 0,
            height: "100vh",
            width: "30vh",
            backgroundImage: `url(${dotted_bg_mobile})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
          className="position-fixed"
        ></div>
        <motion.img
          initial={{ x: 200, y: -200, rotate: -20 }}
          animate={animation}
          variants={mobile_variants["excel_icon"]}
          transition={{ duration }}
          src={excel_icon}
          alt="excel"
          className="mobile-icon"
        />
        <motion.img
          initial={{ x: 200, y: -200, rotate: -20 }}
          animate={animation}
          variants={mobile_variants["code_icon"]}
          transition={{ duration }}
          src={code_icon}
          alt="code"
          className="mobile-icon"
        />
        <motion.img
          initial={{ x: 100, y: -200, rotate: 20 }}
          animate={animation}
          variants={mobile_variants["bulb_icon"]}
          transition={{ duration }}
          src={bulb_icon}
          alt="bulb"
          className="mobile-icon"
        />
        <motion.img
          initial={{ x: 850, y: -200, rotate: 20 }}
          animate={animation}
          variants={mobile_variants["laptop_icon"]}
          transition={{ duration }}
          src={laptop_icon}
          className="mobile-icon"
          alt="laptop"
        />
        <motion.img
          initial={{ x: 0, y: -200, rotate: 20 }}
          animate={animation}
          variants={mobile_variants["code_icon_1"]}
          transition={{ duration }}
          src={js_icon}
          alt="code"
          className="mobile-icon"
        />
        <motion.img
          initial={{ x: 0, y: -200, rotate: 20 }}
          animate={animation}
          variants={mobile_variants["html_icon"]}
          transition={{ duration }}
          src={html_icon}
          className="mobile-icon"
          alt="html"
        />
        <motion.img
          initial={{ x: 0, y: -200, rotate: 20 }}
          animate={animation}
          variants={mobile_variants["html_icon_1"]}
          transition={{ duration }}
          src={report_icon}
          className="mobile-icon"
          alt="html"
        />
        <motion.div
          animate={animation}
          variants={variants["container"]}
          transition={{ duration }}
          className="d-flex justify-content-center mt-5 text-color-dark-1"
        >
          <div
            className="pt-5 focus-container text-center"
            style={{
              width: 300,
              height: 72,
            }}
          >
            Our Product
            <br />
            Development <br />
            Journey
          </div>
        </motion.div>
        <div
          className="d-flex justify-content-center fixed-bottom"
          style={{ zIndex: 2000 }}
        >
          <motion.img
            initial={{ opacity: 0 }}
            animate={animation}
            variants={mobile_variants["device"]}
            transition={{ duration: 0.7 }}
            src={device_half}
            alt="device"
          />
        </div>
        <div
          className="d-flex justify-content-center fixed-bottom"
          style={{ zIndex: 3000 }}
        >
          <motion.img
            style={{ width: 244 }}
            initial={{
              opacity: 1,
              y: 330,
              x: 0,
            }}
            animate={animation}
            variants={mobile_variants["device_full"]}
            transition={{ duration: 0.7 }}
            src={device_full}
            alt="device"
          />
        </div>
        <div className="d-flex justify-content-center fixed-bottom">
          <motion.div
            animate={animation}
            variants={variants["bottom_sheet"]}
            transition={{ duration }}
          >
            <div
              style={{
                width: "1000px",
                borderRadius: "18px",
                height: "40px",
                background: "linear-gradient(180deg, #EFFAFF 0%, #EFFAFF 100%)",
              }}
            ></div>
          </motion.div>
        </div>
      </div>
    );
  }
  return (
    <div style={{ height: "100%", zIndex: 20000 }}>
      <div
        style={{
          top: 0,
          left: 0,
          height: "100vh",
          width: "30%",
          backgroundImage: `url(${dotted_bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="position-fixed"
      ></div>
      <motion.img
        initial={{ x: 700, y: -200, rotate: -20 }}
        animate={animation}
        variants={variants["excel_icon"]}
        transition={{ duration }}
        src={excel_icon}
        alt="excel"
        className="excel-icon"
      />
      <motion.img
        initial={{ x: 500, y: -200, rotate: -20 }}
        animate={animation}
        variants={variants["code_icon"]}
        transition={{ duration }}
        src={code_icon}
        alt="code"
      />
      <motion.img
        initial={{ x: 800, y: -200, rotate: 20 }}
        animate={animation}
        variants={variants["bulb_icon"]}
        transition={{ duration }}
        src={bulb_icon}
        alt="bulb"
      />
      <motion.img
        initial={{ x: 850, y: -200, rotate: 20 }}
        animate={animation}
        variants={variants["laptop_icon"]}
        transition={{ duration }}
        src={laptop_icon}
        alt="laptop"
      />
      <motion.img
        initial={{ x: 950, y: -200, rotate: 20 }}
        animate={animation}
        variants={variants["html_icon"]}
        transition={{ duration }}
        src={html_icon}
        alt="html"
      />
      <motion.img
        initial={{ x: 500, y: -200, rotate: 20 }}
        animate={animation}
        variants={variants["html_icon_1"]}
        transition={{ duration }}
        src={report_icon}
        alt="html"
      />
      <motion.img
        initial={{ x: 800, y: -200, rotate: 20 }}
        animate={animation}
        variants={variants["code_icon_1"]}
        transition={{ duration }}
        src={js_icon}
        alt="code"
      />
      <motion.div
        animate={animation}
        variants={variants["container"]}
        transition={{ duration }}
        style={{ left: 0 }}
        className="d-flex position-fixed w-100 h-100 justify-content-center text-color-dark-1 text-center"
      >
        <div
          className="focus-container"
          style={{
            width: 500,
            height: 72,
          }}
        >
          Our Product
          <br />
          Development <br />
          Journey
        </div>
      </motion.div>
      <div
        className="d-flex justify-content-center fixed-bottom"
        style={{ zIndex: 2000 }}
      >
        <motion.img
          initial={{ opacity: 0 }}
          animate={animation}
          variants={variants["device"]}
          transition={{ duration: 0.7 }}
          src={device_half}
          alt="device"
        />
      </div>
      <div
        className="d-flex justify-content-center fixed-bottom"
        style={{ zIndex: 3000 }}
      >
        <motion.img
          style={{ width: 244 }}
          initial={{ opacity: 1, y: 270, x: 0 }}
          animate={animation}
          variants={variants["device_full"]}
          transition={{ duration: 0.7 }}
          src={device_full}
          alt="device"
        />
      </div>
      <div className="d-flex justify-content-center fixed-bottom">
        <motion.div
          animate={animation}
          variants={variants["bottom_sheet"]}
          transition={{ duration }}
          className="d-flex justify-content-center w-100"
        >
          <div
            style={{
              width: "1000px",
              borderTopLeftRadius: "18px",
              borderTopRightRadius: "18px",
              height: "40px",
              background: "linear-gradient(180deg, #EFFAFF 0%, #EFFAFF 100%)",
            }}
          ></div>
        </motion.div>
      </div>
    </div>
  );
}
