import wireframe_layouts from "../../images/wireframe_layout.gif";
import discovery_workshop from "../../images/discovery_mobile.gif";
import tell_your_business from "../../images/tell_your_business.gif";
import ux_ui_design from "../../images/ux_ui_design.gif";
import integration from "../../images/integration_Qa.gif";
import production_push from "../../images/production_push.gif";
import project_delivary from "../../images/project_delivary.gif";
import Phase1 from "./Phases/Phase1";
import Phase2 from "./Phases/Phase2";
import Phase3 from "./Phases/Phase3";
import Phase4 from "./Phases/Phase4";
import Phase5 from "./Phases/Phase5";
import Phase6 from "./Phases/Phase6";
import Phase7 from "./Phases/Phase7";

export const slides = [
  {
    component: Phase1,
    text: "Tell us your Business Challenges",
    imgSrc: tell_your_business,
    color: "#5691FF",
    buttonColor: "#5691FF",
    bordersColor: "#5691FF",
    buttonText: "Tell Your Problem",
    content: (
      <div className="digital-trans-initiate_content">
        <p>
          Share your key challenges with us, and we will craft customized solutions to unlock new avenues for your business growth. 
        </p>
      </div>
    ),
  },
  {
    component: Phase2,
    text: "Discovery workshop with our Experts!",
    imgSrc: discovery_workshop,
    color: "#5691FF",
    buttonColor: "#000000",
    bordersColor: "#000000",
    buttonText: "Reach our BA’s",
    content: (
      <div className="digital-trans-initiate_content">
        <p>
          Initiate your business journey by introducing an MVP (Minimum Viable
          Product) - the perfect starting point for testing your concept and
          refining your vision
        </p>
      </div>
    ),
  },
  {
    component: Phase3,
    text: "Requirement Definition",
    imgSrc: wireframe_layouts,
    color: "#5691FF",
    buttonColor: "#5691FF",
    bordersColor: "#5691FF",
    buttonText: "Request Wireframes",
    content: (
      <div className="row discovery_row digital-trans-initiate_content  px-2 pb-2">
        {/* Add your divs here */}
        We hold detailed discussions with stakeholders to determine the app's requirements, focusing on business objectives, market analysis, and user demographics to craft a specification for its functionality and usability.
      </div>
    ),
  },
  {
    component: Phase4,
    text: "Rapid Prototyping",
    imgSrc: ux_ui_design,
    color: "#5691FF",
    buttonColor: "#000000",
    bordersColor: "#000000",
    buttonText: "Request Prototype",
    content: (
      <div className="digital-trans-initiate_content  px-2 pb-2">
        <p>
            During the Rapid Prototyping phase, we quickly bring the application concepts to life through wireframes, clickable prototype and UX/UI design allowing for iterative testing and refinement to accelerate the development. 
        </p>
      </div>
    ),
  },
  //   {
  //     text: 'UX/UI Design',
  //     imgSrc: wireframe_layouts,
  //     color: '#5691FF',
  //     buttonColor: '#5691FF',
  //     bordersColor: '#5691FF',
  //     buttonText: "Request Prototype",
  //     content:(
  //       <div className="row discovery_row digital-trans-initiate_content">
  //             {/* Add your divs here */}
  //             <div className="col discovery_content">Possible alternative</div>
  //             <div className="col discovery_content">Cost-benefit analysis</div>
  //             <div className="col discovery_content">High Level Solution Design</div>
  //             <div className="col discovery_content">Requirement Document & Finalize</div>
  //           </div>
  //     )
  //   },
  {
    component: Phase5,
    text: "Development, Integration & QA",
    imgSrc: integration,
    color: "#5691FF",
    buttonColor: "#5691FF",
    bordersColor: "#5691FF",
    buttonText: "Learn More",
    content: (
      <div className="digital-trans-initiate_content">
        <p>
            This phase focuses on turning design into functional reality, ensuring seamless operation and top-tier quality through thorough testing and expert integration. 
        </p>
      </div>
    ),
  },
  {
    component: Phase6,
    text: "Project Delivery, Maintenance, and Support",
    imgSrc: production_push,
    color: "#5691FF",
    buttonColor: "#000000",
    bordersColor: "#000000",
    buttonText: "Reach Us",
    content: (
      <div className="digital-trans-initiate_content">
        <p>
             Venzo's Project Delivery is defined by precise execution and follow-ups, providing ongoing maintenance and support to ensure your application evolves with your business and market demands. 
        </p>
      </div>
    ),
  },
  // {
  //   component: Phase7,
  //   text: "Project Delivery Maintenance & Support",
  //   imgSrc: project_delivary,
  //   color: "#5691FF",
  //   buttonColor: "#5691FF",
  //   bordersColor: "#5691FF",
  //   buttonText: "Reach Us",
  //   content: (
  //     <div className="digital-trans-initiate_content">
  //       <p>
  //         Initiate your business journey by introducing an MVP (Minimum Viable
  //         Product) - the perfect starting point for testing your concept and
  //         refining your vision
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   component: Phase8,
  //   text: "Project Delivery Maintenance & Support",
  //   imgSrc: project_delivary,
  //   color: "#5691FF",
  //   buttonColor: "#5691FF",
  //   bordersColor: "#5691FF",
  //   buttonText: "Reach Us",
  //   content: (
  //     <div className="digital-trans-initiate_content">
  //       <p>
  //         Initiate your business journey by introducing an MVP (Minimum Viable
  //         Product) - the perfect starting point for testing your concept and
  //         refining your vision
  //       </p>
  //     </div>
  //   ),
  // },
];
