import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = {
  open: {
    opacity: 1,
    width: "100%",
    height: "100vh",
    background: "white",
    display: "block",
    transition: { duration: 0.5, staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    opacity: 0,
    width: "100%",
    height: 0,
    display: "none",
    // background: "transparent",
    transition: {
      duration: 0.7,
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

export const Navigation = ({ navconfig }) => (
  <motion.ul variants={variants}>
    {navconfig.map((i) => (
      <MenuItem i={i} key={i} />
    ))}
  </motion.ul>
);
