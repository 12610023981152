import dotted_vector from "../../../images/dotted_vector.png";

export default function Phase6(props) {
  return (
    <div>
      <div className="d-flex justify-content-space-between align-items-center gap-lg-5">
        <div className="phase-vector">
          <img alt="dotted_vector" src={dotted_vector} />
        </div>
        <div>
          <div className="phase-title blue">Repeat / Iterate</div>
          <div className="phase-summary">
            <ul>
              <li>Deconstruct Idea on Lean Canvas</li>
              <li>Desirability Check</li>
              <li>Feasibility Check</li>
              <li>Viability Check</li>
              <li>High Concept Pitch</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
