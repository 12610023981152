import React, { useState, useRef, useEffect } from "react";
import StrokeVector from "../../../images/stroke_vector.svg";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import useDeviceCategory from "../../../utils/useDimensions";

function CounterUp() {
  const [yearsOfBusiness, setYearsOfBusiness] = useState(0);
  const [techStacks, setTechStacks] = useState(0);
  const [brilliantMinds, setBrilliantMinds] = useState(0);
  const [amazingProducts, setAmazingProducts] = useState(0);
  const [successfulProjects, setSuccessfulProjects] = useState(0);
  const [customerRetention, setCustomerRetention] = useState(0);

  const deviceCategory = useDeviceCategory();

  const [ref, inView] = useInView({
    threshold: 0.6,
  });

  useEffect(() => {
    if (inView) {
      setYearsOfBusiness(7);
      setTechStacks(30);
      setBrilliantMinds(100);
      setAmazingProducts(5);
      setSuccessfulProjects(40);
      setCustomerRetention(90);
    } else {
      // setYearsOfBusiness(0);
      // setTechStacks(0);
      // setBrilliantMinds(0);
      // setAmazingProducts(0);
      // setSuccessfulProjects(0);
      // setCustomerRetention(0);
      setYearsOfBusiness(7);
      setTechStacks(30);
      setBrilliantMinds(100);
      setAmazingProducts(5);
      setSuccessfulProjects(40);
      setCustomerRetention(90);
    }
  }, [inView]);

  const getCounter = () => {
    if (deviceCategory === "mobile" || deviceCategory === "tablet") {
      return (
        <div className="pt-4">
          <div className="row">
            <div className="col-6">
              <h1 className="brilliant-minds">
                <CountUp start={0} end={techStacks} delay={3} />+
              </h1>
              <p className="yearsTargets">Tech stacks</p>
            </div>
            <div className="col-6">
              <h1 className="brilliant-minds">
                <CountUp start={0} end={brilliantMinds} delay={3} />+
              </h1>
              <p className="yearsTargets">Brilliant minds</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h1 className="brilliant-minds">
                0<CountUp start={0} end={amazingProducts} delay={3} />+
              </h1>
              <p className="yearsTargets">Amazing products</p>
            </div>
            <div className="col-6">
              <h1 className="brilliant-minds">
                <CountUp start={0} end={successfulProjects} delay={3} />+
              </h1>
              <p className="yearsTargets">successful projects</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h1 className="yearly-business" style={{ color: "#39ABFF" }}>
                0<CountUp start={0} end={yearsOfBusiness} delay={3} />+
              </h1>
              <p className="yearsTargets">Years of business</p>
            </div>
            <div className="col-6">
              <h1 className="yearly-business" style={{ color: "#FF6161" }}>
                <CountUp start={0} end={customerRetention} delay={3} />%
              </h1>
              <p className="yearsTargets">Customer retention</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="row px-3 pt-5">
        <div className="col-lg-2 yearly-business-margin">
          <h1 className="brilliant-minds">
            <CountUp start={0} end={techStacks} delay={3} />+
          </h1>
          <p className="yearsTargets">Tech stacks</p>
        </div>
        <div className="col-lg-2 yearly-business-margin">
          <h1 className="brilliant-minds">
            <CountUp start={0} end={brilliantMinds} delay={3} />+
          </h1>
          <p className="yearsTargets">Brilliant minds</p>
        </div>
        <div className="col-lg-2 yearly-business-margin">
          <h1 className="brilliant-minds">
            0<CountUp start={0} end={amazingProducts} delay={3} />+
          </h1>
          <p className="yearsTargets">Amazing products</p>
        </div>
        <div className="col-lg-3 yearly-business-margin">
          <h1 className="brilliant-minds">
            <CountUp start={0} end={successfulProjects} delay={3} />+
          </h1>
          <p className="yearsTargets">successful projects</p>
        </div>
        <div className="col-lg-3">
          <h1 className="yearly-business" style={{ color: "#FF6161" }}>
            <CountUp start={0} end={customerRetention} delay={3} />%
          </h1>
          <p className="yearsTargets">Customer retention</p>
        </div>
      </div>
    );
  };
  return (
    <div ref={ref}>
      <div className="row px-0 px-md-3 pt-5" style={{ paddingTop: "5rem" }}>
        <div className="col-lg-9">
          <div className="company-titles text-start">Who we are?</div>
          <div className="company-content text-start">
              We are a team of visionaries, strategists, and technologists bound by the mission to elevate your business potential. Driven by a passion for progress, we leverage design thinking, emerging technologies, cloud, and analytics to deliver solutions that not only meet but exceed expectations.  
          </div>
        </div>
        <div className="col-lg-3 d-none d-lg-block">
          <h1 className="yearly-business" style={{ color: "#39ABFF" }}>
            0<CountUp start={0} end={yearsOfBusiness} delay={3} />+
          </h1>
          <p className="yearsTargets">Years of business</p>
        </div>
        <img
          src={StrokeVector}
          className="img-fluid d-none d-lg-block pt-5"
          alt="Main Image"
        />
      </div>
      {getCounter()}
    </div>
  );
}

export default CounterUp;
