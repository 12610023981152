import React from "react";
import letter_pad from "../../images/letter_pad.png";
import { motion } from "framer-motion";
import dotted_lines_left from "../../images/dotted_lines_tell_left.png";
import dotted_lines_right from "../../images/dotted_lines_tell_right.png";
import useDeviceCategory from "../../utils/useDimensions";
import vector_button from "../../images/vector_button.png";
import { slides } from "./SlidesView";

const duration = 1;
const screenHeight = window.innerHeight;

const variants = {
  bottom: {
    forwardout: {
      // width: "100vw",
      // height: "100vh",
      // position: "absolute",
      // top: 0,
      // // y: [150, -700],
      // display: "block",
    },
    forwardin: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      opacity: 1,
    },
  },
  bottomcircle: {
    forwardout: {
      scale: 5,
    },
    backwardin: {
      scale: [5, 1.3],
    },
  },
  letter_pad: {
    forwardin: {
      scale: 1,
      opacity: [0, 0.5, 1],
    },
    forwardout: {
      y: -170,
      x: 10,
      scale: 1,
      opacity: [1, 0.5, 0],
    },
    backwardin: {
      y: [-60, 0],
      scale: [1.2, 1],
      opacity: [0.5, 1],
    },
    backwardout: {
      y: 0,
      scale: [1, 0.9],
      opacity: [1, 0.5],
    },
  },
  dotted_lines: {
    forwardout: {
      opacity: [0, 0.5, 1],
      scale: [0, 1],
    },
    backwardin: {
      opacity: 0,
      scale: [1, 0],
    },
  },
  text_contnt: {
    forwardout: {
      opacity: [0, 0.5],
      scale: [0, 1],
      y: [-20, -50],
    },
    backwardin: {
      opacity: [0.5, 0],
      scale: [1, 0],
      y: [-50, -20],
    },
  },
};

const mobile_variants = {
  bottom: {
    forwardout: {
      // width: "100vw",
      // height: "100vh",
      // position: "absolute",
      // top: 0,
      // // y: [150, -700],
      // display: "block",
    },
    forwardin: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      opacity: 1,
    },
  },
  bottomcircle: {
    forwardout: {
      scale: 5,
    },
    backwardin: {
      scale: [5, 2],
    },
  },
  letter_pad: {
    forwardout: {
      y: -screenHeight * 0.1,
      scale: 0.9,
      opacity: [1, 0.5],
    },
    backwardin: {
      y: -screenHeight * 0.1,
      scale: [1.2, 1],
      opacity: [0.5, 1],
    },
    backwardout: {
      y: [0, 60],
      scale: [1],
      opacity: [1, 0.5],
    },
  },
  dotted_lines: {
    forwardout: {
      opacity: [0, 0.5, 1],
    },
  },
};

function Slide2(props) {
  const animation = `${props.animation}${props.direction}`;

  console.log(animation);
  const deviceCategory = useDeviceCategory();

  if (deviceCategory === "mobile") {
    return (
      <section
        style={{ backgroundColor: "#000", height: "100%", paddingTop: 40 }}
        className="lgShadow_digital slideTwoBackground overflow-hidden pt-2"
      >
        <div className="digital-trans-venzo-title text-start px-3 pt-3">
          Our Digital Transformation Journey
        </div>
        <div className="digital-trans-slideTwo text-start px-3 pt-3">
          <p>
            Embark on a transformative innovation journey with us, where
            strategic insight and technology unite to redefine possibilities.
          </p>
        </div>
        <motion.div
          style={{
            opacity: 1,
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
          initial={{ opacity: 1 }}
          animate={animation}
          variants={mobile_variants["bottom"]}
          className="row"
          transition={{ duration }}
        >
          <motion.circle
            style={{ scale: 2, y: 340, originX: "center", originY: "center" }}
            animate={animation}
            variants={mobile_variants["bottomcircle"]}
            transition={{ duration }}
            className="digital_journey_Problem d-flex justify-content-center"
          ></motion.circle>
          <motion.img
            animate={animation}
            variants={mobile_variants["letter_pad"]}
            src={letter_pad}
            transition={{ duration }}
            alt="letter_pad"
            className="letter-pad"
          />
        </motion.div>

        <motion.div
          style={{
            top: 135,
          }}
          animate={animation}
          variants={variants["dotted_lines"]}
          transition={{ duration }}
          initial={{ opacity: 0 }}
          className="position-fixed d-flex flex-column align-items-center"
        >
          {/* <div className="digital_Trans_title px-3">
            Tell us your business problem?
          </div>
          <div className="digital-trans-initiate_content">
            <p>
              Initiate your business journey by introducing an MVP (Minimum
              Viable Product) - the perfect starting point for testing your
              concept and refining your vision
            </p>
          </div> */}
          {/* <button
            type="button"
            className="btn btn-primary arrow_tell_problem"
            style={{
              backgroundColor: "#5691FF",
              borderColor: slides[0].bordersColor,
            }}
          >
            <div>
              {slides[0].buttonText}{" "}
              <span style={{ paddingLeft: "17px" }}>
                {" "}
                <img
                  src={vector_button}
                  className="img_View"
                  alt="Button"
                  style={{ display: "initial", width: "8px", height: "17px" }}
                />
              </span>
            </div>
          </button> */}
        </motion.div>
      </section>
    );
  }

  if (deviceCategory === "tablet" || deviceCategory === "laptop") {
    return (
      <section
        style={{ backgroundColor: "#000", height: "100%", paddingTop: 40 }}
        className="lgShadow_digital slideTwoBackground overflow-hidden pt-2"
      >
        <div className="digital-trans-venzo-title text-center px-4">
          Our Digital Transformation Journey
        </div>
        <div className="digital-trans-slideTwo text-center px-4 pt-3">
          <p>
            Embark on a transformative innovation journey with us, where
            strategic insight and technology unite to redefine possibilities.
          </p>
        </div>
        <motion.div
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
          initial={{ opacity : 1}}
          animate={animation}
          variants={mobile_variants["bottom"]}
          className="row"
          transition={{ duration }}
        >
          <motion.circle
            style={{
              scale: deviceCategory === "tablet" ? 2 : 2.2,
              y: 400,
              originX: "center",
              originY: "center",
            }}
            animate={animation}
            variants={mobile_variants["bottomcircle"]}
            transition={{ duration }}
            className="digital_journey_Problem d-flex justify-content-center"
          ></motion.circle>

          <motion.img
            animate={animation}
            variants={mobile_variants["letter_pad"]}
            src={letter_pad}
            transition={{ duration, ease: "easeInOut" }}
            alt="letter_pad"
            className={
              deviceCategory === "tablet" ? "letter-pad" : "letter-pad-laptop"
            }
          />
        </motion.div>

        <motion.div
          style={{
            top: 135,
          }}
          animate={animation}
          variants={variants["dotted_lines"]}
          transition={{ duration }}
          initial={{ opacity: 0 }}
          className="position-fixed"
        >
          {/* <div className="digital_Trans_title px-3">
            Tell us your business problem?
          </div>
          <div className="digital-trans-initiate_content">
            <p>
              Initiate your business journey by introducing an MVP (Minimum
              Viable Product) - the perfect starting point for testing your
              concept and refining your vision
            </p>
          </div> */}
        </motion.div>
      </section>
    );
  }

  return (
    <section
      className="lgShadow_digital slideTwoBackground pt-5"
      style={{ height: "100%" }}
    >
      <div className="row">
        <div
          className="col-xl-6 digital-trans-venzo-title justify-content-left align-self-center"
          style={{ textAlign: "inherit", paddingLeft: 60 }}
        >
          Our Digital Transformation Journey
        </div>

        <div className="col-xl-6 digital-trans-slideTwo d-flex justify-selt-right align-self-center">
          <p>
            Embark on a transformative innovation journey with us, where
            strategic insight and technology unite to redefine possibilities.
          </p>
        </div>
      </div>
      <motion.div
        initial={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
        animate={animation}
        variants={variants["bottom"]}
        className="row"
        transition={{ duration }}
      >
        <motion.circle
          // style={{ scale: 1, y: 250, originX: "center", originY: "center" }}
          style={{
            scale: 1.3,
            y: deviceCategory === "desktop" ? 200 : 130,
            originX: "center",
            originY: "center",
          }}
          animate={animation}
          variants={variants["bottomcircle"]}
          transition={{ duration }}
          className="digital_journey_Problem d-flex justify-content-center"
        ></motion.circle>
        <motion.img
          animate={animation}
          variants={variants["letter_pad"]}
          src={letter_pad}
          transition={{ duration }}
          alt="letter_pad"
          className="letter-pad"
        />
      </motion.div>
      {/* <motion.div
        style={{
          top: 0,
          left: 0,
          height: "100vh",
          width: "30%",
          backgroundImage: `url(${dotted_lines_left})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        animate={animation}
        variants={variants["dotted_lines"]}
        transition={{ duration }}
        initial={{ opacity: 0 }}
        className="position-fixed"
      ></motion.div> */}
      {/* <motion.div
        style={{
          top: 0,
          right: 0,
          height: "100vh",
          width: "30%",
          backgroundImage: `url(${dotted_lines_right})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        animate={animation}
        variants={variants["dotted_lines"]}
        transition={{ duration }}
        initial={{ opacity: 0 }}
        className="position-fixed"
      ></motion.div> */}
      <motion.div
        style={{
          top: 235,
          opacity: 0,
        }}
        animate={animation}
        variants={variants["text_contnt"]}
        transition={{ duration }}
        initial={{ opacity: 0 }}
        className="position-fixed d-flex flex-column"
      >
        {/* <div className="digital_Trans_title">
          Tell us your business problem?
        </div>
        <div className="digital-trans-initiate_content">
          <p>
            Initiate your business journey by introducing an MVP (Minimum Viable
            Product) - the perfect starting point for testing your concept and
            refining your vision
          </p>
        </div> */}
      </motion.div>
    </section>
  );
}

export default Slide2;
