import phase_bg from "../../images/phase_bg.png";
import device_full from "../../images/device_full.gif";
import dotted_vector from "../../images/dotted_vector.png";
import prod_phase_2 from "../../images/prod_phase_2.gif";
import prototyping from "../../images/prototyping.gif";
import validation from "../../images/validation.gif";
import build from "../../images/builf.gif";
import iterate from "../../images/iterate.gif";

import _ from "lodash";

import { motion, useScroll, useTransform } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import Phase1 from "./Phases/Phase1";
import Phase2 from "./Phases/Phase2";
import Phase3 from "./Phases/Phase3";
import Phase4 from "./Phases/Phase4";
import Phase5 from "./Phases/Phase5";
import Phase6 from "./Phases/Phase6";
import { useNavigate } from "react-router-dom";
import useDeviceCategory from "../../utils/useDimensions";
import TransformProductBusinessForm from "../DigitalTrans/TransformBusiness/TransformProductBusinessForm";
import BackDrop from "../Contacts/BackDrop";

const phaseAnimations = [
  device_full,
  prod_phase_2,
  prototyping,
  build,
  validation,
  iterate,
];

const phaseWidths = ["60%", "60%", "100%", "75%", "80%", "80%"];

const phaseHeights = ["65%", "65%", "70%", "45%", "65%", "80%"];

const lowLaptopPhaseWidths = [250, 250, "85%", "70%", "70%", "80%"];

const lowLaptopPhaseHeights = [280, 280, "70%", "55%", "60%", "80%"];

const laptopPhaseWidths = [310, 310, 500, 450, 400, 360];

const laptopPhaseHeights = [350, 350, 310, 300, 300, 360];

const mobilePhaseWidths = [262, 262, 430, 350, 400, 360];

const mobilePhaseHeights = [306, 306, 320, 240, 300, 360];

const lowMobilePhaseWidths = [230, 230, 380, 300, 360, 300];

const lowMobilePhaseHeights = [270, 270, 270, 190, 260, 300];

const duration = 0.8;
const MAX_SLIDES = 5;
const minSwipeDistance = 20;

const screenHeight = window.innerHeight;

const variants = {
  container: {
    forwardin: {
      y: [300, -50, 0],
    },
    forwardout: {
      y: -400,
      opacity: 0,
    },
    backwardin: {
      y: [-300, 50, 0],
      opacity: 1,
    },
    backwardout: {
      y: [0, 300],
    },
  },
  progress_bar: {
    1: { width: "17%" },
    2: { width: "34%" },
    3: { width: "51%" },
    4: { width: "68%" },
    5: { width: "85%" },
    6: { width: "100%" },
  },
  device: {
    forwardin: { opacity: 1 },
    forwardout: { opacity: 0 },
    backwardin: { opacity: 1 },
    backwardout: { opacity: 0 },
  },
  device_full: {
    forwardin: { opacity: 0 },
    forwardout: { opacity: [0, 0.5, 1], y: 30, x: 400 },
    backwardin: { opacity: 0, y: [30, 300], x: [400, 10] },
    backwardout: { opacity: 0 },
  },
  idea: {
    forwardin: { y: [300, -50, 0] },
    forwardout: { y: -400, opacity: 0 },
    backwardin: { y: [-400, 0], opacity: 1 },
    backwardout: { y: [0, 300], opacity: 1 },
  },
  product: {
    forwardin: { y: [300, -50, 0] },
    forwardout: { y: -400, opacity: 0 },
    backwardin: { y: [-400, 0], opacity: 1 },
    backwardout: { y: [0, 300], opacity: 1 },
  },
  phase: {
    1: { y: [-440, -420] },
    2: { y: [-860, -840] },
  },
};

const mobile_variants = {
  device_full: {
    forwardin: { opacity: 0 },
    forwardout: { opacity: [0, 0.5, 1], y: 30 },
    backwardin: { opacity: 0, y: [30, 300] },
    backwardout: { opacity: 0 },
  },
};

export default function Slide3(props) {
  const [currentPhase, setCurrentPhase] = useState(props.currentPhase);
  const [inProgress, setInProgress] = useState(false);
  const [phaseTransitionMobile, setPhaseTransitionMobile] = useState(false);
  const { isFormOpen, setIsFormOpen } = props;

  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  const [touchStartY, setTouchStartY] = useState(null);
  const [touchEndY, setTouchEndY] = useState(null);

  const [phaseHeight, setPhaseHeight] = useState(0);
  const ref = useRef(null);

  const navigate = useNavigate();
  const [isPopUpOpen, setPopUpOpen] = useState(false);

  const openPopUp = () => {
    setPopUpOpen(true);
    setIsFormOpen(true);
  };

  const closePopUp = () => {
    setPopUpOpen(false);
    setPhaseTransitionMobile(false);
    setInProgress(false);
    setIsFormOpen(false);
  };

  const phases = [
    <Phase1 openPopUp={openPopUp} />,
    <Phase2 openPopUp={openPopUp} />,
    <Phase3 openPopUp={openPopUp} />,
    <Phase4 openPopUp={openPopUp} />,
    <Phase5 openPopUp={openPopUp} />,
    // <Phase6 openPopUp={openPopUp} />,
  ];

  useEffect(() => {
    if (ref.current) {
      setPhaseHeight(ref.current.clientHeight - 35);
    }
  });

  useEffect(() => {
    window.addEventListener(
      "resize",
      () =>
        setPhaseHeight(ref.current ? ref.current.clientHeight - 35 : "auto"),
      false
    );
    return () => window.removeEventListener("resize", null);
  });

  useEffect(() => {
    if (currentPhase === 0) {
      changePhase({ deltaY: 1 });
    }
  }, [currentPhase]);

  const changePhase = (e) => {
    if (inProgress) {
      return;
    }
    setInProgress(true);
    const deltaY = e.deltaY;
    if (deltaY > 0 && currentPhase <= MAX_SLIDES) {
      if (currentPhase === MAX_SLIDES) {
        navigate("/product-transform");
      }
      nextPhase();
    } else if (deltaY < 0 && currentPhase >= 1) {
      prevPhase();
    }
    setTimeout(() => setInProgress(false), 1500);
  };

  const nextPhase = () => {
    setTimeout(() => props.setPhase(currentPhase + 1), 300);
    setTimeout(() => setCurrentPhase(currentPhase + 1), 200);
  };

  const prevPhase = () => {
    if (currentPhase === 1) {
      setTimeout(() => props.setPhase(currentPhase), 100);
    }
    setTimeout(() => setCurrentPhase(currentPhase - 1), 200);
  };

  const getPhase = () => {
    switch (currentPhase) {
      case 1:
        return <Phase1 openPopUp={openPopUp} />;
      case 2:
        return <Phase2 openPopUp={openPopUp} />;
      case 3:
        return <Phase3 openPopUp={openPopUp} />;
      case 4:
        return <Phase4 openPopUp={openPopUp} />;
      case 5:
        return <Phase5 openPopUp={openPopUp} />;
      case 6:
        return <Phase6 openPopUp={openPopUp} />;
      default:
        return <Phase1 />;
    }
  };

  const debounceSlideChange = _.throttle(changePhase, 500);

  const deviceCategory = useDeviceCategory();

  const onTouchStart = (e) => {
    setTouchEndY(null); // otherwise the swipe is fired even with usual touch events
    setTouchEndX(null);
    setTouchStartY(e.targetTouches[0].clientY);
    setTouchStartX(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEndY(e.targetTouches[0].clientY);
    setTouchEndX(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (inProgress) {
      return;
    }
    setPhaseTransitionMobile(true);
    setInProgress(true);

    if (!touchStartY || !touchEndY || !touchStartX || !touchEndX) return;

    const distanceY = touchStartY - touchEndY;
    const distanceX = touchStartX - touchEndX;
    const isSwipeDown = distanceY > minSwipeDistance;
    const isSwipeUp = distanceY < -minSwipeDistance;
    const isSwipeRight = distanceX > minSwipeDistance;
    const isSwipeLeft = distanceX < -minSwipeDistance;
    if (isSwipeDown || isSwipeLeft) {
      if (currentPhase === MAX_SLIDES) {
        navigate("/product-transform");
      }
      nextPhase();
    } else if (isSwipeUp || isSwipeRight) {
      prevPhase();
    }

    setTimeout(() => setPhaseTransitionMobile(false), 200);
    setTimeout(() => setInProgress(false), 500);
  };

  let width = window.innerWidth;
  let widths = mobilePhaseWidths;
  let heights = mobilePhaseHeights;
  if (deviceCategory === "mobile" || deviceCategory === "tablet") {
    if (width > 577) {
      widths = mobilePhaseWidths;
      heights = mobilePhaseHeights;
    }
    if (width < 400) {
      widths = lowMobilePhaseWidths;
      heights = lowMobilePhaseHeights;
    }

    return (
      <>
        {isPopUpOpen && <BackDrop onClose={closePopUp} />}
        <div
          style={{ height: "100%", zIndex: 20000 }}
          onWheel={debounceSlideChange}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <motion.div
            style={{
              //   top: 100,
              //   left: 0,
              height: "100vh",
              width: "100%",
              backgroundImage: `url(${phase_bg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 90%",
            }}
            animate={{ top: 100, left: 0, width: "100%" }}
            transition={{ duration: 0.5 }}
            className="position-fixed"
          ></motion.div>
          <div className="phase-progress">
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "100%" }}
              transition={{ duration }}
              className="w-100 progress-bar position-relative"
            >
              <motion.div
                animate={{ width: `${currentPhase * 20}%` }}
                variants={variants["progress_bar"]}
                transition={{ duration }}
                className="progress-indicator position-absolute"
              ></motion.div>
              <div
                className="text-color-light-4 font-weight-300"
                style={{ zIndex: 100, lineHeight: " 114.6%", fontSize: 12 }}
              >
                Our Product Development Journey
              </div>
            </motion.div>
          </div>
          <div
            className={`d-flex flex-column ${
              screenHeight > 670
                ? "justify-content-between pb-5"
                : "justify-content-start"
            } justify-content-md-start mt-md-5 align-items-center h-100`}
            style={{ zIndex: 3000 }}
          >
            <div className="phase-content phase-content d-flex flex-column align-items-center">
              <motion.div
                animate={
                  phaseTransitionMobile ? { opacity: 0 } : { opacity: 1 }
                }
                transition={{ duration, ease: "easeInOut" }}
              >
                {getPhase()}
              </motion.div>
            </div>

            <motion.img
              // width={phaseWidths[currentPhase - 1]}
              // height={phaseHeights[currentPhase - 1]}
              initial={{
                opacity: 1,
                scale: 1,
                width: widths[0],
                height: heights[0],
              }}
              animate={
                currentPhase + 1 <= 1
                  ? {
                      y: [0, 50],
                      opacity: [0.5, 1],
                      transition: { duration: 3, ease: "easeInOut" },
                    }
                  : phaseTransitionMobile
                  ? { opacity: 0, scale: 0 }
                  : {
                      opacity: 1,
                      scale: 1,
                      width: widths[currentPhase - 1],
                      height: heights[currentPhase - 1],
                    }
              }
              style={{
                // width: widths[currentPhase - 1],
                // height: heights[currentPhase - 1],
                zIndex: 1000,
                marginTop: deviceCategory === "tablet" ? 60 : 10,
              }}
              // variants={mobile_variants["device_full"]}
              transition={{ duration, ease: "easeInOut" }}
              src={phaseAnimations[currentPhase - 1] || device_full}
              alt="device"
            />
          </div>
        </div>
        {isPopUpOpen && (
          <TransformProductBusinessForm
            isOpen={isPopUpOpen}
            onClose={closePopUp}
          />
        )}
      </>
    );
  }

  let marginTop = 78;
  if (width > 1200 && screenHeight >= 700) {
    marginTop = 50;
    widths = phaseWidths;
    heights = phaseHeights;
  } else if (screenHeight < 700) {
    marginTop = 30;
    widths = lowLaptopPhaseWidths;
    heights = lowLaptopPhaseHeights;
  } else {
    widths = laptopPhaseWidths;
    heights = laptopPhaseHeights;
  }
  return (
    <div
      style={{ height: "100%", zIndex: 20000 }}
      onWheel={debounceSlideChange}
      ref={ref}
    >
      <motion.div
        style={{
          //   top: 100,
          //   left: 0,
          height: "100vh",
          width: "100%",
          backgroundImage: `url(${phase_bg})`,
          backgroundRepeat: "round",
          backgroundSize: "cover",
        }}
        animate={{ top: 100, left: 0, width: "100%" }}
        transition={{ duration: 0.5 }}
        className="position-fixed"
      ></motion.div>
      <div className="phase-progress">
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "100%" }}
          transition={{ duration: 0.3 }}
          className="w-100 progress-bar position-relative"
        >
          <motion.div
            animate={{ width: `${currentPhase * 20}%` }}
            variants={variants["progress_bar"]}
            transition={{ duration, ease: "easeInOut" }}
            className="progress-indicator position-absolute"
          ></motion.div>
          <div
            className="text-color-black font-size-15 font-weight-300"
            style={{ zIndex: 100, lineHeight: " 114.6%" }}
          >
            Our Product Development Journey
          </div>
        </motion.div>
      </div>
      <div
        className="d-flex justify-content-space-between align-items-start"
        style={{ zIndex: 3000 }}
      >
        <div
          className={`phase-content ${deviceCategory}`}
          style={{ height: phaseHeight - marginTop }}
        >
          <motion.div
            style={{}}
            initial={{ y: phaseHeight - marginTop }}
            animate={{
              y:
                currentPhase > 1
                  ? -(currentPhase - 1) * (phaseHeight - marginTop)
                  : 0,
            }}
            transition={{ duration, ease: "easeInOut" }}
          >
            {phases.map((p) => (
              <div
                className="d-flex align-items-start"
                style={{
                  height: phaseHeight - marginTop,
                  borderLeft: "1px dashed",
                  paddingLeft: 49,
                  paddingTop: 50,
                }}
              >
                {p}
              </div>
            ))}
          </motion.div>
          {/* <div style={{ y }}>{getPhase()}</div> */}
        </div>
        <div
          className={`phase-image ${deviceCategory} d-flex align-items-center`}
          style={{ height: phaseHeight - marginTop }}
        >
          <motion.img
            // width={phaseWidths[currentPhase - 1]}
            // height={phaseHeights[currentPhase - 1]}
            initial={{ opacity: 1, y: 30 }}
            animate={{
              y: [0.19 * screenHeight, 0.02 * screenHeight, 0],
              x: [-100, 20, 10],
              opacity: [0.5, 1],
              width: widths[currentPhase - 1],
              height: heights[currentPhase - 1],
            }}
            style={{
              // width: widths[currentPhase - 1],
              // height: heights[currentPhase - 1],
              marginTop: 0,
            }}
            variants={variants["device_full"]}
            transition={{ duration, ease: "easeInOut" }}
            src={phaseAnimations[currentPhase - 1] || device_full}
            alt="device"
          />
        </div>
        <TransformProductBusinessForm
          isOpen={isPopUpOpen}
          onClose={closePopUp}
        />
      </div>
    </div>
  );
}
