import React, { useState } from "react";
//import caseStudyImg from "../../../images/caseStudiesImg.png";
import { useNavigate } from "react-router-dom";
import caseStudyImg from "../../../images/iot_image_lap.svg";
import CarvaganImage from "../../../images/carvagan_full_images.png";

const CaseStudiesViews = ({ cards }) => {
  const [showExploreButton, setShowExploreButton] = useState(false);
  const [showRemainingSlides, setShowRemainingSlides] = useState(false);
  const navigate = useNavigate();

  const handleExploreButtonClick = () => {
    setShowRemainingSlides(!showRemainingSlides);
  };

  const handleCaseStudies = () => {
    navigate("/case-studies/healthcare-iot-for-smarter-device-monitoring");
  };

  const handleCaseStudiesCarvagon = () => {
    navigate("/case-studies/Enhanced-user-engagement-to-boost-revenue");
  };


  return (
    <div className="card-carousel py-3 py-md-5">
      <div className="">
        <div className="">
          <div
            style={{
              fontSize: "45px",
              fontWeight: "500",
              font: "lexend",
              lineHeight: "51.57px",
            }}
          >
            <h1 className="case-studies-heading text-center">Case Studies</h1>
          </div>
        </div>
      </div>
      <div className="row pt-3 pl-5 mx-0">
        <div
          className="card cardViewCases"
          style={{ border: "none", boxShadow: "none" }}
        >
          <div className="card-body text-start">
            <div className="row">
              <div className="col-10">
                <h5 className="card-title card-title_view">Healthcare IoT for Smarter Device Monitoring</h5>
              </div>
              <div className="col-2 text-end">
                <h5 className="card-title">01</h5>
              </div>
              {/* <div className="card-title text-end">{card.title}</div> */}
            </div>

            <p className="card-text card-text-view">
                Our client is a prominent healthcare entity, manufacturing and trading medical equipment and devices. Their excellence extends to driving technology-forward solutions in the health and wellness sector.
            </p>
            <a href="" style={{ textDecoration: "none" }} onClick={handleCaseStudies}>
              Learn More &rarr;
            </a>
          </div>
          <div className="row" style={{ marginTop: "-9px" }}>
            <div className="col-xl-6 offset-xl-5 d-flex justify-content-center">
              {" "}
              <img
                src={caseStudyImg}
                className="img_View"
                alt="1"
                style={{ width: "274px", paddingBottom: "2px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-3 pl-5 mt-3 mx-0">
        <div
          className="card cardViewCases"
          style={{ border: "none", boxShadow: "none" }}
        >
          <div className="card-body text-start">
            <div className="row">
              <div className="col-10">
                <h5 className="card-title card-title_view">Enhanced User Engagement to Boost Revenue</h5>
              </div>
              <div className="col-2 text-end">
                <h5 className="card-title">02</h5>
              </div>
              {/* <div className="card-title text-end">{card.title}</div> */}
            </div>

            <p className="card-text card-text-view">
                Our client is a pivotal part of the automotive industry, offering a dynamic platform that forges connections between vehicle users, service centers, specialized mechanics, and third-party service providers.
            </p>
            <a href="" style={{ textDecoration: "none" }} onClick={handleCaseStudiesCarvagon}>
              Learn More &rarr;
            </a>
          </div>
          <div className="row" style={{ marginTop: "-9px" }}>
            <div className="col-xl-6 offset-xl-5 text-center d-flex justify-content-center">
              {" "}
              <img
                src={CarvaganImage}
                className="img_View"
                alt="1"
                style={{ width: "274px", paddingBottom: "2px" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {showRemainingSlides && (
        <>
          <div className="row pt-3 pl-5">
            <div
              className="card cardViewCases"
              style={{ border: "none", boxShadow: "none" }}
            >
              <div className="card-body text-start">
                <div className="row">
                  <div className="col-6 ">
                    <h5 className="card-title card-title_view">Card3</h5>
                  </div>
                  <div className="col-6 text-end">
                    <h5 className="card-title">03</h5>
                  </div>
                  
                </div>

                <p className="card-text card-text-view">
                  Our company boasts a talented team of experts encompassing a
                  wide range of skill sets, including developers, designers,
                  business analysts, and more
                </p>
                <a href="#" style={{ textDecoration: "none" }}>
                  Learn More &rarr;
                </a>
              </div>
              <div className="row" style={{ marginTop: "-9px" }}>
                <div className="col-xl-6 offset-xl-5 text-center d-flex justify-content-center">
                  {" "}
                  <img
                    src={caseStudyImg}
                    className="img_View"
                    alt="1"
                    style={{ width: "274px", paddingBottom: "2px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}
      {/* <div className="text-center pt-4 pb-3">
        <button
          className="explore-button-view"
          onClick={handleExploreButtonClick}
        >
          Explore more courses &rarr;
        </button>
      </div> */}
    </div>
  );
};

export default CaseStudiesViews;
