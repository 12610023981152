import React, { useState, useEffect } from "react";
// import digital_mob from "../../images/digital_mob.svg";
import digital_mob from "../../images/digital_transform_new_img.svg";
// import digital_mobile from "../../images/digital_mobile.png";
import digital_mobile from "../../images/digital_transform_new_mob.svg";
import { motion } from "framer-motion";
import useDeviceCategory from "../../utils/useDimensions";
import figma_icon from "../../images/figma_icon_bg.svg";
import flutter_icon from "../../images/flutter_icon.svg";
import aws_icon from "../../images/aws_icon.svg";
import react_icon from "../../images/react_icon_bg.svg";
import db_icon from "../../images/db_icon.svg";
import firebase_icon from "../../images/firebase_icon.svg";
 
const duration = 1.2;

const screenHeight = window.innerHeight;

const variants = {
  section: {
    forwardin: {
      opacity: 0,
    },
    forwardout: {
      opacity: 0,
    },
    backwardout: {
      opacity: [0, 1],
    },
  },
};

function Slide1(props) {
  const animation = `${props.animation}${props.direction}`;

  const deviceCategory = useDeviceCategory();

  if (deviceCategory === "mobile") {
    return (
      <section
        style={{ backgroundColor: "#000", height: "100%", paddingTop: 20 }}
        className="lgShadow overflow-hidden"
      >
        <motion.div
          style={{ paddingBottom: 20 }}
          initial={{ opacity: 1 }}
          animate={animation}
          variants={variants["section"]}
          transition={{ duration, ease: "easeInOut" }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.5, y: 300 }}
            animate={{ opacity: 1, scale: 1, y: [300, 0] }}
            transition={{ duration, delay: 1.3, ease: "easeInOut" }}
            className="digital-trans-venzo-title d-flex flex-direction-column justify-content-center"
          >
            <div className="w-100">
              Why Venzo in App Development?
              <div className="digital-trans-initiate w-100 px-3 px-md-5">
                <p>
                  At our core, we build more than just apps—we create enduring
                  partnerships as trusted tech advisors, investing in your
                  success as our own.
                </p>
              </div>
            </div>
          </motion.div>
          <div className="d-flex justify-content-center position-fixed bottom-0 w-100">
            <motion.div
              initial={{ scale: 0.5, y: 100 }}
              animate={{ scale: [0.5, 1], y: -((screenHeight * 12) / 100) }}
              transition={{ duration, ease: "easeInOut", delay: 0.5 }}
              className="digital-square"
            ></motion.div>
          </div>

          <div
            className="d-flex px-3 justify-content-center position-fixed bottom-0 w-100"
            style={{ paddingTop: ".5rem", zIndex: 100 }}
          >
            <motion.div
              initial={{ y: 200 }}
              animate={{ y: [200, -50, 0] }}
              transition={{ duration, ease: "easeInOut", delay: 2 }}
              className="icons-digital-container-mobile pb-1 d-flex justify-content-between px-2 px-sm-3"
            >
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={figma_icon} style={{height:"48px", width:"48px"}} alt="icon" /></div>
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={firebase_icon} style={{height:"48px", width:"48px"}} alt="icon" /></div>
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={flutter_icon} style={{height:"48px", width:"48px"}} alt="icon" /></div>

              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={db_icon} style={{height:"48px", width:"48px"}} alt="icon" /></div>
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={aws_icon} style={{height:"48px", width:"48px"}} alt="icon" /></div>
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={react_icon} style={{height:"48px", width:"48px"}} alt="icon" /></div>
            </motion.div>
          </div>
          <motion.div
            initial={{ y: 600 }}
            animate={{ y: [600, -50, -10] }}
            transition={{ duration, ease: "easeInOut", delay: 2 }}
            className="position-fixed bottom-0 d-flex justify-content-center image-container"
          >
            <img src={digital_mobile} className="img_View" alt="excel" />
          </motion.div>
        </motion.div>
      </section>
    );
  }

  if (deviceCategory === "tablet" || deviceCategory === "laptop") {
    return (
      <section
        style={{ backgroundColor: "#000", height: "100%", paddingTop: 30 }}
        className="lgShadow overflow-hidden"
      >
        <motion.div
          style={{ paddingBottom: 20 }}
          initial={{ opacity: 1 }}
          animate={animation}
          variants={variants["section"]}
          transition={{ duration, ease: "easeInOut" }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.5, y: 300 }}
            animate={{ opacity: 1, scale: 1, y: [300, 0] }}
            transition={{ duration, delay: 1.3, ease: "easeInOut" }}
            className="digital-trans-venzo-title d-flex flex-direction-column justify-content-center"
          >
            <div className="w-100">
              Why Venzo in App Development?
              <div className="digital-trans-initiate w-100 px-3 px-md-5">
                <p>
                  At our core, we build more than just apps—we create enduring
                  partnerships as trusted tech advisors, investing in your
                  success as our own.
                </p>
              </div>
            </div>
          </motion.div>
          <div className="d-flex justify-content-center position-fixed bottom-0 w-100">
            <motion.div
              initial={{ scale: 0.5, y: 100 }}
              animate={{ scale: [0.5, 1], y: -((screenHeight * 10) / 100) }}
              transition={{ duration, easeInOut: "easeOut", delay: 0.5 }}
              className="digital-square"
            ></motion.div>
          </div>

          <div
            className="d-flex px-3 justify-content-center position-fixed bottom-0 w-100"
            style={{ paddingTop: ".5rem", zIndex: 100 }}
          >
            <motion.div
              initial={{ y: 250 }}
              animate={{ y: [250, -30, -10] }}
              transition={{ duration, ease: "easeInOut", delay: 2 }}
              className="icons-digital-container-mobile pb-1 d-flex justify-content-between px-2 px-sm-3"
            >
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={figma_icon} style={{height:"89.14px", width:"89.14px"}} alt="icon" /></div>
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={firebase_icon} style={{height:"89.14px", width:"89.14px"}} alt="icon" /></div>
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={flutter_icon} style={{height:"89.14px", width:"89.14px"}} alt="icon" /></div>

              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={db_icon} style={{height:"89.14px", width:"89.14px"}} alt="icon" /></div>
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={aws_icon} style={{height:"89.14px", width:"89.14px"}} alt="icon" /></div>
              <div className="icon-boxes-digital icon-box-white align-self-center"><img src={react_icon} style={{height:"89.14px", width:"89.14px"}} alt="icon" /></div>
            </motion.div>
          </div>
          <motion.div
            initial={{ y: 600 }}
            animate={{ y: [600, -50, -10] }}
            transition={{ duration, ease: "easeInOut", delay: 2 }}
            className="position-fixed bottom-0 d-flex justify-content-center image-container"
          >
            <img src={digital_mobile} className="img_View" alt="excel" />
          </motion.div>
        </motion.div>
      </section>
    );
  }

  return (
    <section
      style={{ backgroundColor: "#000", height: "100%", paddingTop: "5vh" }}
      className="lgShadow"
    >
      <motion.div
        initial={{ opacity: 1 }}
        animate={animation}
        variants={variants["section"]}
        transition={{ duration, ease: "easeInOut" }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5, y: 300 }}
          animate={{ opacity: 1, scale: 1, y: [300, 0] }}
          transition={{ duration, delay: 1.3, ease: "easeInOut" }}
          className="digital-trans-venzo-title d-flex flex-direction-column justify-content-center"
        >
          <div>
            Why Venzo in App Development?
            <div className="digital-trans-initiate">
              <p>
                At our core, we build more than just apps—we create enduring
                partnerships as trusted tech advisors, investing in your success
                as our own.
              </p>
            </div>
          </div>
        </motion.div>
        <div className="row d-flex justify-content-center position-fixed bottom-0 w-100">
          <motion.div
            initial={{ scale: 0.5, y: 100 }}
            animate={{ scale: [0.5, 1], y: 0 }}
            transition={{ duration, ease: "easeInOut", delay: 0.5 }}
            className="digital-square"
          ></motion.div>
        </div>

        <div
          className="row justify-content-center position-fixed bottom-0 w-100"
          style={{ paddingTop: "3.5rem" }}
        >
          <motion.div
            initial={{ x: -620 }}
            animate={{ x: [-620, 40, 0] }}
            transition={{ duration, ease: "easeInOut", delay: 2 }}
            className="icons-digital-container d-flex justify-content-start align-items-end"
          >
            <div className="icon-boxes-digital icon-box-white"><img src={figma_icon} alt="icon" /></div>
            <div className="icon-boxes-digital icon-box-white"><img src={firebase_icon} alt="icon" /></div>
            <div className="icon-boxes-digital icon-box-white"><img src={flutter_icon} alt="icon" /></div>
          </motion.div>
          <motion.div
            initial={{ y: 320 }}
            animate={{ y: [320, -40, 0] }}
            transition={{ duration, ease: "easeInOut", delay: 2 }}
            className="icons-digital-container d-flex justify-content-center image-container"
          >
            <img src={digital_mob} className="img_View" alt="excel" />
          </motion.div>
          <motion.div
            initial={{ x: 620 }}
            animate={{ x: [620, -40, 0] }}
            transition={{ duration, ease: "easeInOut", delay: 2 }}
            className="icons-digital-container d-flex justify-content-end align-items-end"
          >
            <div className="icon-boxes-digital icon-box-white"><img src={db_icon} alt="icon" /></div>
            <div className="icon-boxes-digital icon-box-white"><img src={aws_icon} alt="icon" /></div>
            <div className="icon-boxes-digital icon-box-white"><img src={react_icon} alt="icon" /></div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
}

export default Slide1;
