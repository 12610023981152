import { useEffect, useState } from "react";
const useDeviceCategory = () => {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  let device = "desktop";

  if (!width) {
    device = "desktop";
  } else if (width <= 576) {
    device = "mobile";
  } else if (width > 576 && width <= 834) {
    device = "tablet";
  } else if (width > 834 && width <= 1200) {
    device = "laptop";
  } else if (width > 1200) {
    device = "desktop";
  }

  return device;
};

export default useDeviceCategory;
