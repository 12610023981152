import React, { useState, useEffect } from "react";
import logo1 from "../../../images/bridgeStone_log.png";
import tringapss from "../../../images/tringapps.png";
import mangopoint from "../../../images/mangopoint.png";
import agshealth from "../../../images/agshealth.png";
import sirius from "../../../images/sirius.webp";
import vooki from "../../../images/vooki.png";
import rasaii from "../../../images/rasaii.webp";
import ebms from "../../../images/ebms.jpeg";
import cspsc from "../../../images/cspsc.webp";
import ClientTrustViews from "./ClientTrustViews";
import TechMahindra from "../../../images/tech-mahindra.png";
import Saama from "../../../images/saama.png";
import RapdiLoops from "../../../images/rapdi-loops.png";
import Cdw from "../../../images/cdw.png";
import PonPlus from "../../../images/pon-plus.png";

function ClientTrustUs() {
  const [isMobile, setIsMobile] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  return (
    // <div>
    //   <div className="row">
    //     <div className="text-center">
    //       <div className="clientViewUs  d-flex justify-content-center align-items-center">
    //         <div className="clienttrustView">
    //           <p className="clienttrustLayout">Client who always trust us</p>
    //         </div>

    //         {/* <div className="col-lg-6 col-xl-6 text-center">
    //                     <div className="content-box">
    //                     Client Who always trust us
    //                     </div>
    //                     </div> */}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div>
      {!isMobile ? (
        <div className={`clientViewUs d-flex justify-content-center align-items-center pt-2 ${
            isHovered ? 'hovered' : ''
          }`}  onMouseEnter={handleMouseEnter}
           >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="client_images">
                  <img src={logo1} className="client_img_views" alt="1" />
                  <img src={tringapss} className="client_img_views" alt="2" />
                  <img src={mangopoint} className="client_img_views" alt="3" />
                  <img src={agshealth} className="client_img_views" alt="5" />
                  <img src={sirius} className="client_img_views" alt="4" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3 text-center">
                <div className="client_images">
                  <img src={TechMahindra} className="client_img_views" alt="6" />
                  <img
                    src={vooki}
                    className="client_img_views"
                    alt="7"
                    style={{ height: 60, width: 60 }}
                  />
                </div>
              </div>
              <div className="col-6 text-center">
                <div className="clienttrustView">
                  <p className="clienttrustLayout pt-3">
                    Client who always trust us
                  </p>
                </div>
              </div>
              <div className="col-3 text-center">
                <div className="client_images">
                  <img src={Saama} className="client_img_views" alt="8" />
                  <img src={Cdw} className="client_img_views" alt="9" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                <div className="client_images">
                  <img src={rasaii} className="client_img_views" alt="11" />
                  <img
                    src={ebms}
                    className="client_img_views"
                    alt="10"
                    style={{ height: 60, width: 90 }}
                  />
                  <img src={RapdiLoops} className="client_img_views" alt="12" />
                  <img src={PonPlus} className="client_img_views" alt="13" />
                  <img src={cspsc} className="client_img_views" alt="14" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ClientTrustViews />
      )}
    </div>
  );
}

export default ClientTrustUs;
