import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
//import CaseStudiesCards from './CaseStudiesCards';
//import './CardSlider.css'; // Import your custom CSS file
import caseStudyImg from '../../../images/caseStudiesImg.png';
import CaseStudiesSlider from './CaseStudiesSlider';
import CaseStudiesAppDevelopSlider from './CaseStudiesAppDevelopSlider';

SwiperCore.use([Navigation, Pagination]);

const CaseStudiesAppDevelop = () => {
    const imageReferences = {
        pfm: caseStudyImg,
        carvagan: caseStudyImg,
        // Define references for other images
      };
    const cards = [
        {
          title: 'PFM',
          count: '01',
          description: 'Our company boasts a talented team of experts encompassing a wide range of skill sets, including developers, designers, business analysts, and more',
          image: imageReferences.pfm,
        },
        {
          title: 'Carvagan',
          count: '02',
          description: 'Our company boasts a talented team of experts encompassing a wide range of skill sets, including developers, designers, business analysts, and more',
          image: imageReferences.carvagan,
        },
        {
          title: 'Card 3',
          count: '03',
          description: 'Our company boasts a talented team of experts encompassing a wide range of skill sets, including developers, designers, business analysts, and more',
          image: imageReferences.carvagan,
        },
      ];
  return (
//     <div className="card-slider-container">
//       <Swiper
//         navigation
//         pagination={{ clickable: true }}
//         slidesPerView={2}
//         spaceBetween={10}
//       >
//         <SwiperSlide>
//           <div className="card">
//             {/* Card content for slide 1 */}
//             <div class="card bg-primary">
//     <div class="card-body text-center">
//       <p class="card-text">Some text inside the first card</p>
//     </div>
//   </div>
//           </div>
//         </SwiperSlide>
//         <SwiperSlide>
//           <div className="card">
//             {/* Card content for slide 2 */}
//             <div class="card bg-primary">
//     <div class="card-body text-center">
//       <p class="card-text">Some text inside the first card</p>
//     </div>
//   </div>
//           </div>
//         </SwiperSlide>
//         <SwiperSlide>
//           <div className="card">
//             {/* Card content for slide 2 */}
//             <div class="card bg-primary">
//     <div class="card-body text-center">
//       <p class="card-text">Some text inside the first card</p>
//     </div>
//   </div>
//           </div>
//         </SwiperSlide>
//         {/* Add more slides as needed */}
//       </Swiper>
//     </div>
<div>
<CaseStudiesAppDevelopSlider cards={cards} />
</div>
  );
};

export default CaseStudiesAppDevelop;