import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import StartBusiness from "../../../images/startBusiness.svg";
import SuccessBusiness2 from "../../../images/success_image2.svg";
import SuccessBusiness1 from "../../../images/success_image3.svg";
import SuccessBusiness2019 from "../../../images/success-logo-2019.svg";
import SuccessBusiness2020 from "../../../images/success-logo-2020.svg";
import SuccessBusiness2021 from "../../../images/success-logo-2021.svg";
import SuccessBusiness2022 from "../../../images/success-logo-2022.svg";
import useDeviceCategory from "../../../utils/useDimensions";
import { motion } from "framer-motion";

const variants = {
  slideLeft: {
    x: 0,
  },
  slideRight: {
    x: -330,
  },
};
function SuccessProcess({tooltipVisible}) {
  const [activeYear, setActiveYear] = useState(2023);
 // const [tooltipVisible, setTooltipVisible] = useState(false);
  const [animate, setAnimate] = useState("slideLeft");

  const imageReferences = {
    success1: StartBusiness,
    success2: SuccessBusiness2,
    success3: SuccessBusiness1,
    success4: SuccessBusiness2019,
    success5: SuccessBusiness2020,
    success6: SuccessBusiness2021,
    success7: SuccessBusiness2022,
  };

  const items = [
    { 
      id: 1, 
      year: 2023, 
      tooltipText: "2023", 
      image: imageReferences.success1,
      content: "Continuously evolving and setting benchmarks in the industry with sustainable and forward-thinking digital solutions." 
    },
    { 
      id: 2, 
      year: 2022, 
      tooltipText: "2022", 
      image: imageReferences.success7,
      content: "120+ Team size, New Product Development Registered US office." 
    },
    { 
      id: 3, 
      year: 2021, 
      tooltipText: "2021", 
      image: imageReferences.success6,
      content: "90+ team Size, New Enterprise accounts, New product development."
     },
     { 
      id: 4, 
      year: 2020, 
      tooltipText: "2020", 
      image: imageReferences.success5,
      content: "Helped Enterprises with proven lean offshore staff augmentation 50+ team size."
     },
     { 
      id: 5, 
      year: 2019, 
      tooltipText: "2019", 
      image: imageReferences.success4,
      content: "Develop bespoke Software Solutions and tailor-made enterprise application for companies."
     },
     { 
      id: 6, 
      year: 2018, 
      tooltipText: "2018", 
      image: imageReferences.success3,
      content: "Banking Business & Technology Consulting to Banks & Financial Institutions"
     },
     { 
      id: 7, 
      year: 2017, 
      tooltipText: "2017", 
      image: imageReferences.success2,
      content: "Signed with Surecomp to handle their Trade Finance Systems Implementation/QA Services."
     },
     { 
      id: 8, 
      year: 2016, 
      tooltipText: "2016", 
      image: imageReferences.success1,
      content: "Company Registered in Chennai, India" 
    },
  ];

  const handleYearClick = (year) => {
    if (year >= 2016 && year <= 2023) {
      setActiveYear(year);
    }
  };

  // const handleMouseEnter = () => {
  //   setTooltipVisible(true);
  // };

  // const handleMouseLeave = () => {
  //   setTooltipVisible(false);
  // };

  const moveYearRight = () => {
    handleYearClick(activeYear + 1);
    if (activeYear === 2019 && deviceCategory === "mobile") {
      setAnimate("slideRight");
    }
  };

  const moveYearLeft = () => {
    handleYearClick(activeYear - 1);
    if (activeYear === 2020 && deviceCategory === "mobile") {
      setAnimate("slideLeft");
    }
  };

  const deviceCategory = useDeviceCategory();

  if (deviceCategory === "mobile" || deviceCategory === "tablet") {
    return (
      <div>
        <div className="container">
          <h1 className="success-heading">
            Our success was not a one day process
          </h1>
          <div className="row pt-5">
            <div className="col-12 px-3">
              <Carousel
                showThumbs={false}
                showArrows={false}
                emulateTouch={true}
                centerMode={true}
                centerSlidePercentage={100}
                selectedItem={2023 - activeYear}
                onClickItem={(year) => handleYearClick(year + 2016)}
              >
                {items.map((item) => (
                  <div key={item.id}>
                    <div
                      className={`card success-cards ${
                        activeYear === item.year ? "active" : ""
                      }`}
                    >
                      <img
                        src={item.image}
                        className="card-img-center"
                        alt="..."
                        style={{ width: "68px", height: "67px" }}
                      />
                      <div className="text-start pt-2">
                        <h5 className="card-title success-card-title">
                          {item.tooltipText}
                        </h5>
                        <div className="card-body">
                          <p className="card-text success-card-text">
                            {item.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-4">
            <div
              className="d-flex justify-content-between"
              style={{
                width: deviceCategory === "mobile" ? 350 : 640,
                height: 30,
              }}
            >
              <div onClick={() => moveYearLeft()} style={{ color: "#fff" }}>
                &lt;
              </div>
              <div
                style={{
                  //width: deviceCategory === "mobile" ? 333 : 640,
                  width: deviceCategory === "mobile" ? 300 : 640,
                  overflow: "hidden",
                }}
              >
                <motion.div
                  animate={animate}
                  variants={variants}
                  className="px-3 d-flex justify-content-between"
                //  style={{ width: 640 }}
                  style={{ width: 680 }}
                >
                  {items.map((item) => (
                    <span
                      onClick={() => handleYearClick(item.year)}
                      style={{
                        color: "#fff",
                        display: "inline-block",
                        width: 80,
                        opacity: activeYear === item.year ? 1 : 0.5,
                      }}
                    >
                      {item.tooltipText}
                    </span>
                  ))}
                </motion.div>
              </div>
              <div onClick={() => moveYearRight()} style={{ color: "#fff" }}>
                &gt;
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="container">
        <h1 className="success-heading">
          Our success was not a one day process
        </h1>
        <div className="row pt-5">
          <div className="col-lg-2">
            <div
              className="custom-list"
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              {items.map((item) => (
                <div
                  key={item.id}
                  className={`list-item ${
                    activeYear === item.year ? "active" : ""
                  }`}
                  onClick={() => handleYearClick(item.year)}
                >
                  <span style={{ color: "#fff" }}>&#x268A;</span>
                </div>
              ))}
              {tooltipVisible && (
                <div className="custom-tooltip">
                  {items.map((item) => (
                    <div
                      key={item.id}
                      className="custom-toolviews"
                      onClick={() => handleYearClick(item.year)}
                    >
                      {item.tooltipText}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-10">
            <Carousel
              showThumbs={false}
              showArrows={true}
              emulateTouch={true}
              centerMode={true}
              centerSlidePercentage={33.3}
              selectedItem={2023 - activeYear}
              onClickItem={(year) => handleYearClick(year + 2016)}
            >
              {items.map((item) => (
                <div key={item.id}>
                  <div
                    className={`card success-cards ${
                      activeYear === item.year ? "active" : ""
                    }`}
                  >
                    <img
                      src={item.image}
                      className="card-img-top"
                      alt="..."
                      style={{ width: "68px", height: "67px" }}
                    />
                    <div className="text-start pt-5">
                      <h5 className="card-title success-card-title">
                        {item.tooltipText}
                      </h5>
                      <div className="card-body">
                        <p className="card-text success-card-text">
                          {item.content}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessProcess;
