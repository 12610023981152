import React from 'react'

export const CommonCaseStuies = {
    "healthcare-iot-for-smarter-device-monitoring": {
      personalHeading: "Healthcare IoT for Smarter Device Monitoring",
      personalContent: "Our client is a prominent healthcare entity, manufacturing and trading medical equipment and devices. With over 12 years of expertise, they specialize in hospital infrastructure planning, executing critical care spaces like operating rooms and intensive care units, and providing infection prevention solutions. Their excellence extends to driving technology-forward solutions in the health and wellness sector.",
      problemStatement: "Problem Statement",
      problemStatementContent: "The client faced significant challenges in monitoring critical data from medical devices such as nitrogen and oxygen cylinders in hospitals and healthcare institutes. Key parameters like temperature, humidity, and pressure were inadequately tracked, leading to inefficiencies in their usage. Additionally, there was a lack of a systematic approach to manage supply and demand and difficulties in connecting and centralizing data from multiple locations for comprehensive monitoring.",
      solutionStatement: "Solution",
      solutionStatementContent: "To address these challenges, we developed an innovative web application that leverages AWS IoT Core for real-time data acquisition from medical device sensors. This robust application offers:",  
    },
    "Enhanced-user-engagement-to-boost-revenue": {
        personalHeading: "Enhanced User Engagement to Boost Revenue",
        personalContent: "Our client is a pivotal part of the automotive industry, offering a dynamic platform that forges connections between vehicle users, service centers, specialized mechanics, and third-party service providers.",
        problemStatement: "Challenge",
        problemStatementContent: "Despite having a solid business model, the client faced stagnation in growth. The root cause was the shortfall in their application's performance and user experience. The lack of critical features in the app, architectural inefficiencies, and subpar performance became roadblocks to user satisfaction, functionality, and market expansion.",
        solutionStatement: "Solution",
        solutionStatementContent: "We developed a high-performance web and mobile application that expanded third-party service integrations while enhancing user experience. We addressed significant performance bottlenecks by rearchitecting the app’s structure and incorporating essential new features.",  
    },
    "streamlining-it-training-and-Placements-a-success-story": {
      personalHeading: "Streamlining IT Training and Placements: A Success Story",
      personalContent: "Our client, a premier IT training and placement agency, has a network of directly operated and franchise centers. They offer comprehensive technology training to students both online and offline, bridging them with seasoned IT professionals as instructors. Remarkably, our client boasts an impressive portfolio encompassing 11 branches, a roster of over 1000 trainers, and an extensive catalog of 120+ courses. ",
      problemStatement: "Scope of Work",
      problemStatementContent: "The client requested that we take control of the project from the previous vendor and maximize the reuse of the work completed over the past 18 months. The scope of the project included creating both a web application and a mobile app, encompassing various modules such as student enrollment, class, and trainer assignment, trainer payments, invoice generation, job placements, student attendance, class progress tracking, trainer feedback, marketing campaigns, push notifications, and more.",
      solutionStatement: "Our Approach",
      solutionStatementContent: "After analyzing the existing code, we informed the client about the challenges associated with reusing it, despite their concerns about extending timelines. As part of POC, we developed a common component for handling the data submissions and retrieval, both at the backend-API level and front-end forms level. This common component enabled us to promise a four-month project schedule. We employed an agile methodology for rapid client feedback and issue resolution. Leveraging cloud-based database solutions ensured rapid iteration and future scalability, easing maintenance efforts.",  
  },
  "bespoke-erp-solution-for-leading-cookware-manufacturer": {
    personalHeading: "Bespoke ERP Solution for Leading Cookware Manufacturer",
    personalContent: "Our client is a prominent stainless steel cookware and utensils manufacturer with an impressive annual turnover of 50 crore. They had previously attempted to implement industry-leading ERP systems from SAP and other providers but encountered setbacks, leading them to seek a custom solution.",
    problemStatement: "Our Engagement",
    problemStatementContent: "Upon initial engagement with the client, we challenged the necessity of a custom solution, presenting off-the-shelf ERP live demos for consideration. However, after thorough discussions, it became evident that the client's unique manufacturing processes demanded significant customization that couldn't be achieved with existing solutions.",
    solutionStatement: "Solution",
    solutionStatementContent: "We developed a high-performance web and mobile application that expanded third-party service integrations while enhancing user experience. We addressed significant performance bottlenecks by rearchitecting the app’s structure and incorporating essential new features.",  
},
  };