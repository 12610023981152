import Home from "./pages/Home";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useRoutes,
} from "react-router-dom";

import Page from "./layouts/Page";
import DigitalTransForm from "./pages/DigitalTransform";
import ProductDevelopment from "./pages/ProductDevelopment";
import TransformSlide from "./pages/TransformSlide";
import Company from "./pages/Company";
import Companies from "./pages/Companies";
import Contact from "./pages/Contact";
import CaseStudiesSection from "./components/DigitalTrans/CaseStudies/CaseStudiesSection";
import TransformProductSlides from "./pages/TransformProductSlides";
import TempHome from "./pages/TempHome";

function Router() {
  return useRoutes([
    {
      path: "/",
      element: <TempHome />,
    },
    {
      path: "/",
      element: <Page />,
      children: [
        {
          path: "product-development-company",
          element: <ProductDevelopment />,
        },
        {
          path: "app-development-company",
          element: <DigitalTransForm />,
        },
        {
          path: "product-transform",
          element: <TransformProductSlides />,
        },
        {
          path: "digital-transform",
          element: <TransformSlide />,
        },
        {
          path: "about-us",
          element: <Companies />,
        },
        {
          path: "contact-us",
          element: <Contact />,
        },
        {
          path: "/case-studies/:id",
          element: <CaseStudiesSection />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}

export default Router;
