import dotted_vector from "../../../images/dotted_vector.png";

export default function Phase3(props) {
  const { openPopUp } = props;
  return (
    <div className="d-flex justify-content-space-between align-items-center gap-lg-5">
      <div className="phase-vector">
        <img alt="dotted_vector" src={dotted_vector} />
      </div>
      <div>
        <div className="phase-title">Rapid Prototyping</div>
        <div className="phase-summary">
          <ul>
            <li>Wireframes</li>
            <li>Interactive Mockups</li>
            <li>UI UX Design</li>
            <li>Early Feedback Adoption</li>
          </ul>
          <button
            type="button"
            className="btn bg-transparent product-ctc-button" onClick={openPopUp}>Visualize Your Ideas With Our Design Experts  &gt;</button>
        </div>
      </div>
    </div>
  );
}
