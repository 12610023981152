import React, { useState, useEffect } from "react";
import logo from "../../../images/logo.svg";
import in_icon from "../../../images/in_icon.svg";
import fb_icon from "../../../images/fb_icon.svg";
import insta_icon from "../../../images/insta_icon.svg";
import youtube_icon from "../../../images/youtube_icon.svg";
import Vector_CopyRights from "../../../images/Vector_CopyRights.svg";
import FooterViews from "./FooterViews";
import fb_color_icon from "../../../images/fb-color-icon.png";
import indeed_color_icon from "../../../images/indeed-color-icon.png";
import insta_color_icon from "../../../images/insta-color-icon.png";
import youtube_color_icon from "../../../images/youtube-color-icon.png";
import { useNavigate } from "react-router-dom";


function Footer() {
  const [isMobile, setIsMobile] = useState(false);
  const [isFbIcon, setIsFbIcon] = useState(false);
  const [isInIcon, setIsInIcon] = useState(false);
  const [isInstaIcon, setIsInstaIcon] = useState(false);
  const [isYoutubeIcon, setIsYoutubeIcon] = useState(false);
  const navigate = useNavigate();

  const goto = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {!isMobile ? (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <footer
                className="footer"
                style={{ backgroundColor: "#131b2ac7" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                        <a href="/">
                          <img
                            src={logo}
                            className="footer_logo-views img_View"
                            alt="logo"
                          />
                        </a>
                      </div>

                      {/* <p className="white-text">
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 text of the printing.
                      </p> */}
                      {/* <div className="footer-social-links m-t-30 d-flex">
                        <li style={{ marginTop: "4px" }}>
                          {" "}
                          <p>Follow Us</p>
                        </li>
                        <li style={{ paddingLeft: 10 }}>
                          <a href="#"
                            onMouseEnter={() => setIsFbIcon(true)}
                            onMouseLeave={() => setIsFbIcon(false)}
                            >
                            <img src={isFbIcon ? fb_color_icon : fb_icon} className="" alt="logo" />
                          </a>
                          <a href="#"
                            onMouseEnter={() => setIsInIcon(true)}
                            onMouseLeave={() => setIsInIcon(false)}
                            >
                            <img src={isInIcon ? indeed_color_icon : in_icon} className="" alt="logo" />
                          </a>
                          <a href="#"
                            onMouseEnter={() => setIsInstaIcon(true)}
                            onMouseLeave={() => setIsInstaIcon(false)}
                            >
                              <img 
                              src={isInstaIcon
                                    ? insta_color_icon
                                    : insta_icon} 
                              className="" alt="logo" />
                          </a>
                          <a href="#"
                            onMouseEnter={() => setIsYoutubeIcon(true)}
                            onMouseLeave={() =>setIsYoutubeIcon(false)}
                          >
                            <img 
                            src={isYoutubeIcon
                                  ? youtube_color_icon
                                  : youtube_icon} 
                              className="" 
                              alt="logo" />
                          </a>
                        </li>
                      </div> */}
                    </div>
                    <div className="col-md-3 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                        About
                      </div>
                      <div className="footer-links">
                        <a href=""  onClick={() => goto("/")}>Home</a>
                        <a href=""  onClick={() => goto("/about-us")}>Company</a>
                        <a href=""  onClick={() => goto("/contact-us")}>Contact</a>
                        {/* <a href="#">
                              Wallstreet
                          </a> */}
                      </div>
                    </div>
                    <div className="col-md-3 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                        Services
                      </div>
                      <div className="footer-links">
                        <a href="" onClick={() => goto("/product-development-company")}>Product Development</a>
                        <a href="" onClick={() => goto("/app-development-company")}>App Development</a>
                        {/* <a href="#">
                              Terms & conditions
                          </a> */}
                        {/* <a href="#">Privacy policy</a> */}
                      </div>
                    </div>
                    <div className="col-md-3 m-b-30">
                    <div className="footer-social-links m-t-30">
                        <div style={{ marginTop: "4px" }}>
                          {" "}
                          <p>Follow Us</p>
                        </div>
                        <div className="d-flex">
                        <li style={{ paddingLeft: 0 }}>
                          <a href="https://www.facebook.com/VenzoTechnologies/"
                            onMouseEnter={() => setIsFbIcon(true)}
                            onMouseLeave={() => setIsFbIcon(false)}
                            target="_blank"
                            >
                            <img src={isFbIcon ? fb_color_icon : fb_icon} className="" alt="logo" />
                          </a>
                          <a href="https://www.instagram.com/venzo_tech/"
                            onMouseEnter={() => setIsInIcon(true)}
                            onMouseLeave={() => setIsInIcon(false)}
                            target="_blank"
                            >
                            <img src={isInIcon ? insta_color_icon : in_icon} className="" alt="logo" />
                          </a>
                          <a href="https://www.linkedin.com/company/venzo-technologies/"
                            onMouseEnter={() => setIsInstaIcon(true)}
                            onMouseLeave={() => setIsInstaIcon(false)}
                            target="_blank"
                            >
                              <img 
                              src={isInstaIcon
                                    ? indeed_color_icon
                                    : insta_icon} 
                              className="" alt="logo" />
                          </a>
                          <a href="https://www.youtube.com/@venzotechnologies9121"
                            onMouseEnter={() => setIsYoutubeIcon(true)}
                            onMouseLeave={() =>setIsYoutubeIcon(false)}
                            target="_blank"
                          >
                            <img 
                            src={isYoutubeIcon
                                  ? youtube_color_icon
                                  : youtube_icon} 
                              className="" 
                              alt="logo" />
                          </a>
                        </li>
                        </div>
                       
                      </div>
                    </div>
                    {/* <div className="col-md-3 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                        Services
                      </div>
                      <div className="footer-links">
                        <a href="#">Product Development</a>
                        <a href="#">Digital Transformation</a>
                        <a href="#">Website Development</a>
                        <a href="#">ECommerce Development</a>
                      </div>
                    </div> */}
                    {/* <div className="col-md-2 m-b-30">
                      <div className="footer-title m-t-5 m-b-20 p-b-8">
                        Services
                      </div>
                      <div className="footer-links">
                        <a href="#">Digital Marketing</a>
                        <a href="#">UX UI Design</a>
                        <a href="#">QA Services</a>
                        <a href="#">Staff Augmentation</a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </footer>
              <div className="footer-bottom footer_dashed-lines">
                Copyright 2024 Venzo. All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      ) : (
        <FooterViews />
      )}
    </div>
  );
}

export default Footer;
