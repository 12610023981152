import React, { useEffect } from "react";
import logo_opening from "../images/logo_opening.gif";
// import home_bg from "../images/Home_BG.png";
// import home_bg from "../images/home_bg.gif";
import home_bg from "../images/home_bg_new.webm";
import "../App.css";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { MobileNavBar } from "../components/Navbar/MobileNavBar";
import Navbar from "../components/Navbar";
import useDeviceCategory from "../utils/useDimensions";
//import useMetaTags from "../hooks/MetaTags";

const delay = 3.5;
const animationPresentView = "animationShown";

function Home() {
  const navigate = useNavigate();
  const deviceCategory = useDeviceCategory();

  // useEffect(() => {
  //       const animationShown = Cookies.get(animationPresentView);
  //       console.log("animationShown", animationShown);
  //   if (!animationShown) {
  //     Cookies.set(animationPresentView, "true", { expires:  null });
  //   }

  //     const handleBeforeUnload = (event) => {

  //      // Cookies.remove(animationPresentView, { expires: new Date(0) });
  //      Cookies.remove(animationPresentView);

  //       event.returnValue = "";
  //     };

  //     window.addEventListener("beforeunload", handleBeforeUnload);

  //     return () => {
  //       window.removeEventListener("beforeunload", handleBeforeUnload);
  //     };
  // }, []);

  const goto = (path) => {
    navigate(path);
  };

  if (deviceCategory === "tablet") {
    return (
      <div className="w-100 h-100">
        <Helmet>
          <title>
            Custom Software Development Company - Venzo Technologies
          </title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="description"
            content="Venzo offers integrated software solutions for startups, SMEs, and large enterprises. With expertise in Mobile App, Website Development, Cloud, etc."
          />
        </Helmet>

        <AnimatePresence>
          {Cookies.get(animationPresentView) ? null : (
            <motion.div
              initial={{ display: "flex", opacity: 1 }}
              animate={{ display: "none" }}
              transition={{ delay, duration: 1 }}
              exit={{ opacity: 0 }}
              className="w-100 h-100 position-absolute justify-content-center align-items-center"
            >
              <div>
                <img src={logo_opening} className="App-logo" alt="logo" />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          initial={{ display: "none" }}
          animate={{ display: "block" }}
          transition={{ delay: Cookies.get(animationPresentView) ? 0 : delay }}
          className="container h-100 p-0 mw-100 overflow-hidden"
        >
          {deviceCategory === "mobile" || deviceCategory === "tablet" ? (
            <Navbar />
          ) : null}

          <div className="d-flex position-absolute justify-content-center align-items-center home-bg-wrapper">
            <div>
              {/* <img src={home_bg} className="home-bg" alt="logo" /> */}
              <video className="home-bg" autoPlay loop muted playsInline>
                <source src={home_bg} type="video/webm" />
              </video>
            </div>
          </div>
          <div className="row h-100">
            <div
              onClick={() => goto("/product-development-company")}
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cursor-pointer product-blue d-flex flex-column justify-content-center align-items-center text-color-light-1 product-section"
              style={{ height: "45%" }}
            >
              <div className="home-section cursor-pointer">
                <div className="font-weight-500 font-size-42 product-views-mobile">
                  <div className="text-start">Product Development</div>
                </div>
                {/* <div className="font-weight-500 font-size-42 product-views">
                  <div className="text-start">Product</div>
                  <div className="text-start">Development</div>
                </div> */}
                <div className="font-weight-400 font-size-18 text-color-light-2 mt-3 product-content">
                  <div className="text-start">
                    Are you an entrepreneur or an innovator looking to launch a
                  </div>
                  <div className="text-start">
                    new web or mobile app? We specialize in delivering a Minimum
                  </div>
                  <div>
                    {" "}
                    Viable Product (MVP) in 6 weeks. From idea validation
                  </div>

                  <div>
                    to launch, our framework ensures that your product resonates
                  </div>
                  <div>
                    with your target audience and thrives in the market.
                  </div>
                  <div></div>
                </div>
                <div className="explore-btn d-flex justify-content-between px-3 align-items-center">
                  <div>Begin Your Journey</div> <div>&rarr;</div>
                </div>
              </div>
            </div>
            <div
              onClick={() => goto("/app-development-company")}
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cursor-pointer digital-blue d-flex flex-column justify-content-center align-items-center text-color-light-1 digital-section"
              style={{ height: "55%", paddingLeft: "85px" }}
            >
              <div className="home-section cursor-pointer">
                <div
                  className="font-weight-500 font-size-42 digital-views-mobile"
                  style={{ marginTop: "-150px" }}
                >
                  <div className="text-start">App Development</div>
                </div>
                {/* <div className="font-weight-500 font-size-42 digital-views">
                  <div className="text-start">App</div>
                  <div className="text-start">Development</div>
                </div> */}
                <div className="font-weight-400 font-size-18 text-color-light-2 mt-3 digital-content">
                  <div className="text-start">
                    Are you an established business looking to enhance and
                    optimize
                  </div>
                  <div className="text-start">
                    your current application? Whether you`re updating legacy
                    systems
                  </div>

                  <div>
                    or crafting entirely new strategies, we`re here to guide
                  </div>
                  <div>
                    your transformation journey. Explore our comprehensive app
                  </div>
                  <div>development services.</div>
                </div>
                <div className="explore-btn d-flex justify-content-between px-3 align-items-center">
                  <div>Let’s Solve Your Problem</div> <div>&rarr;</div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <div className="home-footer text-color-light-3 font-size-18 font-weight-600">
          <div className="d-flex justify-content-between">
            <div className="cursor-pointer" onClick={() => goto("/about-us")}>
              Company
            </div>
            <div className="cursor-pointer" onClick={() => goto("/contact-us")}>
              Contact
            </div>
          </div>
        </div>
      </div>
    );
  }
  //useMetaTags('Custom Software Development Company - Venzo Technologies', 'Venzo offers integrated software solutions for startups, SMEs, and large enterprises. With expertise in Mobile App, Website Development, Cloud, etc.');

  return (
    <div className="w-100 h-100">
      <Helmet>
        <title>Custom Software Development Company - Venzo Technologies</title>
        <meta
          name="description"
          content="Venzo offers integrated software solutions for startups, SMEs, and large enterprises. With expertise in Mobile App, Website Development, Cloud, etc."
        />
      </Helmet>

      <AnimatePresence>
        {Cookies.get(animationPresentView) ? null : (
          <motion.div
            initial={{ display: "flex", opacity: 1 }}
            animate={{ display: "none" }}
            transition={{ delay, duration: 1 }}
            exit={{ opacity: 0 }}
            className="w-100 h-100 position-absolute justify-content-center align-items-center"
          >
            <div>
              <img src={logo_opening} className="App-logo" alt="logo" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        initial={{ display: "none" }}
        animate={{ display: "block" }}
        transition={{ delay: Cookies.get(animationPresentView) ? 0 : delay }}
        className="container h-100 p-0 mw-100 overflow-hidden"
      >
        {deviceCategory === "mobile" || deviceCategory === "tablet" ? (
          <Navbar />
        ) : null}

        <div className="d-flex position-absolute justify-content-center align-items-center home-bg-wrapper">
          <div>
            {/* <img src={home_bg} className="home-bg" alt="logo" /> */}
            <video className="home-bg" autoPlay loop muted playsInline>
              <source src={home_bg} type="video/webm" />
            </video>
          </div>
        </div>
        <div className="row h-100">
          <div
            onClick={() => goto("/product-development-company")}
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cursor-pointer product-blue d-flex flex-column justify-content-center align-items-center text-color-light-1 product-section"
          >
            <div className="home-section cursor-pointer">
              {deviceCategory === "mobile" || deviceCategory === "tablet" ? (
                <div className="font-weight-500 font-size-42 product-views-mobile">
                  <div className="text-start">Product Development</div>
                </div>
              ) : (
                <div className="font-weight-500 font-size-42 product-views">
                  <div className="text-start">Product</div>
                  <div className="text-start">Development</div>
                </div>
              )}
              {/* <div className="font-weight-500 font-size-42 product-views">
                <div className="text-start">Product</div>
                <div className="text-start">Development</div>
              </div> */}
              <div className="font-weight-400 font-size-18 text-color-light-2 mt-3 product-content">
                <div className="text-start">
                  Are you an entrepreneur or an innovator
                </div>
                <div className="text-start">
                  looking to launch a new web or mobile app? We
                </div>
                <div>specialize in delivering a Minimum Viable </div>
                <div>Product (MVP) in 6 weeks. From idea validation </div>
                <div>to launch, our framework ensures that your </div>
                <div>product resonates with your target audience </div>
                <div>and thrives in the market.</div>
              </div>
              <div className="explore-btn d-flex justify-content-between px-3 align-items-center">
                <div>Begin Your Journey</div> <div>&rarr;</div>
              </div>
            </div>
          </div>
          <div
            onClick={() => goto("/app-development-company")}
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cursor-pointer digital-blue d-flex flex-column justify-content-center align-items-center text-color-light-1 digital-section"
          >
            <div className="home-section cursor-pointer">
              {deviceCategory === "mobile" || deviceCategory === "tablet" ? (
                <div
                  className="font-weight-500 font-size-42 digital-views-mobile"
                  style={{ marginTop: "-60px" }}
                >
                  <div className="text-start">App Development</div>
                </div>
              ) : (
                <div className="font-weight-500 font-size-42 digital-views">
                  <div className="text-start">App</div>
                  <div className="text-start">Development</div>
                </div>
              )}
              {/* <div className="font-weight-500 font-size-42 digital-views">
                <div className="text-start">App</div>
                <div className="text-start">Development</div>
              </div> */}
              <div className="font-weight-400 font-size-18 text-color-light-2 mt-3 digital-content">
                <div className="text-start">
                  Are you an established business looking
                </div>
                <div className="text-start">
                  to enhance and optimize your current
                </div>
                <div>application? Whether you`re updating</div>
                <div>legacy systems or crafting entirely new</div>
                <div>strategies, we`re here to guide your</div>
                <div>transformation journey. Explore our</div>
                <div>comprehensive app development services.</div>
              </div>
              <div className="explore-btn d-flex justify-content-between px-3 align-items-center">
                <div>Let’s Solve Your Problem</div> <div>&rarr;</div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <div className="home-footer text-color-light-3 font-size-18 font-weight-600">
        <div className="d-flex justify-content-between">
          <div className="cursor-pointer" onClick={() => goto("/about-us")}>
            Company
          </div>
          <div className="cursor-pointer" onClick={() => goto("/contact-us")}>
            Contact
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
