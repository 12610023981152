import dotted_vector from "../../../images/dotted_vector.png";

export default function Phase2(props) {
  const { openPopUp } = props;
  return (
    <div className="d-flex justify-content-space-between align-items-center gap-lg-5">
      <div className="phase-vector">
        <img alt="dotted_vector" src={dotted_vector} />
      </div>
      <div>
        <div className="phase-title blue">Product Definition</div>
        <div className="phase-summary">
          <ul>
            <li>Product Vision & Problem Statement</li>
            <li>Requirement Definition</li>
            <li>User Journey Definition</li>
            <li>Project Plan</li>
            <li>Success Metrics</li>
          </ul>
          <button
            type="button"
            className="btn bg-transparent product-ctc-button" onClick={openPopUp}>Engage With Our Product Manager &gt;</button>
        </div>
      </div>
    </div>
  );
}
