import React from "react";
// import cultureImage from "../../../images/venzo_culturen_new_image.png";
import cultureImage from "../../../images/culture_image_resize.svg";
import VenzoCultureFronview from "../../../images/venzo-culture-front-view-image.png";
import useDeviceCategory from "../../../utils/useDimensions";
//import venzoLogo from "../../../images/venzo_culture_front_view.svg";
import culture_mobile from "../../../images/culture_mobile.png";
import venzo_logo from "../../../images/venzo_logo.png";

function Culture() {
  const deviceCategory = useDeviceCategory();

  if (deviceCategory === "mobile" || deviceCategory === "tablet") {
    return (
      <div>
        <div className="">
          <div className="row pt-5 mt-5">
            <div className="hover-scroll-image-container">
              <div className="position-absolute w-100 h-100 culture-overlay"></div>
              <div
                className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                style={{
                  backgroundImage: `url(${venzo_logo})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    marginBottom: 140,
                    fontSize: 28,
                    fontWeight: 500,
                  }}
                >
                  Culture @
                </div>
              </div>
              <img
                src={culture_mobile}
                alt="Culture"
                className="hover-scroll-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="">
        <div className="row pt-5 mt-5">
          <div className="hover-scroll-image-container">
            <div className="position-absolute w-100 h-100 culture-overlay"></div>
            <div
              className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center hover-venzo-logo-view"
              style={{
                backgroundImage: `url(${venzo_logo})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "232px 210px",
              }}
            >
              <div
                style={{
                  color: "#fff",
                  marginBottom: 300,
                  fontSize: 45,
                  fontWeight: 500,
                }}
              >
                Culture @
              </div>
            </div>
            <img
              src={cultureImage}
              alt="Culture"
              className="hover-scroll-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Culture;
