import { useEffect, useState } from "react";
import _ from "lodash";

import Slide1 from "../components/DigitalTrans/Slide1";
import Slide2 from "../components/DigitalTrans/Slide2";
import Slide3 from "../components/DigitalTrans/Slide3";
import { AnimatePresence, motion } from "framer-motion";

import { animation_direction } from "../utils/constants";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

const MAX_SLIDES = 3;
const minSwipeDistance = 5;

function DigitalTransForm() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [startAnimation, setStartAnimation] = useState("initial");
  const [inProgress, setInProgress] = useState(false);
  const [slideInOut, setSlideInOut] = useState("");
  const [phase, setPhase] = useState(0);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state && state.path === "transform") {
      window.addEventListener("beforeunload", () => navigate(pathname, {}));
      setInProgress(true);
      setCurrentSlide(state.slide);
      setPhase(state.phase);
      setTimeout(() => setInProgress(false), 1500);
      return () => {
        window.removeEventListener("beforeunload", () =>
          navigate(pathname, {})
        );
      };
    }
  }, [state?.path]);

  const changeSlide = (e) => {
    if (inProgress || (currentSlide === 3 && phase !== 0)) {
      return;
    }
    setInProgress(true);
    const deltaY = e.deltaY;

    if (deltaY > 0 && currentSlide < MAX_SLIDES && !isFormOpen) {
      nextSlide();
    } else if (deltaY < 0 && currentSlide > 1 && !isFormOpen) {
      prevSlide();
    }
    setTimeout(() => setInProgress(false), 1500);
  };

  const nextSlide = () => {
    if (inProgress || currentSlide === 3) {
      return;
    }

    setStartAnimation("forward");
    if (currentSlide === 1) {
      setSlideInOut("in");
    } else {
      setSlideInOut("out");
    }
    setTimeout(
      () => setCurrentSlide(currentSlide + 1),
      currentSlide == 1 ? 500 : currentSlide == 2 ? 600 : 200
    );
  };

  const prevSlide = () => {
    setStartAnimation("backward");
    if (currentSlide === 3) {
      setSlideInOut("in");
    } else {
      setSlideInOut("out");
    }
    setTimeout(() => setCurrentSlide(currentSlide - 1), 200);
  };

  const getSlide = (isFormOpen, setIsFormOpen) => {
    switch (currentSlide) {
      case 1:
        return <Slide1 animation={startAnimation} direction={slideInOut} />;
      case 2:
        return <Slide2 animation={startAnimation} direction={slideInOut} />;
      case 3:
        return (
          <Slide3
            animation={startAnimation}
            direction={slideInOut}
            setPhase={setPhase}
            path={state?.path}
            currentPhase={phase}
            isFormOpen={isFormOpen} 
          setIsFormOpen={setIsFormOpen} 
          />
        );
      default:
        return <Slide1 animation={startAnimation} />;
    }
  };

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (
      !touchStart ||
      !touchEnd ||
      inProgress ||
      (currentSlide === 3 && phase !== 0)
    ) {
      return;
    }

    setInProgress(true);

    const distance = touchStart - touchEnd;
    const isSwipeDown = distance > minSwipeDistance;
    const isSwipeUp = distance < -minSwipeDistance;
    if (isSwipeDown) {
      nextSlide();
    } else if (isSwipeUp) {
      prevSlide();
    }

    setTimeout(() => setInProgress(false), 500);
  };

  const debounceSlideChange = _.throttle(changeSlide, 1000);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="h-100 overflow-hidden"
        style={{
          background: "linear-gradient(180deg, #FFF 0%, #9CE0FF 306.28%)",
        }}
        onWheel={debounceSlideChange}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Helmet>
          <title>App Development Company in India, Chennai | Venzo</title>
          <meta
            name="description"
            content="Venzo is a leading app development company in India. Our expert team develops high-quality mobile apps for iOS & Android platforms that meet your business needs."
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        {getSlide(isFormOpen, setIsFormOpen)}
      </motion.div>
    </AnimatePresence>
  );
}

export default DigitalTransForm;
