import React from "react";

function LifeOfVenzo() {
  return (
    <div>
      <div className="container">
        <div className="row text-center">
          <h1 className="lifeofvenzo-heading">Life at venzo</h1>
          <div className="px-2 px-md-5">
            <p className="lifeofvenzo-content">
                Discover Life at Venzo through the lens of our team. Our Venzoians take you on an exclusive tour of our vibrant culture, values, and collaborative environment that fuels our daily innovation. 
            </p>
          </div>
        </div>
        <div className="">
          <div className="row video-row text-center pt-3">
            <div className="col video-container">
              <iframe
                width="448"
                height="246"
                src="https://www.youtube.com/embed/V3Ms61nY3wY?si=9S8GXp8MoRl9pOok"
                title="Video 1"
                allowFullScreen
              />
            </div>

            <div className="col video-container">
              <iframe
                width="448"
                height="246"
                src="https://www.youtube.com/embed/UasAjYBtybc?si=dFLi2BxYvSH89kLX"
                title="Video 2"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LifeOfVenzo;
