import React, { useState } from "react";
import JoinWith from "../../../images/join-with-us.png";
import join_us_mobile from "../../../images/join_us_mobile.png";
import useDeviceCategory from "../../../utils/useDimensions";
import ApplyNowJobs from "./ApplyNowJobs";

function CurrentOpenings() {
  const deviceCategory = useDeviceCategory();
  const [isPopUpOpen, setPopUpOpen] = useState(false);
  
  const openPopUp = (e) => {
    e.preventDefault();
    setPopUpOpen(true);
  };

  const closePopUp = () => {
    setPopUpOpen(false);
  };

  if (deviceCategory === "mobile") {
    return (
      <div className="container mt-4">
        {/* <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex flex-column align-items-center justify-content-center mb-3">
            <div className="about-company">Be a part of Venzo</div>
            <h1 style={{ color: "#464646", marginTop: 20 }}>
              Current Openings
            </h1>
          </div>
          <div className="row">
            <div className="card" style={{ width: "334px", height: "175px" }}>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 current-openings-card-title">
                  Web Developer - Wordpress & PHP knowledge
                </h6>
                <p className="card-text current-openings-text">
                  2- 3 Years Experience
                </p>
                <a
                  href="#"
                  className="card-link"
                  style={{ textDecoration: "none" }}
                >
                  View Job &rarr;
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="card" style={{ width: "334px", height: "175px" }}>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 current-openings-card-title">
                  Web Developer - Wordpress & PHP knowledge
                </h6>
                <p className="card-text current-openings-text">
                  2- 3 Years Experience
                </p>
                <a
                  href="#"
                  className="card-link"
                  style={{ textDecoration: "none" }}
                >
                  View Job &rarr;
                </a>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row pb-5">
          <div className="col">
            <div
              className="card"
              style={{
                width: "335px",
                height: "468px",
                backgroundImage: `url(${join_us_mobile})`,
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-openings-card-title pt-4">
                  Join Us
                </h6>
                <p className="card-text current-openings-text">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p>
                <a
                  href=""
                  className="card-link"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-100 mt-lg-0 mt-4 px-5">
      <div className="w-100 mt-lg-0 mt-4">
        {/* <div className="row">
          <div className="col col-lg-4 col-12 pl-3">
            <div className="about-company">Be a part of Venzo</div>
            <h1 style={{ color: "#464646", marginTop: 20 }}>
              Current Openings
            </h1>
          </div>
          <div className="col">
            <div className="card  card-view-openings" style={{ width: "100%", height: "200px" }}>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 current-openings-card-title">
                  Web Developer - Wordpress & PHP knowledge
                </h6>
                <p className="card-text current-openings-text">
                  2- 3 Years Experience
                </p>
                <a
                  href="#"
                  className="card-link"
                  style={{ textDecoration: "none" }}
                >
                  View Job &rarr;
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card  card-view-openings" style={{ width: "343px", height: "200px" }}>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 current-openings-card-title">
                  Web Developer - Wordpress & PHP knowledge
                </h6>
                <p className="card-text current-openings-text">
                  2- 3 Years Experience
                </p>
                <a
                  href="#"
                  className="card-link"
                  style={{ textDecoration: "none" }}
                >
                  View Job &rarr;
                </a>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row pt-5">
          <div className="col">
            <div
              className="card  card-view-openings"
              style={{
                width: "100%",
                height: "300px",
                backgroundImage: `url(${JoinWith})`,
                backgroundSize: "100% 100%",
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-openings-card-title pt-4">
                  Join Us
                </h6>
                <p className="card-text joinus current-openings-text pl-4">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p>
                <a
                  href=""
                  className="card-link pl-4"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrentOpenings;
