import React, { useState } from "react";
import { motion } from "framer-motion";
import useDeviceCategory from "../../../utils/useDimensions";
import { slides } from "../SlidesView";
import vector_button from "../../../images/vector_button.png";
import TransformBusinessForm from "../TransformBusiness/TransformBusinessForm";

const screenWidth = window.innerWidth;
const duration = 0.4;

function Phase1({
  text,
  imgSrc,
  content,
  animation,
  updateInProgress,
  phaseTransitionMobile,
  updatePopupStatus,
}) {
  const deviceCategory = useDeviceCategory();
  const [isPopUpOpen, setPopUpOpen] = useState(false);

  const openPopUp = () => {
    updatePopupStatus(true);
    updateInProgress(true);
    setPopUpOpen(true);
  };

  const closePopUp = () => {
    updatePopupStatus(false);
    setPopUpOpen(false);
    updateInProgress(false);
  };

  if (deviceCategory === "mobile" || deviceCategory === "tablet") {
    let imageWidth = 240;
    let imageHeight = 280;
    let imageY = -150;
    let contentY = 60;
    if (screenWidth < 400) {
      imageWidth = 220;
      imageHeight = 240;
      imageY = -80;
      contentY = 10;
    }

    if (screenWidth > 577) {
      imageWidth = 480;
      imageHeight = 440;
      imageY = -100;
    }

    return (
      <div>
        <motion.div
          className=""
          initial={{ opacity: [0, 0.5, 1], y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration, ease: "easeInOut" }}
        >
          <div className="digital_Trans_title px-3">{text}</div>
          <div
            className="d-flex justify-content-center"
            style={{ color: "#000000" }}
          >
            {content}
          </div>
        </motion.div>
        <div className="d-flex justify-content-center">
          <motion.button
            animate={
              phaseTransitionMobile
                ? {
                    backgroundColor: ["gray", slides[0].buttonColor],
                    borderColor: ["gray", slides[0].bordersColor],
                    // scale: [0.8, 1],
                  }
                : {
                    backgroundColor: slides[0].buttonColor,
                    borderColor: slides[0].bordersColor,
                    scale: 1,
                  }
            }
            transition={{ duration }}
            type="button"
            className="btn btn-primary arrow_tell_problem"
            // style={{
            //   backgroundColor: "#5691FF",
            //   borderColor: slides[0].bordersColor,
            // }}
            onClick={openPopUp}
          >
            <motion.div
              animate={
                phaseTransitionMobile ? { scale: [1.1, 1] } : { scale: 1 }
              }
              transition={{ duration }}
            >
              {slides[0].buttonText}{" "}
              <span style={{ paddingLeft: "17px" }}>
                {" "}
                <img
                  src={vector_button}
                  className="img_View"
                  alt="Button"
                  style={{ display: "initial", width: "8px", height: "17px" }}
                />
              </span>
            </motion.div>
          </motion.button>
        </div>
        <div className="d-flex justify-content-center position-fixed bottom-0 w-100">
          <div
            className="pt-2 d-flex justify-content-center"
            style={{ height: imageHeight, width: imageWidth }}
          >
            <motion.img
              src={imgSrc}
              alt="Slide"
              animate={{
                opacity: [0, 0.5, 1],
                scale: [1],
                y: imageY,
              }}
              transition={{
                duration,
                ease: "easeInOut",
              }}
              initial={{ opacity: 0, scale: 0, y: imageY }}
            />
          </div>
        </div>
        <TransformBusinessForm isOpen={isPopUpOpen} onClose={closePopUp} />
      </div>
    );
  }

  return (
    <div>
      <motion.div
        className=""
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: [0, 0.5, 1], y: 0 }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
      >
        <div className="digital_Trans_title">{text}</div>
        <div
          className="d-flex justify-content-center"
          style={{ color: "#000000" }}
        >
          {content}
        </div>
      </motion.div>
      <div className="d-flex justify-content-center">
        <div className="" style={{ height: "50%", width: "50%" }}>
          <motion.img
            src={imgSrc}
            alt="Slide"
            animate={{ opacity: [0, 0.5, 1], scale: 1 }}
            transition={{
              duration,
              ease: "easeInOut",
            }}
            initial={{ opacity: 0, scale: 1 }}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
      {/* <motion.img
        style={{
          height: "341px",
          width: "384.87px",
          zIndex: 10000,
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          bottom: 0,
          opacity: 0.5,
        }}
        animate={animation}
        variants={variants["letter_pad"]}
        transition={{ duration: 0.5 }}
        alt="letter_pad"
        src={imgSrc}
        className="img_View"
      /> */}
    </div>
  );
}

export default Phase1;
