import React, { useState } from "react";
import { motion } from "framer-motion";
import useDeviceCategory from "../../../utils/useDimensions";
import { slides } from "../SlidesView";
import vector_button from "../../../images/vector_button.png";
import TransformBusinessForm from "../TransformBusiness/TransformBusinessForm";

const screenWidth = window.innerWidth;
const duration = 0.8;

function Phase5({
  text,
  imgSrc,
  content,
  updateInProgress,
  phaseTransitionMobile,
  updatePopupStatus,
}) {
  const deviceCategory = useDeviceCategory();
  const [isPopUpOpen, setPopUpOpen] = useState(false);

  const openPopUp = () => {
    updatePopupStatus(true);
    updateInProgress(true);
    setPopUpOpen(true);
  };

  const closePopUp = () => {
    updatePopupStatus(false);
    setPopUpOpen(false);
    updateInProgress(false);
  };

  if (deviceCategory === "mobile" || deviceCategory === "tablet") {
    let imageWidth = 400;
    let imageHeight = 250;
    let imageY = -180;

    if (screenWidth < 400) {
      imageWidth = 330;
      imageHeight = 200;
      imageY = -100;
    }

    if (screenWidth > 577) {
      imageWidth = 600;
      imageHeight = 350;
      imageY = -160;
    }

    return (
      <div>
        <motion.div
          className=""
          initial={{ opacity: 0.5, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration, ease: "easeInOut" }}
        >
          <div className="digital_Trans_title px-3">{text}</div>
          <div
            className="d-flex justify-content-center"
            style={{ color: "#000000" }}
          >
            {content}
          </div>
        </motion.div>
        <div className="d-flex justify-content-center">
          <motion.button
            animate={
              phaseTransitionMobile
                ? {
                    backgroundColor: ["gray", slides[4].buttonColor],
                    borderColor: ["gray", slides[4].bordersColor],
                    // scale: [0.8, 1],
                  }
                : {
                    backgroundColor: slides[4].buttonColor,
                    borderColor: slides[4].bordersColor,
                    scale: 1,
                  }
            }
            transition={{ duration }}
            type="button"
            className="btn btn-primary arrow_tell_problem"
            // style={{
            //   backgroundColor: "#5691FF",
            //   borderColor: slides[0].bordersColor,
            // }}
            onClick={openPopUp}
          >
            <motion.div
              animate={
                phaseTransitionMobile ? { scale: [1.1, 1] } : { scale: 1 }
              }
              transition={{ duration }}
            >
              {slides[4].buttonText}{" "}
              <span style={{ paddingLeft: "17px" }}>
                {" "}
                <img
                  src={vector_button}
                  className="img_View"
                  alt="Button"
                  style={{ display: "initial", width: "8px", height: "17px" }}
                />
              </span>
            </motion.div>
          </motion.button>
        </div>
        <div className="d-flex justify-content-center position-fixed bottom-0 w-100">
          <div
            className="d-flex justify-content-center"
            style={{ height: imageHeight, width: imageWidth }}
          >
            <motion.img
              src={imgSrc}
              style={{ width: "100%", height: "100%" }}
              alt="Slide"
              animate={{ opacity: 1, scale: [1.1, 1], y: imageY }}
              transition={{
                duration: 0.3,
                ease: "easeOut",
              }}
              initial={{ opacity: 0.5, scale: 1.1 }}
            />
          </div>
        </div>
        <TransformBusinessForm isOpen={isPopUpOpen} onClose={closePopUp} />
      </div>
    );
  }

  return (
    <div className="">
      <motion.div
        className=""
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="digital_Trans_title">{text}</div>
        <div
          className="d-flex justify-content-center"
          style={{ color: "#000000" }}
        >
          {content}
        </div>
      </motion.div>
      <div className="d-flex justify-content-center">
        <div className="" style={{ height: "50%", width: "50%" }}>
          <motion.img
            style={{ width: "100%", height: "100%" }}
            src={imgSrc}
            alt="Slide"
            animate={{
              opacity: 1,
              scale: [1.1, 1],
              width: "100%",
              height: "100%",
            }}
            transition={{
              duration,
              ease: "easeInOut",
            }}
            initial={{ opacity: 0.5, scale: 1.1 }}
          />
        </div>
      </div>
    </div>
  );
}

export default Phase5;
