import React, { useState, useEffect, useRef } from "react";
import vector_button from "../../images/vector_button.png";
import dotted_lines_left from "../../images/dotted_lines_tell_left.png";
import dotted_lines_right from "../../images/dotted_lines_tell_right.png";
import { slides } from "./SlidesView";
import { useLocation, useNavigate } from "react-router-dom";
import _, { transform } from "lodash";
import { motion } from "framer-motion";
import useDeviceCategory from "../../utils/useDimensions";
import TransformBusinessForm from "./TransformBusiness/TransformBusinessForm";

const minSwipeDistance = 20;
const duration = 0.8;

function Slide3(props) {
  const animation = `${props.animation}${props.direction}`;

  const [currentBox, setCurrentBox] = useState(props.currentPhase);

  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  const [touchStartY, setTouchStartY] = useState(null);
  const [touchEndY, setTouchEndY] = useState(null);

  const [phaseTransitionMobile, setPhaseTransitionMobile] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const { isFormOpen, setIsFormOpen } = props;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => setInProgress(false), 1000);
  }, []);

  useEffect(() => {
    if (props.path === "transform" && props.currentPhase === 5) {
      setInProgress(true);
      setCurrentBox(props.currentPhase);
      setTimeout(() => setInProgress(false), 2000);
    }
  }, [props.currentPhase, props.path, props.phase]);

  const [isPopUpOpen, setPopUpOpen] = useState(false);

  const openPopUp = () => {
    setPopUpOpen(true);
    setIsFormOpen(true);
  };

  const closePopUp = () => {
    setPopUpOpen(false);
    setInProgress(false);
    setIsFormOpen(false);
  };

  const updateInProgress = (value) => {
    setInProgress(value);
  };

  const updatePopupStatus = (value) => {
    setPopUpOpen(value);
  };

  const deviceCategory = useDeviceCategory();

  useEffect(() => {
    if (currentBox === 0) {
      setInProgress(true);
      setTimeout(() => setInProgress(false), 1500);
    }
  }, [currentBox]);

  const handleWheel = (e) => {
    if (inProgress || isPopUpOpen) {
      return;
    }

    setInProgress(true);

    const delta = e.deltaY || e.detail || e.wheelDelta;
    const nextBox = currentBox + (delta < 0 ? -1 : 1);

    if (delta > 0) {
      setTimeout(() => props.setPhase(nextBox), 300);
    } else if (delta < 0 && currentBox <= 1) {
      setTimeout(() => props.setPhase(currentBox), 300);
    }
    if (nextBox >= 0 && nextBox < slides.length) {
      setTimeout(() => setCurrentBox(nextBox), 300);
    } else if (nextBox === slides.length) {
      navigate("/digital-transform");
    }
    setTimeout(() => setPhaseTransitionMobile(true), 300);
    setTimeout(() => setPhaseTransitionMobile(false), 200);
    setTimeout(() => setInProgress(false), 1500);
  };

  // const handleClick = (e) => {
  //   if (inProgress) {
  //     return;
  //   }
  //   setInProgress(true);
  //   const nextBox = (currentBox + 1) % slides.length;

  //   setTimeout(() => props.setPhase(nextBox), 500);
  //   if (currentBox === slides.length - 1) {
  //     //  setShowNewComponent(true);
  //     navigate("/pages/digital-transform");
  //   } else {
  //     setCurrentBox(nextBox);
  //   }
  //   setTimeout(() => setInProgress(false), 1500);
  // };

  // useEffect(() => {
  //   if (deviceCategory === "laptop" || deviceCategory === "desktop") {
  //     // document.body.addEventListener("click", handleClick);
  //   }

  //   return () => {
  //     // document.body.removeEventListener("click", handleClick);
  //   };
  // }, [currentBox]);

  const debounceSlideChange = _.throttle(handleWheel, 2500);

  const onTouchStart = (e) => {
    if (isPopUpOpen) {
      return;
    }
    setTouchEndY(null); // otherwise the swipe is fired even with usual touch events
    setTouchEndX(null);
    setTouchStartY(e.targetTouches[0].clientY);
    setTouchStartX(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEndY(e.targetTouches[0].clientY);
    setTouchEndX(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (inProgress || isPopUpOpen) {
      return;
    }

    if (!touchStartY || !touchEndY || !touchStartX || !touchEndX) return;

    setInProgress(true);

    const distanceY = touchStartY - touchEndY;
    const distanceX = touchStartX - touchEndX;
    const isSwipeDown = distanceY > minSwipeDistance;
    const isSwipeUp = distanceY < -minSwipeDistance;
    const isSwipeRight = distanceX > minSwipeDistance;
    const isSwipeLeft = distanceX < -minSwipeDistance;

    if (isSwipeDown || isSwipeLeft) {
      handleWheel({ deltaY: 1 });
    } else if (isSwipeUp || isSwipeRight) {
      handleWheel({ deltaY: -1 });
    }

    setTimeout(() => setInProgress(false), 1500);
  };

  if (deviceCategory === "mobile" || deviceCategory === "tablet") {
    return (
      <div
        style={{ height: "100%" }}
        className="digital-trans-venzos overflow-hidden"
        onWheel={debounceSlideChange}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <div className="pt-5">
          {React.createElement(slides[currentBox].component, {
            ...slides[currentBox],
            animation,
            updateInProgress,
            phaseTransitionMobile,
            updatePopupStatus,
          })}
        </div>
        <div className="position-fixed bottom-0 d-flex justify-content-center flex-column align-items-center w-100 pb-4">
          <p className="digital_journey_text">Digital Transformation Journey</p>
          <div
            className="row justify-content-start"
            style={{ paddingLeft: "13px", zIndex: 3000, bottom: 29 }}
          >
            {slides.map((slide, index) => (
              <motion.div
                key={index}
                className="rectangular-box"
                initial={{ backgroundColor: "gray" }}
                animate={
                  currentBox === index
                    ? {
                        scale: [1.3, 1],
                        backgroundColor: ["gray", slide.color],
                      }
                    : {
                        scale: 1,
                        backgroundColor: "gray",
                      }
                }
                transition={{ duration: 1.2, ease: "easeInOut" }}
                style={{
                  // backgroundColor:
                  //   currentBox === index ? slide.color : "gray",
                  marginRight: "4px",
                }}
              ></motion.div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{ height: "100%" }}
        className="digital-trans-venzo"
        onWheel={debounceSlideChange}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6 }}
          style={{
            top: 0,
            left: 0,
            height: "100vh",
            width: "40%",
            backgroundImage: `url(${dotted_lines_left})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="position-fixed"
        ></motion.div>
        <div className="pt-2">
          {React.createElement(slides[currentBox].component, {
            ...slides[currentBox],
            animation,
            phaseTransitionMobile,
          })}
        </div>
        {/* <div className="row px-5 pb-5">

        {React.createElement(slides[currentBox].component, slides[currentBox])}
      </div> */}
        <div
          className="px-5 pb-5 d-flex justify-content-between position-fixed bottom-0 w-100"
          style={{ zIndex: 100 }}
        >
          <div className="">
            <p className="digital_journey_text text-start">
              Digital Transformation Journey
            </p>
            <div
              className="row justify-content-start"
              style={{ paddingLeft: "13px", zIndex: 3000, bottom: 29 }}
            >
              {slides.map((slide, index) => (
                <motion.div
                  key={index}
                  className="rectangular-box"
                  initial={{ backgroundColor: "gray" }}
                  animate={
                    currentBox === index
                      ? {
                          scale: [1.3, 1],
                          backgroundColor: ["gray", slide.color],
                        }
                      : {
                          scale: 1,
                          backgroundColor: "gray",
                        }
                  }
                  transition={{ duration: 1, ease: "easeInOut" }}
                  style={{
                    // backgroundColor:
                    //   currentBox === index ? slide.color : "gray",
                    marginRight: "6px",
                  }}
                ></motion.div>
              ))}
            </div>
          </div>

          <div className="">
            <motion.button
              animate={
                phaseTransitionMobile
                  ? {
                      backgroundColor: ["gray", slides[currentBox].buttonColor],
                      borderColor: ["gray", slides[currentBox].bordersColor],
                      // scale: [0.8, 1],
                    }
                  : {
                      backgroundColor: slides[currentBox].buttonColor,
                      borderColor: slides[currentBox].bordersColor,
                      scale: 1,
                    }
              }
              transition={{ duration }}
              type="submit"
              className="btn btn-primary arrow_tell_problem"
              // style={{
              //   backgroundColor: slides[currentBox].buttonColor,
              //   borderColor: slides[currentBox].bordersColor,
              // }}
              onClick={openPopUp}
            >
              <motion.div
                animate={
                  phaseTransitionMobile ? { scale: [1.2, 1] } : { scale: 1 }
                }
                transition={{ duration }}
              >
                {slides[currentBox].buttonText}{" "}
                <span style={{ paddingLeft: "17px" }}>
                  {" "}
                  <img
                    src={vector_button}
                    className="img_View"
                    alt="Button"
                    style={{ display: "initial", width: "8px", height: "17px" }}
                  />
                </span>
              </motion.div>
            </motion.button>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6 }}
          style={{
            top: 0,
            right: 0,
            height: "100vh",
            width: "30%",
            backgroundImage: `url(${dotted_lines_right})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="position-fixed"
        ></motion.div>
      </div>
      <TransformBusinessForm isOpen={isPopUpOpen} onClose={closePopUp} />
    </div>
  );
}

export default Slide3;
