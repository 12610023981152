import React from "react";
import WriteUsForm from "../components/Contacts/WriteUsForm";
import AddressDetails from "../components/Contacts/AddressDetails";
import Footer from "../components/DigitalTrans/TransformBusiness/Footer";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

function Contact() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="overflow-auto"
    >
      <Helmet>
        <title>Contact Venzo Technologies | Software Development Company</title>
        <meta
          name="description"
          content="We have the expertise and experience to drive your success. Contact us today for a free consultation and lets start building your future together."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section
        className="pt-md-5 p-md-5 pt-4 p-4 d-md-block d-flex justify-content-center"
        // style={{backgroundImage: `url(${FirstCompany_bg})`}}
      >
        <WriteUsForm />
      </section>
      <section
        className="pt-md-5 p-md-5 pt-4 p-4 d-md-block d-flex justify-content-center"
        // style={{backgroundImage: `url(${FirstCompany_bg})`}}
      >
        <AddressDetails />
      </section>
      <section
        className="pt-5 p-5 footerViews"
        style={{ backgroundColor: "#111827" }}
      >
        <Footer />
      </section>
    </motion.div>
  );
}

export default Contact;
