import React, { useState } from "react";
import { motion } from "framer-motion";
import useDeviceCategory from "../../../utils/useDimensions";
import { slides } from "../SlidesView";
import vector_button from "../../../images/vector_button.png";
import TransformBusinessForm from "../TransformBusiness/TransformBusinessForm";

const screenWidth = window.innerWidth;
const duration = 0.8;

function Phase2({
  text,
  imgSrc,
  content,
  updateInProgress,
  phaseTransitionMobile,
  updatePopupStatus,
}) {
  console.log(phaseTransitionMobile);
  const deviceCategory = useDeviceCategory();
  const [isPopUpOpen, setPopUpOpen] = useState(false);

  const openPopUp = () => {
    updatePopupStatus(true);
    updateInProgress(true);
    setPopUpOpen(true);
  };

  const closePopUp = () => {
    updatePopupStatus(false);
    setPopUpOpen(false);
    updateInProgress(false);
  };

  if (deviceCategory === "mobile" || deviceCategory === "tablet") {
    let imageWidth = 240;
    let imageHeight = 280;
    let imageY = -150;
    if (screenWidth < 400) {
      imageWidth = 220;
      imageHeight = 264;
      imageY = -80;
    }

    if (screenWidth > 577) {
      imageWidth = 500;
      imageHeight = 700;
      imageY = -120;
    }

    return (
      <div>
        <motion.div
          className=""
          initial={{ opacity: 0.5, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration, ease: "easeInOut" }}
        >
          <div className="digital_Trans_title px-3">{text}</div>
          <div className="px-3 pb-2" style={{ color: "#000000" }}>
            <div
              style={{ color: "#000000" }}
              className="container text-center py-3"
            >
              <div className="row">
                <div
                  className="discovery-items col-6"
                  style={{ paddingLeft: 0 }}
                >
                  Possible <br /> alternatives
                </div>
                <div
                  className="discovery-items col-6"
                  style={{ borderRight: 0 }}
                >
                  Cost-benefit <br /> alternatives
                </div>
              </div>
              <div className="row">
                <div className="discovery-items border-bottom-0 col-6">
                  High Level <br /> Solution Design
                </div>
                <div
                  className="discovery-items col-6 border-bottom-0"
                  style={{ borderRight: "none" }}
                >
                  Requirement <br /> Document & Finalize
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <div className="d-flex justify-content-center">
          <motion.button
            animate={
              phaseTransitionMobile
                ? {
                    backgroundColor: ["gray", slides[1].buttonColor],
                    borderColor: ["gray", slides[1].bordersColor],
                    // scale: [0.8, 1],
                  }
                : {
                    backgroundColor: slides[1].buttonColor,
                    borderColor: slides[1].bordersColor,
                    scale: 1,
                  }
            }
            transition={{ duration }}
            type="button"
            className="btn btn-primary arrow_tell_problem"
            // style={{
            //   backgroundColor: "#5691FF",
            //   borderColor: slides[0].bordersColor,
            // }}
            onClick={openPopUp}
          >
            <motion.div
              animate={
                phaseTransitionMobile ? { scale: [1.1, 1] } : { scale: 1 }
              }
              transition={{ duration }}
            >
              {slides[1].buttonText}{" "}
              <span style={{ paddingLeft: "17px" }}>
                {" "}
                <img
                  src={vector_button}
                  className="img_View"
                  alt="Button"
                  style={{ display: "initial", width: "8px", height: "17px" }}
                />
              </span>
            </motion.div>
          </motion.button>
        </div>
        <div className="d-flex justify-content-center position-fixed bottom-0 w-100">
          <div
            className="pt-2 d-flex justify-content-center"
            // style={{ height: imageHeight, width: imageWidth }}
          >
            <motion.img
              style={{ width: "100%", height: "100%" }}
              src={imgSrc}
              alt="Slide"
              animate={{
                opacity: 1,
                scale: [1.1, 1],
                y: imageY,
                width: "100%",
                height: "100%",
              }}
              transition={{
                duration,
                ease: "easeInOut",
              }}
              initial={{ opacity: 0.5, scale: 1.1 }}
            />
          </div>
        </div>
        <TransformBusinessForm isOpen={isPopUpOpen} onClose={closePopUp} />
      </div>
    );
  }

  return (
    <div>
      <motion.div
        className=""
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration }}
      >
        <div className="digital_Trans_title">{text}</div>
        <div
          style={{ color: "#000000" }}
          className="d-flex justify-content-center"
        >
          <div
            className={`discovery-items ${
              deviceCategory === "desktop" ? "" : "discovery-laptop"
            }`}
          >
            Potential <br /> Alternatives
          </div>
          <div
            className={`discovery-items ${
              deviceCategory === "desktop" ? "" : "discovery-laptop"
            }`}
          >
            Cost-benefit <br /> Analysis
          </div>
          <div
            className={`discovery-items ${
              deviceCategory === "desktop" ? "" : "discovery-laptop"
            }`}
          >
            High Level <br /> Solution Design
          </div>
          {/* <div
            className={`discovery-items ${
              deviceCategory === "desktop" ? "" : "discovery-laptop"
            }`}
          >
            Requirement <br /> Document & Finalize
          </div> */}
        </div>
      </motion.div>
      <div className="d-flex justify-content-center">
        <div className="pt-5" style={{ height: "50%", width: "50%" }}>
          <motion.img
            style={{ width: "100%", height: "100%" }}
            src={imgSrc}
            alt="Slide"
            animate={{
              opacity: 1,
              scale: [1.1, 1],
              width: "100%",
              height: "100%",
            }}
            transition={{
              duration,
              ease: "easeInOut",
            }}
            initial={{ opacity: 0.5, scale: 1.1 }}
          />
          {/* <img
            src={imgSrc}
            className="img_View"
            alt="Slide"
            style={{ width: "100%", height: "100%" }}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default Phase2;
