import React from "react";
import logo1 from "../../../images/bridgeStone_log.png";
import tringapss from "../../../images/tringapps.png";
import mangopoint from "../../../images/mangopoint.png";
import agshealth from "../../../images/agshealth.png";
import sirius from "../../../images/sirius.webp";
import vooki from "../../../images/vooki.png";
import rasaii from "../../../images/rasaii.webp";
import ebms from "../../../images/ebms.jpeg";
import cspsc from "../../../images/cspsc.webp";

function ClientTrustViews() {
  return (
    <div>
      <div className="clientViewUs d-flex justify-content-center align-items-center">
        <div className="container">
          {/* <div className="row">
            <div className="col-12 text-center">
              <div className="client_images">
                <img src={logo1} className="client_img_views" alt="1" />
                <img src={tringapss} className="client_img_views" alt="2" />
                <img src={mangopoint} className="client_img_views" alt="3" />
                <img src={agshealth} className="client_img_views" alt="5" />
                <img src={sirius} className="client_img_views" alt="4" />
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col text-center">
              <div className="clienttrustView">
                <p className="clienttrustLayout pt-3">
                  Client who always trust us
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="text-start">
              <div className="">
                <img src={rasaii} className="client_img_views" alt="11" />
              </div>
            </div>
            <div className="text-md-start text-end">
              <img
                src={ebms}
                className="client_img_views"
                alt="10"
                style={{ height: 60, width: 90 }}
              />
            </div>
            <div className="text-end d-none d-md-block">
              <img
                src={agshealth}
                className="client_img_views"
                alt="10"
                style={{ height: 60, width: 90 }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="text-start">
              <div className="">
                <img src={tringapss} className="client_img_views" alt="11" />
              </div>
            </div>
            <div className="text-md-start text-end">
              <img
                src={cspsc}
                className="client_img_views"
                alt="10"
                style={{ height: 60, width: 90 }}
              />
            </div>
            <div className="text-end d-none d-md-block">
              <img
                src={mangopoint}
                className="client_img_views"
                alt="10"
                style={{ height: 60, width: 90 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientTrustViews;
