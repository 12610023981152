import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
//import CaseStudiesCards from './CaseStudiesCards';
//import './CardSlider.css'; // Import your custom CSS file
import blog1 from "../../../images/fromBlogs_1.png";
import blog2 from "../../../images/FromBlogs_2.png";
import blog3 from "../../../images/FromBlogs_3.png";
import FormBlogsViews from "./FormBlogsViews";

SwiperCore.use([Navigation, Pagination]);

function FromBlogs() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {!isMobile ? (
        <div className="card-slider-container">
          <div className="text-center pb-2">
            <h3 style={{ color: "#ffffff" }}>From Blogs</h3>
            <p className="blogCardContent">
              Learn how to grow your business with our expert advice.
            </p>
          </div>

          <Swiper
            //  navigation
            pagination={{ clickable: true }}
            slidesPerView={3}
            spaceBetween={10}
          >
            <SwiperSlide>
              <div
                className="card card_backGround"
                style={{ borderColor: "#111827" }}
              >
                <img className="card-img-top" src={blog1} alt="Card image" />
                <div className="card-body text-start">
                  <h6 className="card-title blogCardContent">
                    Product Development
                  </h6>
                  <p
                    className="card-text blogCardContent"
                    style={{ color: "#ffffff" }}
                  >
                    How To Choose Between React Native And Ionic For Your App
                  </p>
                  <p className="card-text blogCardContent">Sept 12, 2023</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="card card_backGround"
                style={{ borderColor: "#111827" }}
              >
                <img
                  className="card-img-top img_View"
                  src={blog2}
                  alt="Card image"
                />
                <div className="card-body text-start">
                  <h6 className="card-title blogCardContent">
                    Product Development
                  </h6>
                  <p
                    className="card-text blogCardContent"
                    style={{ color: "#ffffff" }}
                  >
                    Top-15 Mobile App Ideas that Will Drive Your Start-Up Ahead
                  </p>
                  <p className="card-text blogCardContent">Sept 12, 2023</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="card card_backGround"
                style={{ borderColor: "#111827" }}
              >
                <img
                  className="card-img-top img_View"
                  src={blog3}
                  alt="Card image"
                />
                <div className="card-body text-start">
                  <h6 className="card-title blogCardContent">
                    Product Development
                  </h6>
                  <p
                    className="card-text blogCardContent"
                    style={{ color: "#ffffff" }}
                  >
                    Significant Benefits and Why Your Company Requires IT Staff
                    Augmentation Services
                  </p>
                  <p className="card-text blogCardContent">Sept 12, 2023</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="card card_backGround"
                style={{ borderColor: "#111827" }}
              >
                <img
                  className="card-img-top img_View"
                  src={blog2}
                  alt="Card image"
                />
                <div className="card-body text-start">
                  <h6 className="card-title blogCardContent">
                    Product Development
                  </h6>
                  <p
                    className="card-text blogCardContent"
                    style={{ color: "#ffffff" }}
                  >
                    Significant Benefits and Why Your Company Requires IT Staff
                    Augmentation Services
                  </p>
                  <p className="card-text blogCardContent">Sept 12, 2023</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <p className="text-center pt-3">
            <a href="#" style={{ textDecoration: "none" }}>
              View all &gt;&gt;&gt;
            </a>
          </p>
        </div>
      ) : (
        <FormBlogsViews />
      )}
    </div>
  );
}

export default FromBlogs;
