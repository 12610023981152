import React, { useState } from "react";
import pfmImage from "../../../images/pfm-case-study-image.svg";
import pfmFigma from "../../../images/pfm-figma.svg";
import pfmFirebase from "../../../images/pfm-firebase.svg";
import pfmFlutter from "../../../images/pfm-flutter.svg";
import Footer from "../TransformBusiness/Footer";
import { CommonCaseStuies } from "./CommonCaseStudies";
import { useParams } from "react-router-dom";
import awsLogo from "../../../images/aws_logo.svg";
import sqlLogo from "../../../images/sql_image.svg";
import reactjsLogo from "../../../images/reactjs_logo.svg";
import phpLogo from "../../../images/php_logo.svg";
import flaskLogo from "../../../images/flask_logo.svg";
import supabaseLogo from "../../../images/supabase.svg";
import djangoLogo from "../../../images/django.svg";
import postgreSqlLogo from "../../../images/postgreSQL.svg";
import nodeLogo from "../../../images/node.svg";
import reactNativeLogo from "../../../images/reactNative.svg";
import TransformBusinessForm from "../TransformBusiness/TransformBusinessForm";
import caseStudyImg from "../../../images/iot_image_lap.svg";
import CarvaganImage from "../../../images/carvagan_full_images.png";
import ERPImage from "../../../images/ERP_case_studies.png";
import FitaImage from "../../../images/FITA_case_studies.png";
import DigitalOcean from "../../../images/digital_ocean_logo.png";
import { Helmet } from "react-helmet";

function CaseStudiesSection() {
  const [isPopUpOpen, setPopUpOpen] = useState(false);

  const caseStudyParams = useParams();

  const caseStudiesTitle = caseStudyParams.id;

  //console.log("Hi New One", caseStudiesTitle);

  const selectedContent = CommonCaseStuies[caseStudiesTitle];

  if (!selectedContent) {
    return null;
  }

  const {
    personalHeading,
    personalContent,
    problemStatement,
    problemStatementContent,
    solutionStatement,
    solutionStatementContent,
  } = selectedContent;

  const openPopUp = () => {
    setPopUpOpen(true);
  };

  const closePopUp = () => {
    setPopUpOpen(false);
  };

  return (
    <div>
      <Helmet>
        <title>
          {caseStudiesTitle === "Enhanced-user-engagement-to-boost-revenue"
            ? "Enhanced User Engagement Strategies for Optimal Revenue Growth"
            : caseStudiesTitle ===
              "healthcare-iot-for-smarter-device-monitoring"
            ? "Healthcare: IoT Solutions for Smarter Device Monitoring"
            : caseStudiesTitle ===
              "streamlining-it-training-and-Placements-a-success-story"
            ? "Streamlining IT Training and Placements: A Success Story"
            : "Revolutionizing Operations for a Leading Cookware Manufacturer"}
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={
            caseStudiesTitle === "Enhanced-user-engagement-to-boost-revenue"
              ? "Uncover how our client, a pivotal player in the automotive industry, transformed user engagement. Revolutionizing Automotive Connectivity, read more about it."
              : caseStudiesTitle ===
                "healthcare-iot-for-smarter-device-monitoring"
              ? "Discover how our prominent healthcare client, with over 12 years of expertise, leverages cutting-edge IoT solutions for smarter device monitoring."
              : caseStudiesTitle ===
                "streamlining-it-training-and-Placements-a-success-story"
              ? "Transforming Futures: Streamlining IT Training and Placements - A Success Story in Problem-Solving, Innovation, and Results"
              : "Explore the success journey of a prominent stainless steel cookware manufacturer with a 50 crore annual turnover. Read More about case study."
          }
        />
      </Helmet>
      <section
        className="d-flex align-items-center"
        style={{ backgroundColor: "#FFEEEB" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {/* <div className="pt-5"><span className="case-study-tag-view">Case Study</span> </div> */}
              <h1 className="pfm-cast-studies-heading">{personalHeading}</h1>
              <p className="pfm-cast-studies-content">{personalContent}</p>
            </div>
            <div className="col-lg-4 d-flex justify-content-center align-items-center">
              {caseStudiesTitle ===
              "Enhanced-user-engagement-to-boost-revenue" ? (
                <img
                  className="pmf-studies-image-views"
                  src={CarvaganImage}
                  alt="PFM image"
                  style={{ height: 300 }}
                />
              ) : caseStudiesTitle ===
                "bespoke-erp-solution-for-leading-cookware-manufacturer" ? (
                <img
                  className="pmf-studies-image-views"
                  src={ERPImage}
                  alt="ERP image"
                  style={{ height: 300 }}
                />
              ) : caseStudiesTitle ===
                "streamlining-it-training-and-Placements-a-success-story" ? (
                <img
                  className="pmf-studies-image-views"
                  src={FitaImage}
                  alt="PFM image"
                  style={{ height: 300 }}
                />
              ) : (
                <img
                  className="pmf-studies-image-views"
                  src={caseStudyImg}
                  alt="PFM image"
                  style={{ height: 300 }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="pt-md-5 p-md-5 pt-5 p-4 d-md-block d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row pt-5">
            <h1
              className="pfm-problem-statement-heading"
              style={{ borderBottomColor: "#FF7B65" }}
            >
              {problemStatement}
            </h1>
            <div className="pt-2">
              <p className="pfm-problem-statement-content">
                {problemStatementContent}
              </p>
            </div>
          </div>
          <div className="row pt-5">
            <h1
              className="pfm-problem-statement-heading"
              style={{ borderBottomColor: "#63BA54" }}
            >
              {solutionStatement}
            </h1>
            <div className="pt-2">
              <p className="pfm-problem-statement-content">
                {solutionStatementContent}
              </p>
              {caseStudiesTitle ===
              "Enhanced-user-engagement-to-boost-revenue" ? (
                <div>
                  <p className="pfm-problem-statement-content">
                    Furthermore, rigorous security standards and adopting the
                    latest DevOps practices ensured a secure user environment
                    and drove down hosting expenses. The solution resulted in
                    increased user engagement, a broader service offering, and
                    overall growth in the client's market presence and revenue.
                  </p>
                </div>
              ) : caseStudiesTitle ===
                "bespoke-erp-solution-for-leading-cookware-manufacturer" ? (
                <div>
                  <ul>
                    <li className="pfm-problem-statement-content">
                      <span style={{ fontWeight: 600 }}>
                        Requirements Drafting with Hand-Drawn Mockups:{" "}
                      </span>{" "}
                      <span>
                        We began by outlining the client's key requirements
                        while providing hand-drawn mockups to visualize the
                        proposed system's functionalities.{" "}
                      </span>
                    </li>
                    <li className="pfm-problem-statement-content">
                      <span style={{ fontWeight: 600 }}>
                        Interactive Mockup Walkthrough:
                      </span>{" "}
                      <span>
                        Collaboratively, we walked through the entire set of
                        requirements using interactive mockups, enabling the
                        client to visualize the system's workflow.{" "}
                      </span>
                    </li>
                    <li className="pfm-problem-statement-content">
                      <span style={{ fontWeight: 600 }}>
                        Refinement of Requirements:
                      </span>{" "}
                      <span>
                        Based on client feedback, we refined and adjusted the
                        requirements to ensure they aligned with their unique
                        workflow. We have deferred certain requirements, such as
                        Payroll module, to be addressed in future phases.{" "}
                      </span>
                    </li>
                    <li className="pfm-problem-statement-content">
                      <span style={{ fontWeight: 600 }}>
                        Project Timeline Management:
                      </span>{" "}
                      <span>
                        Recognizing the client's urgent need for a launch, we
                        selected Django as the framework, and Postgresql as the
                        database, and hosted the solution on the cloud. Django's
                        rapid development capabilities were a strategic choice
                        given the tight timeline and limited internal staff
                        resources.{" "}
                      </span>
                    </li>
                  </ul>
                </div>
              ) : caseStudiesTitle ===
                "healthcare-iot-for-smarter-device-monitoring" ? (
                <div>
                  <ul>
                    <li className="pfm-problem-statement-content">
                      <span style={{ fontWeight: 600 }}>
                        Real-time Data Monitoring:
                      </span>{" "}
                      <span>
                        Continuous display of live sensor data for temperature,
                        humidity, and other parameters.
                      </span>
                    </li>
                    <li className="pfm-problem-statement-content">
                      <span style={{ fontWeight: 600 }}>
                        Subscription-Based Access:
                      </span>{" "}
                      <span>
                        Facilities with the client's IoT devices can subscribe
                        to monitor device metrics online.
                      </span>
                    </li>
                    <li className="pfm-problem-statement-content">
                      <span style={{ fontWeight: 600 }}>
                        Data Analytics and Reporting:
                      </span>{" "}
                      <span>
                        Provision of detailed charts summarizing data over
                        various periods and comprehensive reports on historical
                        sensor readings.
                      </span>
                    </li>
                    <li className="pfm-problem-statement-content">
                      <span style={{ fontWeight: 600 }}>
                        Proactive Alert System:
                      </span>{" "}
                      <span>
                        Customizable alerts via email, SMS, and push
                        notifications for any anomalies detected by the sensors.
                      </span>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>

          {caseStudiesTitle === "Enhanced-user-engagement-to-boost-revenue" ? (
            <div className="row pt-5">
              <div className="col-lg-6">
                <div className="pfm-technology-box w-100  mt-3">
                  <div>
                    <h3 className="pfm-technology-box-heading">
                      Technology we used
                    </h3>
                  </div>
                  <div className="d-flex align-items-center justify-content-around pt-3 pfm-studies-views">
                    <img
                      className="pmf-studies-image"
                      src={reactjsLogo}
                      alt="PFM image"
                    />
                    <img
                      className="pmf-studies-image"
                      src={flaskLogo}
                      alt="PFM image"
                    />
                    <img
                      className="pmf-studies-image"
                      src={pfmFlutter}
                      alt="PFM image"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="pfm-technology-box  w-100 mt-3">
                  <div>
                    <h3 className="pfm-technology-box-heading">
                      Results & Impact
                    </h3>
                  </div>
                  <div className="d-flex align-items-center pt-3 pfm-studies-views">
                    {/* <div>
                    <p className="pfm-result-text">650M+</p>
                    <p className="pfm-result-content">Active Users</p>
                  </div>
                  <div>
                    <p className="pfm-result-text">3X</p>
                    <p className="pfm-result-content">Car Trading Revenue</p>
                  </div>
                  <div>
                    <p className="pfm-result-text">15+</p>
                    <p className="pfm-result-content">Leading Car Service Centers Enrolled</p>
                  </div> */}
                    <div>
                      <ul className="case-studies-list-image">
                        <li className="case-studies-list-content">
                          15 Leading Car Service Centers Enrolled{" "}
                        </li>
                        <li className="case-studies-list-content">
                          650+ Active Users.
                        </li>
                        <li className="case-studies-list-content">
                          3x Car Trading Revenue
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : caseStudiesTitle ===
            "streamlining-it-training-and-Placements-a-success-story" ? (
            <div className="row pt-5">
              <div className="col-lg-6">
                <div className="pfm-technology-box-streaming w-100  mt-3">
                  <div>
                    <h3 className="pfm-technology-box-heading">
                      Technology we used
                    </h3>
                  </div>
                  <div className="d-flex align-items-center justify-content-around pt-4 pb-5 pfm-studies-views case-studies-iot-mobile">
                    <img
                      className="pmf-studies-image"
                      src={nodeLogo}
                      alt="PFM image"
                    />
                    <img
                      className="pmf-studies-image"
                      src={reactNativeLogo}
                      alt="PFM image"
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-around pt-4 pb-4 pfm-studies-views case-studies-iot-mobile">
                    <img
                      className="pmf-studies-image"
                      src={reactjsLogo}
                      alt="PFM image"
                    />
                    <img
                      className="pmf-studies-image"
                      src={supabaseLogo}
                      alt="PFM image"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="pfm-technology-box-streaming  w-100 mt-3">
                  <div>
                    <h3 className="pfm-technology-box-heading">
                      Results & Impact
                    </h3>
                  </div>
                  <div className="pt-3 pfm-studies-views">
                    <div className="d-flex align-items-center case-studies-views-device">
                      <div>
                        <ul className="case-studies-list-image">
                          <li className="case-studies-list-content">
                            We completed User Acceptance Testing (UAT), and the
                            client expressed extreme satisfaction with the
                            project delivery.
                          </li>
                          <li className="case-studies-list-content">
                            We successfully implemented approximately 15
                            distinct business modules within the committed
                            timeframe.
                          </li>
                          <li className="case-studies-list-content">
                            We achieved go-live within just three weeks of
                            testing.
                          </li>
                          <li className="case-studies-list-content">
                            The client initiated discussions for Phase 2,
                            focusing on analytical reports and dashboards.
                          </li>
                          {/* <li className="case-studies-list-content">6-7 New Users per Org</li>
                    <li className="case-studies-list-content">200 Users per Minute</li> */}
                        </ul>
                      </div>
                      {/* <div>
                    <p className="pfm-result-text-pfm">500+</p>
                    <p className="pfm-result-content-pfm">Monitoring in 12 Organization</p>
                  </div>
                  <div>
                    <p className="pfm-result-text-pfm">6 - 7</p>
                    <p className="pfm-result-content-pfm">New Users per Organization</p>
                  </div>
                  <div>
                    <p className="pfm-result-text-pfm">10,000</p>
                    <p className="pfm-result-content-pfm">Daily Downloads</p>
                  </div> */}
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-around case-studies-views-device">
                 <div>
                    <p className="pfm-result-text-pfm">90%</p>
                    <p className="pfm-result-content-pfm">Premium Subscription Users</p>
                  </div>
                 
                 
                  <div>
                    <p className="pfm-result-text-pfm">10 - 15</p>
                    <p className="pfm-result-content-pfm">&nbsp;Daily Reports Per Devices</p>
                  </div>
                  <div>
                    <p className="pfm-result-text-pfm">200</p>
                    <p className="pfm-result-content-pfm">Users Per Minutes</p>
                  </div>
                  
                 </div> */}
                  </div>
                </div>
              </div>
            </div>
          ) : caseStudiesTitle ===
            "bespoke-erp-solution-for-leading-cookware-manufacturer" ? (
            <div className="row pt-5">
              <div className="col-lg-6">
                <div className="pfm-technology-box-streaming w-100  mt-3">
                  <div>
                    <h3 className="pfm-technology-box-heading">
                      Technology we used
                    </h3>
                  </div>
                  <div className="d-flex align-items-center justify-content-around pt-3 pfm-studies-views">
                    <img
                      className="pmf-studies-image"
                      src={postgreSqlLogo}
                      alt="PFM image"
                    />
                    <img
                      className="pmf-studies-image"
                      src={djangoLogo}
                      alt="PFM image"
                    />
                    <img
                      className="pmf-studies-image"
                      src={DigitalOcean}
                      alt="PFM image"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="pfm-technology-box-streaming  w-100 mt-3">
                  <div>
                    <h3 className="pfm-technology-box-heading">
                      Results & Impact
                    </h3>
                  </div>
                  <div className="d-flex align-items-center pt-3 pfm-studies-views">
                    {/* <div>
                    <p className="pfm-result-text">650M+</p>
                    <p className="pfm-result-content">Active Users</p>
                  </div>
                  <div>
                    <p className="pfm-result-text">3X</p>
                    <p className="pfm-result-content">Car Trading Revenue</p>
                  </div>
                  <div>
                    <p className="pfm-result-text">15+</p>
                    <p className="pfm-result-content">Leading Car Service Centers Enrolled</p>
                  </div> */}
                    <div>
                      <ul className="case-studies-list-image">
                        <li className="case-studies-list-content">
                          Customized workflow that aligned seamlessly with
                          client's manufacturing{" "}
                        </li>
                        <li className="case-studies-list-content">
                          Enhanced visibility for informed decision-making with
                          production data.{" "}
                        </li>
                        <li className="case-studies-list-content">
                          User-friendly interface for rapid adoption & minimal
                          training
                        </li>
                        <li className="case-studies-list-content">
                          Rapid implementation in 2-weeks with immediate
                          benefits.
                        </li>
                        <li className="case-studies-list-content">
                          Smooth Integration with Government APIs and other
                          systems.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row pt-5">
              <div className="col-lg-6">
                <div className="pfm-technology-box-iot w-100  mt-3">
                  <div>
                    <h3 className="pfm-technology-box-heading">
                      Technology we used
                    </h3>
                  </div>
                  <div className="d-flex align-items-center justify-content-around pt-4 pb-5 pfm-studies-views case-studies-iot-mobile">
                    <img
                      className="pmf-studies-image"
                      src={phpLogo}
                      alt="PFM image"
                    />
                    <img
                      className="pmf-studies-image"
                      src={pfmFlutter}
                      alt="PFM image"
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-around pt-4 pb-4 pfm-studies-views case-studies-iot-mobile">
                    <img
                      className="pmf-studies-image"
                      src={DigitalOcean}
                      alt="PFM image"
                    />
                    <img
                      className="pmf-studies-image"
                      src={sqlLogo}
                      alt="PFM image"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="pfm-technology-box-iot  w-100 mt-3">
                  <div>
                    <h3 className="pfm-technology-box-heading">
                      Results & Impact
                    </h3>
                  </div>
                  <div className="pt-3 pfm-studies-views">
                    <div className="d-flex align-items-center case-studies-views-device">
                      <div>
                        <ul className="case-studies-list-image">
                          <li className="case-studies-list-content">
                            Monitoring 500+ Devices in 12 Organizations
                          </li>
                          <li className="case-studies-list-content">
                            90% Premium Subscription Users
                          </li>
                          <li className="case-studies-list-content">
                            10-15 Daily Reports per Device
                          </li>
                          <li className="case-studies-list-content">
                            10,000 Daily Downloads
                          </li>
                          <li className="case-studies-list-content">
                            6-7 New Users per Org
                          </li>
                          <li className="case-studies-list-content">
                            200 Users per Minute
                          </li>
                        </ul>
                      </div>
                      {/* <div>
                    <p className="pfm-result-text-pfm">500+</p>
                    <p className="pfm-result-content-pfm">Monitoring in 12 Organization</p>
                  </div>
                  <div>
                    <p className="pfm-result-text-pfm">6 - 7</p>
                    <p className="pfm-result-content-pfm">New Users per Organization</p>
                  </div>
                  <div>
                    <p className="pfm-result-text-pfm">10,000</p>
                    <p className="pfm-result-content-pfm">Daily Downloads</p>
                  </div> */}
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-around case-studies-views-device">
                 <div>
                    <p className="pfm-result-text-pfm">90%</p>
                    <p className="pfm-result-content-pfm">Premium Subscription Users</p>
                  </div>
                 
                 
                  <div>
                    <p className="pfm-result-text-pfm">10 - 15</p>
                    <p className="pfm-result-content-pfm">&nbsp;Daily Reports Per Devices</p>
                  </div>
                  <div>
                    <p className="pfm-result-text-pfm">200</p>
                    <p className="pfm-result-content-pfm">Users Per Minutes</p>
                  </div>
                  
                 </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row pfm-lets-solve d-flex align-items-center">
            <div className="col-lg-6">
              <h1 className="pfm-lets-solve-heading px-5">
                Lets solve <br />
                your use cases
                <br /> today&nbsp;!
              </h1>
            </div>
            <div className="col-lg-6">
              <p className="pfm-lets-solve-content">
                Transform your idea into a fully functional app with our expert
                app development services.
              </p>
              <div className="pfm-lets-solve-button-views">
                <button
                  type="button"
                  class="btn pfm-lets-solve-button"
                  onClick={openPopUp}
                >
                  Start now
                </button>
                <TransformBusinessForm
                  isOpen={isPopUpOpen}
                  onClose={closePopUp}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="pt-5 p-5 footerViews"
        style={{ backgroundColor: "#111827" }}
      >
        <Footer />
      </section>
    </div>
  );
}

export default CaseStudiesSection;
