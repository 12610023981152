import { useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Router from "./routes";
import "./styles/utils.css";
import "./styles/animations.css";
import Cookies from "js-cookie";
import { useEffect } from "react";
import ReactGA from "react-ga4";
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import { Helmet } from "react-helmet";

export default function App() {
  const location = useLocation();
  const animationPresentView = "animationShown";
  //Google Analytics Code Details
  const TRACKING_ID = "G-5PTB6XMJ76";
  ReactGA.initialize(TRACKING_ID);
  // ReactGA.pageview(document.location.pathname);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Venzo Technology",
  });

  useEffect(() => {
    const animationShown = Cookies.get(animationPresentView);
    if (!animationShown) {
      Cookies.set(animationPresentView, "true", { expires: null });
    }

    const handleBeforeUnload = (event) => {
      console.log(event);
      event.preventDefault();
      return null;
    };

    // window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", removeCookie);

    return () => {
      // window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", removeCookie);
      removeCookie();
    };
  }, []);

  const removeCookie = (event) => {
    console.log(event);
    Cookies.remove(animationPresentView);
  };

  return (
    <TransitionGroup component={null}>
        <Helmet>   
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Router />
      </CSSTransition>
    </TransitionGroup>
  );
}
