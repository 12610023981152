import React, { useEffect, useState } from "react";
import formVector from "../../../images/form-vector.svg";
import checkedImage from "../../../images/form-checkbox.svg";
import uncheckedImage from "../../../images/form-unCheckbox.svg";
import axios from 'axios'
//import toMail from '../../../config/config';
import {toHREnquiryMail, toMail} from '../../../config/config'
import { storage } from '../../../firebase/firebase'
import { v4 } from "uuid";
import { ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";

import { AnimatePresence, motion } from "framer-motion";

const variants = {
  open: {
    opacity: [0, 1],
    scale: 1,
  },
  close: {
    opacity: [1, 0],
    scale: 0,
  },
};

const ApplyNowJobs = ({ isOpen, onClose }) => {
    const [submit, setSubmit] = useState(false);
    const [imagelist, setImagelist] = useState('');
    const [imageUpload, setImageUpload] = useState(null);
    const [emailInput, setEmailInput] = useState({
      name: "",
      email: "",
      mobile: "",
      message: ""
    });
    const [submitSuccess, setSubmitSuccess] = useState(false);

//   useEffect(() => {
//     document.addEventListener("keydown", onClose, false);

//     return () => {
//       document.removeEventListener("keydown", onClose, false);
//     };
//   }, []);
  const initialCheckboxStates = {
    ideation: false,
    marketResearch: false,
    productDefinition: false,
  };

  const [checkboxStates, setCheckboxStates] = useState(initialCheckboxStates);

  const handleCheckboxChange = (checkbox) => {
    setCheckboxStates({
      ...checkboxStates,
      [checkbox]: !checkboxStates[checkbox],
    });
  };

  const checkboxSelected = {
    ideation: checkboxStates.ideation ? checkedImage : uncheckedImage,
    marketResearch: checkboxStates.marketResearch
      ? checkedImage
      : uncheckedImage,
    productDefinition: checkboxStates.productDefinition
      ? checkedImage
      : uncheckedImage,
  };

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  // const checkboxImage = isChecked ? checkedImage : uncheckedImage;

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onClose();
//   };

  const closePopup = (e) => {
    if (e.target.id === "popup-container") {
      onClose();
      setSubmitSuccess(false);
    }
  };

  const handleCancelClick = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    onClose();
  };

  const handleChange = (e) => {
    setEmailInput({ ...emailInput, [e.target.name]: e.target.value });
  }
    const sendEmail =async(event)=>{
    event.preventDefault();
    if (submit) return;
    setSubmit(true)
 

    const body = {
      to: toHREnquiryMail,
      message: " Name:" + " " + emailInput["name"] + " " + " <br> Email:" + " " + emailInput["email"] + " " + " <br> Mobile No:" + " " + emailInput["mobile"] + " " + " <br> Message:" + " " + emailInput["message"] + " " +"<br> Resume:" + " " + imagelist,
      subject: "Venzo Careers Form",
      
    }
    const emailResponse = await axios.post("https://us-central1-venzoadmindev.cloudfunctions.net/sendMail", body);
  //  console.log(emailResponse)
    setEmailInput(
      { name: "",
      email: "",
      mobile: "",
      message: ""}
  )
  try {
    // If the email sending is successful, setSubmitSuccess to true
    setSubmitSuccess(true);
  } catch (error) {
    console.error("Error sending email:", error);
    // Handle the error if needed
  }
  finally {
    setSubmit(false); // Reset submit state of success or failure
  }

  }
  const sendFile =(e)=>{
   
  
    setImageUpload(e.target.files[0])
    const imageRef = ref(storage, `venzofile/${e.target.files[0].name + v4()}`)

    uploadBytes(imageRef, e.target.files[0]).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        console.log(url)
        // setImagelist((prev) => [...prev, url]);
        setImagelist(url)
      })
    })
  }

  useEffect(() => {
    if (submitSuccess) {
      
      const timeoutId = setTimeout(() => {
        setSubmitSuccess(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [submitSuccess]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ scale: 0 }}
        animate={isOpen ? "open" : "close"}
        variants={variants}
        transition={{ duration: 0.3 }}
        className="popup"
        id="popup-container"
        onClick={closePopup}
        style={{left: "none"}}
      >
        <div className={!submitSuccess ? "popup-inner" : "popup-inner-thankyou-views"}>
        {!submitSuccess ? (
          <form onSubmit={sendEmail} className="apply-jobs-form-space">
          <div className="text-center">
              <h2 className="popUpHeading">Join Our Team</h2>
              <p className="popUpContent">
                Kindly fill the below details
              </p>
              </div>
              <img
                src={formVector}
                className="img_View"
                alt="Button"
                style={{
                  display: "initial",
                  width: "990px",
                  height: "17px",
                //  paddingRight: "40px",
                }}
              />
            <div className="scrollable-popup-content-apply-jobs">
           
              <div className="row text-start pt-2 apply-jobs-center-views">
                <h6 className="pb-2 form-subHeading">Personal Information</h6>
                <div className="col">
                  <label
                    for="email"
                    className="mr-sm-2 text-start pb-2 apply-form-label-views"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-input"
                    id="email"
                    placeholder="Enter Name"
                    name="name"
                    value={emailInput["name"]} onChange={handleChange}
                    style={{background: emailInput["name"] ? "#ffffff" : ""}}
                    required
                  />
                </div>
                <div className="col">
                  <label for="email" className="mr-sm-2 pb-2 apply-form-label-views">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control form-input"
                    placeholder="Enter Email"
                    name="email"
                    value={emailInput["email"]} onChange={handleChange}
                    required
                    style={{background: emailInput["email"] ? "#ffffff" : ""}}
                  />
                </div>
              </div>
              <div className="row text-start pt-4 apply-jobs-center-views">
                {/* <h6 className="pb-2 form-subHeading">Business Information</h6> */}
                <div className="col">
                  <label
                    for="email"
                    className="mr-sm-2 text-start pb-2 apply-form-label-views"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    className="form-control form-input"
                    id="email"
                    placeholder="Enter Mobile Number"
                    name="mobile"
                    value={emailInput["mobile"]} onChange={handleChange}
                    required
                    style={{background: emailInput["mobile"] ? "#ffffff" : ""}}
                  />
                </div>
                <div className="col">
                <div class="custom-file">
                    <label class="mr-sm-2 text-start pb-2 apply-form-label-views" for="customFile">Choose file</label>
                    <input type="file" class="custom-file-input" accept='.pdf , .doc , .docx' placeholder='choose file' required onChange={sendFile} />
                    
                </div>
                </div>
              </div>
              <div className="row text-start pt-4 apply-jobs-center-views">
                {/* <h6 className="pb-2 form-subHeading">Business Information</h6> */}
               
               <div class="form-group">
                    <label className="mr-sm-2 text-start pb-2 apply-form-label-views" for="exampleFormControlTextarea1">Message</label>
                    <textarea class="form-control apply-form-text-areas" name='message' id="exampleFormControlTextarea1" rows="3" value={emailInput["message"]} onChange={handleChange} style={{background: emailInput["message"] ? "#ffffff" : ""}} ></textarea>
                </div>
               
              </div>
              </div>

              <div className="row d-flex justify-content-end pt-4 apply-form-button-views">
                <button
                  onClick={handleCancelClick}
                  className="form-button"
                  style={{ background: "transparent", color: "#ffffff" }}
                >
                  Cancel
                </button>
                <button type="submit" className="form-button" disabled={submit}>
                {submit ? "Submitting..." : "Submit"}
                </button>
              </div>
           
          </form> ) : (
            <div className="thank-you-message">  
              <p className='apply-success-heading'>Submitted Successfully</p>
                <p className='apply-success-content'>Thank you for contacting us,<br></br> Our team will reach you soon.</p>
              {/* You can customize this message as needed */}
            </div> 
            )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ApplyNowJobs;
