import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import useDeviceCategory from "../utils/useDimensions";
import { useState } from "react";
import _ from "lodash";

function Page() {
  const deviceCategory = useDeviceCategory();
  const location = useLocation();
  const navigate = useNavigate();
  const caseStudyParams = useParams();
  const caseStudiesTitle = caseStudyParams.id;
  const [reachedTop, setReachedTop] = useState(false);
  //console.log("caseStudiesTitle",caseStudiesTitle);

  // const handleScroll = (e) => {
  //   if (
  //     location.pathname === "/pages/digital-transform" ||
  //     location.pathname === "/pages/product-transform"
  //   ) {
  //     const target = e.target;

  //     console.log(target.scrollTop === 0, target.scrollTop);
  //     setTimeout(setReachedTop(target.scrollTop === 0), 3000);
  //   }
  // };

  // const handleWheel = () => {
  //   if (
  //     reachedTop &&
  //     (location.pathname === "/pages/digital-transform" ||
  //       location.pathname === "/pages/product-transform")
  //   ) {
  //     console.log(reachedTop);
  //     navigate(-1);
  //   }
  // };

  return (
    <div
      id="page"
      // onScroll={_.debounce(handleScroll, 2000, {
      //   leading: false,
      //   trailing: true,
      // })}
      // onWheel={_.debounce(handleWheel, 100, {
      //   leading: true,
      //   trailing: false,
      // })}
      className={`d-flex flex-column ${
        // deviceCategory === "mobile"
        //   ? location.pathname === "/pages/product-development-company" ? "overflow-y-hidden overflow-x-hidden" : "overflow-x-hidden"
        //   : // location.pathname === "/pages/product-development-company" ||
        //   //location.pathname === "/pages/app-development-company" ||
        location.pathname === "/about-us" ||
        location.pathname === "/contact-us" ||
        location.pathname === "/digital-transform" ||
        location.pathname === "/product-transform" ||
        location.pathname === "/case-studies/streamlining-it-training-and-Placements-a-success-story" ||
        location.pathname === "/case-studies/bespoke-erp-solution-for-leading-cookware-manufacturer" ||
        location.pathname === "/case-studies/Enhanced-user-engagement-to-boost-revenue" ||
        location.pathname === "/case-studies/healthcare-iot-for-smarter-device-monitoring" ||
        location.pathname === `/${caseStudiesTitle}`
          ? "overflow-y-auto overflow-x-hidden"
          : "overflow-hidden"
      } h-100`}
    >
      <Navbar />
      <div className="flex-grow-1 mh-100">
        <Outlet />
      </div>
    </div>
  );
}

export default Page;
