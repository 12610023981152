import React, { useEffect, useState } from "react";
import formVector from "../../../images/form-vector.svg";
import checkedImage from "../../../images/form-checkbox.svg";
import uncheckedImage from "../../../images/form-unCheckbox.svg";
import { toHREnquiryMail, toMail } from "../../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

const variants = {
  open: {
    opacity: [0, 1],
    scale: 1,
  },
  close: {
    opacity: [1, 0],
    scale: 0,
  },
};

const TransformProductBusinessForm = ({ isOpen, onClose }) => {
  // useEffect(() => {
  //   document.addEventListener("keydown", onClose, false);

  //   return () => {
  //     document.removeEventListener("keydown", onClose, false);
  //   };
  // }, []);
  const initialCheckboxStates = {
    ideation: false,
    build: false,
    productDefinition: false,
  };

  const [checkboxStates, setCheckboxStates] = useState(initialCheckboxStates);
  const [submit, setSubmit] = useState(false);
  const [emailInput, setEmailInput] = useState({
    name: "",
    email: "",
    lastName: "",
    message: "",
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  // const handleCheckboxChange = (checkbox) => {
  //   setCheckboxStates({
  //     ...checkboxStates,
  //     [checkbox]: !checkboxStates[checkbox],
  //   });
  // };

  const handleCheckboxChange = (checkbox) => {
    setCheckboxStates((prevCheckboxStates) => {
      const updatedCheckboxStates = { ...initialCheckboxStates };
      updatedCheckboxStates[checkbox] = !prevCheckboxStates[checkbox];
      return updatedCheckboxStates;
    });
  };

  const checkboxSelected = {
    ideation: checkboxStates.ideation ? checkedImage : uncheckedImage,
    build: checkboxStates.build ? checkedImage : uncheckedImage,
    productDefinition: checkboxStates.productDefinition
      ? checkedImage
      : uncheckedImage,
  };

  const selectedCheckboxes = Object.keys(checkboxStates).filter(
    (checkbox) => checkboxStates[checkbox]
  );

  const checkboxesContent = selectedCheckboxes.map((checkbox) => {
    switch (checkbox) {
      case "ideation":
        return "Ideation: Have you fully thought through the idea, the problem you are solving, the solution, the target business segment, and the business model?";
      case "build":
        return "Build: Turn your prototypes into high-fidelity products with our comprehensive build phase, ensuring quality and efficiency from the ground up.";
      case "productDefinition":
        return "Product Definition & Prototyping: Define and bring your product to life with precision prototyping, bridging the gap between abstract ideas and tangible solutions.";
      default:
        return "";
    }
  });

  // console.log("checkboxesContent", checkboxesContent);

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  // const checkboxImage = isChecked ? checkedImage : uncheckedImage;

  const handleSubmit = (e) => {
    e.preventDefault();
    // onClose();
    setShouldSubmit(true);
  };

  const closePopup = (e) => {
    if (e.target.id === "popup-container") {
      onClose();
      setSubmitSuccess(false);
    }
  };

  const handleCancelClick = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    onClose();
  };

  const handleChange = (e) => {
    setEmailInput({ ...emailInput, [e.target.name]: e.target.value });
  };
  async function sendEmail(event) {
    //event.preventDefault()
    if (submit) return;
    setSubmit(true);

    const body = {
      to: toMail,
      //message: " Name:" + " " + emailInput["name"] + " " + " <br> Email:" + " " + emailInput["email"] + " " + " <br> lastName No:" + " " + emailInput["lastName"] + " " + " <br> Message:" + " " + emailInput["message"],
      message: `
        Name: ${emailInput.name} <br/>
        Email: ${emailInput.email} <br/>
        Last Name: ${emailInput.lastName} <br/>
        Company Name: ${emailInput.message} <br/>
        Project Journey: <br /> ${checkboxesContent.join("\n")}
      `,
      subject: "Venzo Business Journey From :Business Journey Form",
    };
    const emailResponse = await axios.post(
      "https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/sendMail",
      body
    );
    console.log(emailResponse);
    setEmailInput({
      name: "",
      email: "",
      lastName: "",
      message: "",
    });
    setCheckboxStates(initialCheckboxStates);
    try {
      setSubmitSuccess(true);
    } catch (error) {
      console.error("Error sending email:", error);
    }
    finally {
      setSubmit(false); // Reset submit state of success or failure
    }
  }

  useEffect(() => {
    if (shouldSubmit) {
      // Reset the flag and submit the form
      setShouldSubmit(false);
      sendEmail();
    }
  }, [shouldSubmit]);

  useEffect(() => {
    if (submitSuccess) {
      
      const timeoutId = setTimeout(() => {
        setSubmitSuccess(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [submitSuccess]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ scale: 0 }}
        animate={isOpen ? "open" : "close"}
        variants={variants}
        transition={{ duration: 0.3 }}
        className="popup"
        id="popup-container"
        onClick={closePopup}
      >
        <div className={!submitSuccess ? "popup-inner" : "popup-inner-thankyou-views"}>
        {!submitSuccess ? (
          <form onSubmit={handleSubmit} className="form-space">
            <div>
              <h2 className="popUpHeading">Initiate your business journey</h2>
              <p className="popUpContent text-center">
                Kindly fill the below details
              </p>
              <img
                src={formVector}
                className="img_View"
                alt="Button"
                style={{
                  display: "initial",
                  width: "800px",
                  height: "17px",
                  paddingRight: "40px",
                }}
              />
              <div className="scrollable-popup-content">
              <div className="row text-start pt-2">
                <h6 className="pb-2 form-subHeading">Personal Information</h6>
                <div className="col">
                  <label
                    for="email"
                    className="mr-sm-2 text-start pb-2 form-label-view"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-input"
                    id="email"
                    placeholder="Enter First Name"
                    name="name"
                    value={emailInput["name"]}
                    onChange={handleChange}
                    required
                    style={{background: emailInput["name"] ? "#ffffff" : ""}}
                  />
                </div>
                <div className="col">
                  <label for="email" className="mr-sm-2 pb-2 form-label-view">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-input"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={emailInput["lastName"]}
                    onChange={handleChange}
                    style={{background: emailInput["lastName"] ? "#ffffff" : ""}}
                    required
                  />
                </div>
              </div>
              <div className="row text-start pt-4">
                <h6 className="pb-2 form-subHeading">Business Information</h6>
                <div className="col">
                  <label
                    for="email"
                    className="mr-sm-2 text-start pb-2 form-label-view"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-input"
                    id="email"
                    placeholder="Enter Company Name"
                    name="message"
                    value={emailInput["message"]}
                    onChange={handleChange}
                    required
                    style={{background: emailInput["message"] ? "#ffffff" : ""}}
                  />
                </div>
                <div className="col">
                  <label for="email" className="mr-sm-2 pb-2 form-label-view">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control form-input"
                    placeholder="Enter Email"
                    name="email"
                    value={emailInput["email"]}
                    onChange={handleChange}
                    style={{background: emailInput["email"] ? "#ffffff" : ""}}
                    required
                  />
                </div>
              </div>
              <div className="row text-start pt-4">
                <h6 className="pb-2 form-subHeading">
                  What stage are you in your project journey?
                </h6>
                <div className="row">
                  <div className="col">
                    <div className="form-group form-check">
                      <label className="form-check-label form-subHeading">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={checkboxStates.ideation}
                          onChange={() => handleCheckboxChange("ideation")}
                          style={{ display: "none" }}
                        />
                        <img
                          src={checkboxSelected.ideation}
                          alt="Checkbox"
                          className="form-checkbox-image"
                        />{" "}
                        Ideation
                      </label>
                    </div>
                    <div>
                      <p
                        className="popUpContent"
                        style={{  paddingLeft: "31px" }}
                      >
                        Have you fully thought through the idea, the problem you
                        are solving, the solution, the target business segment,
                        and the business model?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group form-check">
                      <label className="form-check-label form-subHeading">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={checkboxStates.build}
                          onChange={() => handleCheckboxChange("build")}
                          style={{ display: "none" }}
                        />
                        <img
                          src={checkboxSelected.build}
                          alt="Checkbox"
                          className="form-checkbox-image"
                        />{" "}
                        Build
                      </label>
                    </div>
                    <div>
                      <p
                        className="popUpContent"
                        style={{ paddingLeft: "31px" }}
                      >
                        Turn your prototypes into high-fidelity products with
                        our comprehensive build phase, ensuring quality and
                        efficiency from the ground up.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group form-check">
                      <label className="form-check-label form-subHeading">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={checkboxStates.productDefinition}
                          onChange={() =>
                            handleCheckboxChange("productDefinition")
                          }
                          style={{ display: "none" }}
                        />
                        <img
                          src={checkboxSelected.productDefinition}
                          alt="Checkbox"
                          className="form-checkbox-image"
                        />{" "}
                        Product Definition & Prototyping
                      </label>
                    </div>
                    <div>
                      <p
                        className="popUpContent"
                        style={{ paddingLeft: "31px" }}
                      >
                        Define and bring your product to life with precision
                        prototyping, bridging the gap between abstract ideas and
                        tangible solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  src={formVector}
                  className="img_View"
                  alt="Button"
                  style={{
                    display: "initial",
                    width: "800px",
                    height: "17px",
                    paddingRight: "40px",
                  }}
                />
              </div>
              </div>
              <div className="d-flex justify-content-end pt-3">
                <button
                  onClick={handleCancelClick}
                  className="form-button"
                  style={{ background: "transparent", color: "#ffffff" }}
                >
                  Cancel
                </button>
                <button type="submit" className="form-button" disabled={submit}>
                {submit ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
          ) : (
                        <div className="thank-you-message pt-5">  
                        <p className='apply-success-heading' style={{color:"#ffffff "}}>Submitted Successfully</p>
                            <p className='apply-success-content' style={{color:"#ffffff "}}>Thank you for reaching us. <br />We will revert back to you within one business day. </p>
                        {/* You can customize this message as needed */}
                        </div> 
                    )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default TransformProductBusinessForm;
