import React, { useState } from "react";
import WriteUsBorder from "../../images/write_us_border.svg";
import { toHREnquiryMail, toMail } from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function WriteUsForm() {
  const [submit, setSubmit] = useState(false);
  const [emailInput, setEmailInput] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const navigate = useNavigate();

  const goto = (path) => {
    navigate(path);
  };

  const handleChange = (e) => {
    setEmailInput({ ...emailInput, [e.target.name]: e.target.value });
  };
  async function sendEmail(event) {
    event.preventDefault();
    if (submit) return;
    setSubmit(true);

    const body = {
      to: toMail,
      message:
        " Name:" +
        " " +
        emailInput["name"] +
        " " +
        " <br> Email:" +
        " " +
        emailInput["email"] +
        " " +
        " <br> Mobile No:" +
        " " +
        emailInput["mobile"] +
        " " +
        " <br> Message:" +
        " " +
        emailInput["message"],
      subject: "Venzo Enquiry From :Contact-us Form",
    };
    const emailResponse = await axios.post(
      "https://us-central1-venzoadmindev.cloudfunctions.net/sendMail",
      body
    );
    console.log(emailResponse);
    setEmailInput({
      name: "",
      email: "",
      mobile: "",
      message: "",
    });
    try {
      setSubmitSuccess(true);
    } catch (error) {
      console.error("Error sending email:", error);
    }
    finally {
      setSubmit(false); // Reset submit state of success or failure
    }
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 px-3">
            {!submitSuccess ? (
              <form onSubmit={sendEmail} className="contact-form-details">
                <div className="">
                  <h1 className="contact-form-heading">Write Us</h1>
                  <img
                    src={WriteUsBorder}
                    className="card-img-top contact-form-heading-border"
                    alt="Asif Aly"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control contact-form-input"
                    autocomplete="off"
                    placeholder="Name"
                    name="name"
                    value={emailInput["name"]}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control contact-form-input"
                    autocomplete="off"
                    placeholder="Email"
                    name="email"
                    value={emailInput["email"]}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control contact-form-input"
                    autocomplete="off"
                    placeholder="Mobile Number"
                    name="mobile"
                    value={emailInput["mobile"]}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control contact-form-input"
                    autocomplete="off"
                    placeholder="Message"
                    name="message"
                    value={emailInput["message"]}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group pt-5">
                  <button
                    type="submit"
                    className="btn btn-primary contact-form-button w-100"
                    disabled={submit}
                  >
                    {submit ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            ) : (
              <div className="thank-you-message pt-5">
                <p
                  className="apply-success-heading"
                  style={{ color: "#ad00ff " }}
                >
                  Submitted Successfully
                </p>
                <p
                  className="apply-success-content"
                  style={{ color: "#ad00ff " }}
                >
                  Thank you for contacting us
                </p>
                {/* You can customize this message as needed */}
              </div>
            )}
          </div>
          <div className="col-lg-6 col-md-12 contact-form-product mt-5 mt-lg-0">
            <div
              className="card contact-form-card-body text-start pb-3"
              style={{ background: "#FFEDF2" }}
            >
              <div className="card-body">
                <h6 className="card-subtitle mb-2 contact-form-card-title">
                  Product Development Journey
                </h6>
                <p className="card-text contact-form-text">
                  Are you a business keen on enhancing your existing systems and
                  operations?
                </p>
                <a
                  className="card-link"
                  style={{ textDecoration: "none" }}
                  onClick={() => goto("/product-development-company")}
                >
                  Explore & Know where you are &rarr;
                </a>
              </div>
            </div>
            <div className="contact-form-digital">
              <div
                className="card contact-form-card-body text-start"
                style={{ background: "#DDE9FF" }}
              >
                <div className="card-body">
                  <h6 className="card-subtitle mb-2 contact-form-card-title">
                    App Development Journey
                  </h6>
                  <p className="card-text contact-form-text">
                    Looking for future-ready solutions to meet evolving business
                    needs?
                  </p>
                  <a
                    href="#"
                    className="card-link"
                    style={{ textDecoration: "none" }}
                    onClick={() => goto("/app-development-company")}
                  >
                    Take our survey & Get your quote &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WriteUsForm;
