import * as React from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({ i }) => {
  const style = { border: `2px solid ${colors[i]}` };
  const location = useLocation();
  const navigate = useNavigate();
console.log(location.pathname)
  const goto = (e, path) => {
    e.preventDefault();
    const url = location.pathname === "/" && path !== "/" ?`${path}` : path;
    console.log(url)
    navigate(url);
  };

  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="text-placeholder" style={style}>
        <a
          key={i.path}
          href={i.path}
          onClick={(e) => goto(e, i.path)}
          className={
            (location.pathname === "/" && i.path === "/") ||
            location.pathname.includes(i.key)
              ? "active cursor-pointer link-item"
              : "cursor-pointer link-item"
          }
        >
          {i.label}
        </a>
        {/* <a
          key={i.path}
          href={i.path}
          className={location.pathname.includes(i.key) ? "active" : ""}
        >
          {i.label}
        </a> */}
      </div>
    </motion.li>
  );
};
