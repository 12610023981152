import React, { useState } from "react";
import JoinWith from "../../../images/join-with-us.png";
import join_us_mobile from "../../../images/join_us_mobile.png";
import useDeviceCategory from "../../../utils/useDimensions";
import ApplyNowJobs from "./ApplyNowJobs";

function JobOpeningsDetails() {
  const deviceCategory = useDeviceCategory();
  const [isPopUpOpen, setPopUpOpen] = useState(false);
  
  const openPopUp = (e) => {
    e.preventDefault();
    setPopUpOpen(true);
  };

  const closePopUp = () => {
    setPopUpOpen(false);
  };

  if (deviceCategory === "mobile") {
    return (
      <div className="container mt-4">
        {/* <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex flex-column align-items-center justify-content-center mb-3">
            <div className="about-company">Be a part of Venzo</div>
            <h1 style={{ color: "#464646", marginTop: 20 }}>
              Current Openings
            </h1>
          </div>
          <div className="row">
            <div className="card" style={{ width: "334px", height: "175px" }}>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 current-openings-card-title">
                  Web Developer - Wordpress & PHP knowledge
                </h6>
                <p className="card-text current-openings-text">
                  2- 3 Years Experience
                </p>
                <a
                  href="#"
                  className="card-link"
                  style={{ textDecoration: "none" }}
                >
                  View Job &rarr;
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="card" style={{ width: "334px", height: "175px" }}>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 current-openings-card-title">
                  Web Developer - Wordpress & PHP knowledge
                </h6>
                <p className="card-text current-openings-text">
                  2- 3 Years Experience
                </p>
                <a
                  href="#"
                  className="card-link"
                  style={{ textDecoration: "none" }}
                >
                  View Job &rarr;
                </a>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row pb-5">
          <div className="col" style={{paddingTop:"20px"}}>
            <div
              className="card"
              style={{
                width: "335px",
                height: "400px",
                // backgroundImage: `url(${join_us_mobile})`,
                backgroundColor:"#e8e5ff"
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-card-titles pt-4">
                Senior Business Development Associate
                </h6>
                {/* <p className="card-text current-openings-text">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p> */}
                <p className="card-text joinus current-openings-text pl-4 pt-3">
                 <span style={{fontWeight:"bold"}}>Job Location :</span> <span>OMR, Thoraipakkam, Chennai </span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <span style={{fontWeight:"bold"}}>Experience :</span> <span>4 to 7 years</span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <a href="assets/files/Senior_Business_Development_Associate.pdf" target="_self">Job Description</a>
                </p>
                <a
                  href=""
                  className="card-link"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
          <div className="col" style={{paddingTop:"20px"}}>
            <div
              className="card"
              style={{
                width: "335px",
                height: "400px",
                // backgroundImage: `url(${join_us_mobile})`,
                backgroundColor:"#e8e5ff"
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-card-titles pt-4">
                Business Development Associate
                </h6>
                {/* <p className="card-text current-openings-text">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p> */}
                <p className="card-text joinus current-openings-text pl-4 pt-3">
                 <span style={{fontWeight:"bold"}}>Job Location :</span> <span>OMR, Thoraipakkam, Chennai </span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <span style={{fontWeight:"bold"}}>Experience :</span> <span>2 to 5 years</span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <a href="assets/files/Venzo_JD_BDE_Website Development_3+ years.pdf" target="_self">Job Description</a>
                </p>
                <a
                  href=""
                  className="card-link"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
          <div className="col" style={{paddingTop:"20px"}}>
            <div
              className="card"
              style={{
                width: "335px",
                height: "400px",
                // backgroundImage: `url(${join_us_mobile})`,
                backgroundColor:"#e8e5ff"
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-card-titles pt-4">
                Content & Communication Strategist
                </h6>
                {/* <p className="card-text current-openings-text">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p> */}
                <p className="card-text joinus current-openings-text pl-4 pt-3">
                 <span style={{fontWeight:"bold"}}>Job Location :</span> <span>OMR, Thoraipakkam, Chennai </span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <span style={{fontWeight:"bold"}}>Experience :</span> <span>2 to 5 years</span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <a href="assets/files/Venzo_JD_Content Strategiest_4+ years.pdf" target="_self">Job Description</a>
                </p>
                <a
                  href=""
                  className="card-link"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
          <div className="col" style={{paddingTop:"20px"}}>
            <div
              className="card"
              style={{
                width: "335px",
                height: "400px",
                // backgroundImage: `url(${join_us_mobile})`,
                backgroundColor:"#e8e5ff"
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-card-titles pt-4">
                Digital Marketing Executive
                </h6>
                {/* <p className="card-text current-openings-text">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p> */}
                <p className="card-text joinus current-openings-text pl-4 pt-3">
                 <span style={{fontWeight:"bold"}}>Job Location :</span> <span>OMR, Thoraipakkam, Chennai </span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <span style={{fontWeight:"bold"}}>Experience :</span> <span>2 to 4 years</span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <a href="assets/files/Venzo_JD_Digital Marketing_2-4 years_May 2024.pdf" target="_self">Job Description</a>
                </p>
                <a
                  href=""
                  className="card-link"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-100 mt-lg-0 mt-4 px-5">
      <div className="w-100 mt-lg-0 mt-4">
        {/* <div className="row">
          <div className="col col-lg-4 col-12 pl-3">
            <div className="about-company">Be a part of Venzo</div>
            <h1 style={{ color: "#464646", marginTop: 20 }}>
              Current Openings
            </h1>
          </div>
          <div className="col">
            <div className="card  card-view-openings" style={{ width: "100%", height: "200px" }}>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 current-openings-card-title">
                  Web Developer - Wordpress & PHP knowledge
                </h6>
                <p className="card-text current-openings-text">
                  2- 3 Years Experience
                </p>
                <a
                  href="#"
                  className="card-link"
                  style={{ textDecoration: "none" }}
                >
                  View Job &rarr;
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card  card-view-openings" style={{ width: "343px", height: "200px" }}>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 current-openings-card-title">
                  Web Developer - Wordpress & PHP knowledge
                </h6>
                <p className="card-text current-openings-text">
                  2- 3 Years Experience
                </p>
                <a
                  href="#"
                  className="card-link"
                  style={{ textDecoration: "none" }}
                >
                  View Job &rarr;
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <h1 className="card-subtitle mb-2 job-openings-card-title pt-2 text-center">Join Us</h1> */}
        <div className="row pt-5">
          <div className="col-lg-6">
            <div
              className="card  card-view-openings"
              style={{
                // width: "100%",
                // height: "300px",
                height: "400px",
                //backgroundImage: `url(${JoinWith})`,
                backgroundColor:"#e8e5ff",
                backgroundSize: "100% 100%",
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-openings-card-title pt-2">
                    Senior Business Development Associate
                </h6>
                {/* <p className="card-text joinus current-openings-text pl-4">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p> */}
                <p className="card-text joinus current-openings-text pl-4 pt-3">
                 <span style={{fontWeight:"bold"}}>Job Location :</span> <span>OMR, Thoraipakkam, Chennai </span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <span style={{fontWeight:"bold"}}>Experience :</span> <span>4 to 7 years</span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <a href="assets/files/Senior_Business_Development_Associate.pdf" style={{ textDecoration: "none" }} target="_blank">Job Description</a>
                </p>
                <a
                  href=""
                  className="card-link pl-4"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="card  card-view-openings"
              style={{
                // width: "100%",
                // height: "300px",
                height: "400px",
                //backgroundImage: `url(${JoinWith})`,
                backgroundColor:"#e8e5ff",
                backgroundSize: "100% 100%",
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-openings-card-title pt-2">
                    Business Development Associate
                </h6>
                {/* <p className="card-text joinus current-openings-text pl-4">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p> */}
                <p className="card-text joinus current-openings-text pl-4 pt-3">
                 <span style={{fontWeight:"bold"}}>Job Location :</span> <span>OMR, Thoraipakkam, Chennai </span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <span style={{fontWeight:"bold"}}>Experience :</span> <span>2 to 5 years</span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <a href="assets/files/Venzo_JD_BDE_Website Development_3+ years.pdf" style={{ textDecoration: "none" }} target="_blank">Job Description</a>
                </p>

                <a
                  href=""
                  className="card-link pl-4"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-6">
            <div
              className="card  card-view-openings"
              style={{
                // width: "100%",
                 height: "400px",
                //backgroundImage: `url(${JoinWith})`,
                backgroundColor:"#e8e5ff",
                backgroundSize: "100% 100%",
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-openings-card-title pt-2">
                    Content & Communication Strategist
                </h6>
                {/* <p className="card-text joinus current-openings-text pl-4">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p> */}
                <p className="card-text joinus current-openings-text pl-4 pt-3">
                 <span style={{fontWeight:"bold"}}>Job Location :</span> <span>OMR, Thoraipakkam, Chennai </span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <span style={{fontWeight:"bold"}}>Experience :</span> <span>4 to 6 years</span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <a href="assets/files/Venzo_JD_Content Strategiest_4+ years.pdf" style={{ textDecoration: "none" }} target="_blank">Job Description</a>
                </p>
                <a
                  href=""
                  className="card-link pl-4"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="card  card-view-openings"
              style={{
                // width: "100%",
                // height: "300px",
                height: "400px",
                //backgroundImage: `url(${JoinWith})`,
                backgroundColor:"#e8e5ff",
                backgroundSize: "100% 100%",
              }}
            >
              <div className="card-body text-start align-items-center">
                <h5 className="card-title"></h5>
                <h6 className="card-subtitle mb-2 job-openings-card-title pt-4">
                    Digital Marketing Executive
                </h6>
                {/* <p className="card-text joinus current-openings-text pl-4">
                  Here are some career pathways to consider if you <br />enjoy working on complex problems with a fun team.
                </p> */}
                <p className="card-text joinus current-openings-text pl-4 pt-3">
                 <span style={{fontWeight:"bold"}}>Job Location :</span> <span>OMR, Chennai </span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <span style={{fontWeight:"bold"}}>Experience :</span> <span>2 to 4 years</span>
                </p>
                <p className="card-text joinus current-openings-text pl-4">
                 <a href="assets/files/Venzo_JD_Digital Marketing_2-4 years_May 2024.pdf" style={{ textDecoration: "none" }} target="_blank">Job Description</a>
                </p>
                <a
                  href=""
                  className="card-link pl-4"
                  style={{ textDecoration: "none" }}
                  onClick={openPopUp}
                >
                  Apply Now &rarr;
                </a>
                <ApplyNowJobs isOpen={isPopUpOpen} onClose={closePopUp} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobOpeningsDetails;
