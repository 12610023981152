import React from "react";
import Asif_Boss from "../../../images/asif.png";
import Balaji_Boss from "../../../images/balaji.png";
import Ravi_Boss from "../../../images/ravi.png";
import indeedIcon from "../../../images/IndeedIcon.png";
import leaderMarker from "../../../images/leader_marker.svg";

function LeadershipTeam() {
  const openIndeedLink = () => {
    window.open("https://www.indeed.com", "_blank");
  };

  return (
    <div>
      <div className="container">
        <div>
          <h1 className="leaderShip-heading" style={{ color: "#EC3939" }}>
            Our Leadership team
            <img
              src={leaderMarker}
              className="leadership-image-marker"
              alt="Asif Aly"
            />
          </h1>
          <p className="leadership-content">
              With over 50 years of combined experience, we've got a seasoned team of experts to lead Venzo Technologies. 
          </p>
        </div>
        <div className="row">
          <div className="col col-lg-4 col-12">
            <div className="card leadership-cards">
              <div className="card-img-container-views">
                <div className="position-absolute w-100 card-overlay"></div>
                <img
                  src={Asif_Boss}
                  className="card-img-top leadership-image"
                  alt="Asif Aly"
                />
                <a href="https://www.linkedin.com/in/asifaly/" class="pe-auto" target="_blank">
                <img
                  src={indeedIcon}
                  className="linked-icon-views"
                  alt="Asif Aly"
                  //onClick={()=>{window.open("https://www.linkedin.com/in/asifaly/", "_blank");}}
                />
                </a>
                {/* <a href="#" className="linked-icon-views">Link</a> */}
              </div>
              <div className="card-body">
                <h5 className="card-title leadership-title">
                  Asif Aly, <span>Co-Founder</span>
                </h5>
                <p className="card-text leadership-text">
                After a 12-year stint donning various hats as a Project leader, Pre-Sales Consultant, Functional Consultant, and Trade-finance professional, he took the entrepreneurial plunge in his early 30s with a relentless drive for innovation and a passion to best align technology with customer business plans. 
                </p>
              </div>
            </div>
          </div>
          <div className="col col-lg-4 col-12">
            <div className="card leadership-cards">
              <div className="card-img-container-views">
                <div className="position-absolute w-100 card-overlay"></div>
                <img
                  src={Balaji_Boss}
                  className="card-img-top leadership-image"
                  alt="Balaji"
                />
                <a href="https://www.linkedin.com/in/balaji-venzo/" class="pe-auto" target="_blank">
                <img
                  src={indeedIcon}
                  className="linked-icon-views"
                  alt="Balaji"
                />
                </a>
                {/* <a href="#" className="linked-icon-views">Link</a> */}
              </div>
              <div className="card-body">
                <h5 className="card-title leadership-title">
                  Balaji, <span style={{ fontSize: "25px" }}>Co-Founder</span>
                </h5>
                <p className="card-text leadership-text">
                    Trade Finance professional with experience of more than 18 years in all facets of trade finance operations and handling projects of different natures like in-sourcing, core banking, online banking & merger of banks. 
                </p>
              </div>
            </div>
          </div>
          <div className="col col-lg-4 col-12">
            <div className="card leadership-cards">
              <div className="card-img-container-views">
                <div className="position-absolute w-100 card-overlay"></div>
                <img
                  src={Ravi_Boss}
                  className="card-img-top leadership-image"
                  alt="Ravi"
                />
                <a href="https://www.linkedin.com/in/ravindran-subbaiah-74824369/" class="pe-auto" target="_blank">
                <img
                  src={indeedIcon}
                  className="linked-icon-views"
                  alt="Ravi"
                />
                </a>
                {/* <a href="#" className="linked-icon-views">Link</a> */}
              </div>
              <div className="card-body">
                <h5 className="card-title leadership-title">
                  Ravi, <span style={{ fontSize: "25px" }}>Co-Founder</span>
                </h5>
                <p className="card-text leadership-text">
                    CEG Alumnus and a Business Leader with 17 plus years of rich experience in handling Finance and Operations. Expertise in handling a diverse range of operations, including areas involving Strategy, Engineering, Delivery, Marketing, Program Management for software products and services. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadershipTeam;
