import React,{useState} from "react";
import dotted_vector from "../../../images/dotted_vector.png";
import TransformProductBusinessForm from "../../DigitalTrans/TransformBusiness/TransformProductBusinessForm";

export default function Phase1(props) {
  const { openPopUp } = props;

  return (
    <div>
    <div className="d-flex justify-content-space-between align-items-center gap-lg-5">
      <div className="phase-vector">
        <img alt="dotted_vector" src={dotted_vector} />
      </div>
      <div>
        <div className="phase-title">Idea Refinement</div>
        <div className="phase-summary">
          <ul>
            <li>Deconstruct Idea on Lean Canvas</li>
            <li>Desirability Check</li>
            <li>Feasibility Check</li>
            <li>Viability Check</li>
            <li>High Concept Pitch</li>
          </ul>
          <button
            type="button"
            className="btn bg-transparent product-ctc-button" onClick={openPopUp}>Map Your Ideas With Lean Canvas &gt;</button>
        </div>
      </div>
     
    </div>
   
    </div>
  );
}
