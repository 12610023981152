import React from "react";
import vectorAddress from "../../images/vector-address.svg";

function AddressDetails() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <h1 className="contact-form-address-heading">Address</h1>
          <div className="col-lg-5 col-md-12">
            <h6 className="contact-form-address-city">CHENNAI</h6>
            <div>
              <p className="contact-form-address-content">
                Spero Sierra, 1st Floor, Awfis,Plot No 21,
                <br />
                Bethel Nagar Street, Industrial
                <br />
                Estate,Perungudi , Chennai – 600096,
                <br />
                Tamil Nadu, India.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <h6 className="contact-form-address-city">MADURAI</h6>
            <div>
              <p className="contact-form-address-content">
                No: 407-3, 1st Floor,
                <br />
                Shree Chest And ENT Centre,
                <br />
                Sivagangai main road, Madurai – 625020,
                <br />
                Tamil Nadu, India.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <h6 className="contact-form-address-city">USA</h6>
            <div>
              <p className="contact-form-address-content">
                Venzo Technologies Inc.
                <br />
                16192 Coastal Highway,
                <br />
                Lewes, DE 19958,
                <br />
                United States of America.
              </p>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <img src={vectorAddress} className="card-img-top" alt="vector" />
        </div>
        <div className="row pt-5">
          <div className="col-lg-5">
            <h1 className="contact-form-address-heading">Phone</h1>
            <h6 className="contact-form-address-city">USA</h6>
            <p className="contact-form-address-content">+1 325 271 4695</p>
          </div>
          {/* <div className="col-lg-2">
            <h1 className="contact-form-address-heading">&nbsp;</h1>
            <h6 className="contact-form-address-city">INDIA</h6>
            <p className="contact-form-address-content">+91 984 059 4865</p>
          </div> */}
          <div className="col-lg-4">
            <h1 className="contact-form-address-heading">Email</h1>
            <h6 className="contact-form-address-city">BUSINESS</h6>
            <p className="contact-form-address-content">
              bd@venzotechnologies.com
            </p>
          </div>
          <div className="col-lg-3">
            <h1 className="contact-form-address-heading">&nbsp;</h1>
            <h6 className="contact-form-address-city">CAREERS</h6>
            <p className="contact-form-address-content">
              hrteam@venzotechnologies.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressDetails;
