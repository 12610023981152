import React, { useState, useEffect } from "react";
import ClientTrustUs from "../components/DigitalTrans/TransformBusiness/ClientTrustUs";
import CaseStudies from "../components/DigitalTrans/TransformBusiness/CaseStudies";
import FromBlogs from "../components/DigitalTrans/TransformBusiness/FromBlogs";
import Footer from "../components/DigitalTrans/TransformBusiness/Footer";
import transform_dotted_vector from "../images/transform_dotted_vector.png";
import clientUsBg from "../images/case_studies_bg.svg";
import client_bg_view from "../images/client_us_bg.svg";
import transform_bg from "../images/transform_business_bg.svg";
import transform_arc_vector from "../images/transform_arc_vector.png";
import TransformProductBusinessForm from "../components/DigitalTrans/TransformBusiness/TransformProductBusinessForm";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

function TransformProductSlides() {
  const [isPopUpOpen, setPopUpOpen] = useState(false);
  const [reachedTop, setReachedTop] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const navigate = useNavigate();
  const page = document.getElementById("page");

  const openPopUp = () => {
    setPopUpOpen(true);
  };

  const closePopUp = () => {
    setPopUpOpen(false);
  };

  useEffect(() => {
    if (!pageLoaded) {
      setTimeout(() => setPageLoaded(true), 2000);
    }
  }, [pageLoaded]);

  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     const deltaY = e.deltaY;

  //     setViewDeltaY((prev) => prev + deltaY);

  //     const swipeSizes = 200;

  //     if (viewDeltaY < -swipeSizes && !isPopUpOpen) {
  //       navigate("/pages/product-development-company", {
  //         state: {
  //           path: "transform",
  //           slide: 3,
  //           phase: 6,
  //         },
  //       });

  //       setViewDeltaY(0);
  //     }
  //   };

  //   const handleTouchStart = (e) => {
  //     const touchStart = e.targetTouches[0].clientY;
  //     document.addEventListener("touchmove", handleTouchMove);

  //     document.addEventListener("touchend", function handleTouchEnd() {
  //       document.removeEventListener("touchmove", handleTouchMove);
  //       document.removeEventListener("touchend", handleTouchEnd);
  //     });

  //     function handleTouchMove(e) {
  //       const touchEnd = e.changedTouches[0].clientY;
  //       const distance = touchStart - touchEnd;

  //       setViewDeltaY((prev) => prev + distance);

  //       //const swipeSizes = 200;

  //       if (viewDeltaY > 50 && !isPopUpOpen) {
  //         // navigate("/pages/app-development-company");
  //         navigate("/pages/product-development-company", {
  //           state: {
  //             path: "transform",
  //             slide: 3,
  //             phase: 6,
  //           },
  //         });
  //       }
  //     }
  //   };

  //   const isMobileView = window.innerWidth < 786;
  //   const eventListener = isMobileView ? "touchstart" : "wheel";
  //   const eventHandler = isMobileView ? handleTouchStart : handleScroll;

  //   document.addEventListener(eventListener, eventHandler);

  //   return () => {
  //     document.removeEventListener(eventListener, eventHandler);
  //   };
  // }, [viewDeltaY, isPopUpOpen, navigate]);

  const onTouchEnd = (e) => {
    console.log(page.scrollTop, e.changedTouches);
    if (reachedTop && !isPopUpOpen) {
      navigate("/product-development-company", {
        state: {
          path: "transform",
          slide: 3,
          phase: 6,
        },
      });
    }
    if (pageLoaded) {
      setReachedTop(page.scrollTop === 0);
    }
  };

  const handleScroll = (e) => {
    setTimeout(setReachedTop(page.scrollTop === 0), 3000);
  };

  const handleWheels = (e) => {
    console.log(e.deltaY);
    if (e.deltaY < 0 && reachedTop && !isPopUpOpen) {
      navigate("/product-development-company", {
        state: {
          path: "transform",
          slide: 3,
          phase: 6,
        },
      });
    }
    if (pageLoaded) {
      setReachedTop(page.scrollTop === 0);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className=""
      onScroll={_.debounce(handleScroll, 500, {
        leading: false,
        trailing: true,
      })}
      onWheel={_.debounce(handleWheels, 1500, {
        leading: true,
        trailing: false,
      })}
      onTouchEnd={onTouchEnd}
    >
      <section
        style={{
          backgroundImage: `url(${transform_bg})`,
        }}
        className="row d-flex flex-direction-column justify-content-center align-items-center transform-image transform-section"
      >
        <img
          src={transform_dotted_vector}
          className="d-md-block d-none"
          alt="vector"
        />
        <div
          className="row d-flex flex-direction-column justify-content-center align-items-center transformWidth"
          style={{ width: "86%" }}
        >
          <div
            className="col d-flex flex-column align-items-center"
            style={{ paddingTop: "10%" }}
          >
            <h2
              className="transform-business-text-product"
              style={{ backgroundImage: `url(${transform_arc_vector})` }}
            >
              Ready to Start Your Business with Venzo?
            </h2>
            <p className="transform-business-content-text">
              Kickstart your Product development with an MVP for effective
              concept validation and refinement. Use our Lean Canvas
              questionnaire for a tailored product development strategy guided
              by expert precision.
            </p>
          </div>
        </div>
        <div
          className="row justify-content-center d-flex flex-direction-column justify-content-center align-items-center"
          style={{ paddingBottom: "10%" }}
        >
          <button
            type="button"
            className="btn btn-primary startNow_button"
            onClick={openPopUp}
          >
            Start Now
          </button>
          <TransformProductBusinessForm
            isOpen={isPopUpOpen}
            onClose={closePopUp}
          />
        </div>
        <img
          src={transform_dotted_vector}
          className="d-md-block d-none"
          alt="vector"
        />
      </section>

      <section
        className="pt-md-5 p-md-5 pt-4 p-4 clientTrust d-md-block d-flex justify-content-center"
        style={{
          backgroundImage: `url(${client_bg_view})`,
          backgroundSize: "cover",
        }}
      >
        <ClientTrustUs />
      </section>
      <section
        className="pt-md-5 p-md-5 pt-4 p-4 caseStudyView"
        style={{
          backgroundImage: `url(${clientUsBg})`,
          backgroundSize: "cover",
        }}
      >
        <CaseStudies />
      </section>
      {/* <section
        className="p-5 formBlogViews"
        style={{ backgroundColor: "#111827", paddingTop: "5rem" }}
      >
        <FromBlogs />
      </section> */}
      <section
        className="pt-5 p-5 footerViews"
        style={{ backgroundColor: "#111827" }}
      >
        <Footer />
      </section>
    </motion.div>
  );
}

export default TransformProductSlides;
