import React from "react";
import "../App.css";
import VENZO_WHITE_LOGO from "../images/venzo_white_logo.png";
import facebook_white from "../images/facebook_white.png";
import instagram_white from "../images/instagram_white.png";
import linkedin_white from "../images/linkedin_white.png";
import youtube_white from "../images/youtube_white.png";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

function TempHome() {
  const navigate = useNavigate();

  const goto = (path) => {
    navigate(path);
  };

  return (
    <div
      className="w-100 h-100"
      style={{ background: "#051E46", color: "white" }}
    >
      <Helmet>
        <title>Venzo Technologies | We're coming soon with a new look</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Be ready to transform your trade-finance business with our
          services"
        />
      </Helmet>
      <div className="h-100 d-flex flex-column justify-content-between align-items-center py-5 white-shadow">
        <div className="d-flex flex-column justify-content-between align-items-center h-50">
          <img src={VENZO_WHITE_LOGO} className="temp-white-logo" alt="Logo" />
          <div className="mt-4 mt-lg-3 d-flex flex-column justify-content-center align-items-center px-2">
            <div className="coming-soon">We're coming soon with a new look</div>
            <div className="be-ready">
              Be ready to transform your trade-finance business with our
              services
            </div>
            <div className="mt-3 d-flex justify-content-center align-items-center cta-buttons">
              <div className="btn" onClick={() => goto("/about-us")}>
                Company
              </div>
              <div> | </div>
              <div className="btn" onClick={() => goto("/contact-us")}>
                Contact Us
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <a
            target="_blank"
            href="https://www.facebook.com/VenzoTechnologies/"
            rel="noreferrer"
          >
            <img src={facebook_white} alt="facebook" />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/venzo_tech/"
            rel="noreferrer"
          >
            <img src={instagram_white} alt="instagram" />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/venzo-technologies/"
            rel="noreferrer"
          >
            <img src={linkedin_white} alt="youtube" />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/@venzotechnologies9121"
            rel="noreferrer"
          >
            <img src={youtube_white} alt="linkedin" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default TempHome;
