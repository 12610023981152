import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap-icons/font/bootstrap-icons.css";
import "@fontsource/lexend"; // Defaults to weight 400

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

//
import App from "./App";

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);
