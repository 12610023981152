import { useEffect, useState } from "react";
import _ from "lodash";
import { AnimatePresence, motion } from "framer-motion";

import Slide1 from "../components/ProductDev/Slide1";
import Slide2 from "../components/ProductDev/Slide2";
import Slide3 from "../components/ProductDev/Slide3";

import { animation_direction } from "../utils/constants";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

const MAX_SLIDES = 3;

const minSwipeDistance = 5;

function ProductDevelopment() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [startAnimation, setStartAnimation] = useState("initial");
  const [inProgress, setInProgress] = useState(false);
  const [slideInOut, setSlideInOut] = useState("");
  const [phase, setPhase] = useState(0);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  //console.log("pathname", pathname);
  

  useEffect(() => {
    if (state && state.path === "transform") {
      window.addEventListener("beforeunload", () => navigate(pathname, {}));
      setInProgress(true);
      setCurrentSlide(state.slide);
      setPhase(state.phase);
      // setTimeout(() => navigate(pathname, {}), 1000);
      setTimeout(() => setInProgress(false), 1500);
      return () => {
        window.removeEventListener("beforeunload", () =>
          navigate(pathname, {})
        );
      };
    }
  }, [state?.path]);

  const changeSlide = (e) => {
    if (inProgress || (currentSlide === 3 && phase !== 1)) {
      return;
    }
    setInProgress(true);
    const deltaY = e.deltaY;

    if (deltaY > 0 && currentSlide < MAX_SLIDES && !isFormOpen) {
      nextSlide();
    } else if (deltaY < 0 && currentSlide > 1 && !isFormOpen) {
      prevSlide();
    }
    setTimeout(() => setInProgress(false), 1500);
  };

  const nextSlide = () => {
    if (inProgress || currentSlide === 3) {
      return;
    }

    setStartAnimation("forward");
    if (currentSlide === 1) {
      setSlideInOut("in");
    } else {
      setSlideInOut("out");
    }
    setTimeout(() => setCurrentSlide(currentSlide + 1), 200);
  };

  const prevSlide = () => {
    setStartAnimation("backward");
    if (currentSlide === 3) {
      setSlideInOut("in");
    } else {
      setSlideInOut("out");
    }
    setTimeout(() => setCurrentSlide(currentSlide - 1), 200);
  };

  const getSlide = (isFormOpen, setIsFormOpen) => {
    switch (currentSlide) {
      case 1:
        return <Slide1 animation={startAnimation} />;
      case 2:
        return <Slide2 animation={startAnimation} direction={slideInOut} />;
      case 3:
        return (
          <Slide3
            animation={startAnimation}
            // direction={slideInOut}
            path={state?.path}
            currentPhase={phase}
            setPhase={setPhase}
            setCurrentSlide={setCurrentSlide}
            isFormOpen={isFormOpen} 
          setIsFormOpen={setIsFormOpen} 
          />
        );
      default:
        return <Slide1 animation={startAnimation} />;
    }
  };

  const debounceSlideChange = _.throttle(changeSlide, 500);

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (
      !touchStart ||
      !touchEnd ||
      inProgress ||
      (currentSlide === 3 && phase !== 1)
    )
      return;

    setInProgress(true);

    const distance = touchStart - touchEnd;
    const isSwipeDown = distance > minSwipeDistance;
    const isSwipeUp = distance < -minSwipeDistance;
    if (isSwipeDown) {
      nextSlide();
    } else if (isSwipeUp) {
      prevSlide();
    }

    setTimeout(() => setInProgress(false), 500);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="main-container h-100"
        onWheel={debounceSlideChange}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Helmet>
          <title>
            Software Product Development Company in India, Chennai | Venzo
          </title>
          <meta
            name="description"
            content="At Venzo, we are a trusted software product development company that offers end-to-end solutions for startups, SMEs, and large enterprises."
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        {getSlide(isFormOpen, setIsFormOpen)}
      </motion.div>
    </AnimatePresence>
  );
}

export default ProductDevelopment;
