import excel_icon from "../../images/react_icon.svg";
import code_icon from "../../images/code_icon.svg";
import laptop_icon from "../../images/laptop_icon.svg";
import bulb_icon from "../../images/new_bulb_icon.svg";
import html_icon from "../../images/cloud_icon.svg";
import js_icon from "../../images/js_icon.svg";
import report_icon from "../../images/report_icon.svg";

import { motion } from "framer-motion";
import useDeviceCategory from "../../utils/useDimensions";
import { useEffect, useState } from "react";
import { wrap } from "popmotion";

const duration = 0.8;

const variants = {
  container: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      y: -300,
    },
    backward: {
      y: [-300, 0],
    },
  },
  excel_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -500,
      y: 500,
      rotate: -20,
    },
    backward: { x: [-500, 0], y: [500, 0], rotate: 0 },
  },
  code_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -800,
      y: 300,
      rotate: 20,
    },
    backward: { x: [-800, 0], y: [300, 0], rotate: 0 },
  },
  bulb_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -250,
      y: 350,
      rotate: 20,
    },
    backward: { x: [-250, 0], y: [350, 0], rotate: 0 },
  },
  laptop_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -50,
      y: 450,
      rotate: 20,
    },
    backward: { x: [-50, 0], y: [450, 0], rotate: 0 },
  },
  html_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -50,
      y: 450,
      rotate: 20,
    },
    backward: { x: [-80, 0], y: [450, 0], rotate: 0 },
  },
  html_icon_1: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -350,
      y: 250,
      rotate: 20,
    },
    backward: { x: [-80, 0], y: [450, 0], rotate: 0 },
  },
  code_icon_1: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -50,
      y: 450,
      rotate: 20,
    },
    backward: { x: [-50, 0], y: [450, 0], rotate: 0 },
  },
};

const mobile_variants = {
  container: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      y: -300,
    },
    backward: {
      y: [-300, 0],
    },
  },
  excel_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: 0,
      y: 600,
      rotate: -20,
    },
    backward: { x: [0, 0], y: [600, 0], rotate: 0 },
  },
  code_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: 0,
      y: 600,
      rotate: 20,
    },
    backward: { x: [0, 0], y: [600, 0], rotate: 0 },
  },
  bulb_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: 0,
      y: 600,
      rotate: 20,
    },
    backward: { x: [0, 0], y: [600, 0], rotate: 0 },
  },
  laptop_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -50,
      y: 600,
      rotate: 20,
    },
    backward: { x: [-50, 0], y: [600, 0], rotate: 0 },
  },
  html_icon: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -50,
      y: 600,
      rotate: 20,
    },
    backward: { x: [-80, 0], y: [600, 0], rotate: 0 },
  },
  html_icon_1: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: 0,
      y: 250,
      rotate: 20,
    },
    backward: { x: [-80, 0], y: [600, 0], rotate: 0 },
  },
  code_icon_1: {
    initial: { x: 0, y: 0, rotate: 0 },
    forward: {
      x: -50,
      y: 450,
      rotate: 20,
    },
    backward: { x: [-50, 0], y: [450, 0], rotate: 0 },
  },
};

const content = [
  { number: "7+", text: "Years of Experience" },
  { number: "100+", text: "Brilliant minds" },
  { number: "30+", text: "Successful Projects" },
  { number: "90%", text: "Customer Retention" },
];

const sliderVariants = {
  enter: (direction) => {
    return {
      y: direction > 0 ? 200 : 200,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      y: direction < 0 ? 200 : 200,
      opacity: 0,
    };
  },
};

export default function Slide1(props) {
  const animation = props.animation;
  const [[page, direction], setPage] = useState([0, 0]);

  const contentIndex = wrap(0, content.length, page);

  useEffect(() => {
    const intervalId = setInterval(() => paginate(1), 3000);

    return () => clearInterval(intervalId);
  });

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const deviceCategory = useDeviceCategory();

  if (deviceCategory === "mobile") {
    return (
      <motion.div
        animate={animation}
        variants={variants["container"]}
        transition={{ duration }}
        className="d-flex flex-column justify-content-between h-100"
      >
        <div className="focus-container">
          <div className="">
            Why ??? <br />
            <span className="venzo-gradient">Venzo</span> in Product
            <br /> Development
          </div>
          <div
            className="content-text-1 pt-3"
            style={{
              width: 300,
              // height: 84,
            }}
          >
            At Venzo, we recognize the individuality of each product vision and
            create a tailored roadmap with a series of carefully framed steps
            that help achieve your business goals.
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="icons-container">
            <div className="row">
              <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["excel_icon"]}
                  transition={{ duration }}
                  src={excel_icon}
                  alt="excel"
                />
              </div>
              <div className="icon-boxes icon-box-blue"></div>
              <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["code_icon"]}
                  transition={{ duration }}
                  src={code_icon}
                  alt="code"
                />
              </div>
            </div>
            <div className="row">
              <div className="icon-boxes icon-box-red d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["laptop_icon"]}
                  transition={{ duration }}
                  src={laptop_icon}
                  alt="laptop"
                />
              </div>
              <div className="icon-boxes icon-box-red d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["bulb_icon"]}
                  transition={{ duration }}
                  src={bulb_icon}
                  alt="bulb"
                />
              </div>
              <div className="icon-boxes icon-box-red d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["html_icon"]}
                  transition={{ duration }}
                  src={html_icon}
                  alt="html"
                />
              </div>
            </div>
            <div className="row">
              <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["html_icon_1"]}
                  transition={{ duration }}
                  src={report_icon}
                  alt="html"
                />
              </div>
              <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["code_icon_1"]}
                  transition={{ duration }}
                  src={js_icon}
                  alt="code"
                />
              </div>
              <div className="icon-boxes icon-box-blue"></div>
            </div>
          </div>
        </div>

        <motion.div
          key={page}
          custom={direction}
          variants={sliderVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
            duration,
          }}
          drag="y"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          className="d-flex justify-content-center align-items-center gap-3 product-view-bottom"
        >
          <div className="product-success-content-value">
            {content[contentIndex].number}
          </div>
          <div
            className="content-text-1"
            style={{
              width: 160,
            }}
          >
            {content[contentIndex].text}
          </div>
        </motion.div>
      </motion.div>
    );
  }

  if (deviceCategory === "tablet") {
    return (
      <motion.div
        animate={animation}
        variants={variants["container"]}
        transition={{ duration }}
        className="d-flex flex-column justify-content-between h-100"
      >
        <div className="focus-container">
          <div className="pt-2">
            Why ??? <br />
            <span className="venzo-gradient">Venzo</span> in Product
            <br /> Development
          </div>
          <div
            className="content-text-1 pt-4"
            style={{
              width: 400,
              height: 100,
            }}
          >
            At Venzo, we recognize the individuality of each product vision and
            create a tailored roadmap with a series of carefully framed steps
            that help achieve your business goals.
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="icons-container">
            <div className="row">
              <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["excel_icon"]}
                  transition={{ duration }}
                  src={excel_icon}
                  alt="excel"
                />
              </div>
              <div className="icon-boxes icon-box-blue"></div>
              <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["code_icon"]}
                  transition={{ duration }}
                  src={code_icon}
                  alt="code"
                />
              </div>
            </div>
            <div className="row">
              <div className="icon-boxes icon-box-red d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["laptop_icon"]}
                  transition={{ duration }}
                  src={laptop_icon}
                  alt="laptop"
                />
              </div>
              <div className="icon-boxes icon-box-red d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["bulb_icon"]}
                  transition={{ duration }}
                  src={bulb_icon}
                  alt="bulb"
                />
              </div>
              <div className="icon-boxes icon-box-red d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["html_icon"]}
                  transition={{ duration }}
                  src={html_icon}
                  alt="html"
                />
              </div>
            </div>
            <div className="row">
              <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["html_icon_1"]}
                  transition={{ duration }}
                  src={report_icon}
                  alt="html"
                />
              </div>
              <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
                <motion.img
                  animate={animation}
                  variants={mobile_variants["code_icon_1"]}
                  transition={{ duration }}
                  src={js_icon}
                  alt="code"
                />
              </div>
              <div className="icon-boxes icon-box-blue"></div>
            </div>
          </div>
        </div>

        <motion.div
          key={page}
          custom={direction}
          variants={sliderVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="y"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          className="d-flex justify-content-between align-items-center gap-3 mt-1"
        >
          <div className="font-size-66 font-weight-500">
            {content[contentIndex].number}
          </div>
          <div
            className="content-text-1"
            style={{
              width: 160,
            }}
          >
            {content[contentIndex].text}
          </div>
        </motion.div>
      </motion.div>
    );
  }

  return (
    <motion.div
      animate={animation}
      variants={variants["container"]}
      className="d-flex flex-column justify-content-center h-100"
      transition={{ duration }}
    >
      <div
        className={`focus-container ${deviceCategory} d-flex justify-content-between border-bottom border-dark border-1`}
      >
        <div className="pt-5">
          Why ??? <br />
          <span className="venzo-gradient">Venzo</span> in Product
          <br /> Development
        </div>
        <div className={`icons-container ${deviceCategory}`}>
          <div className="row">
            <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
              <motion.img
                animate={animation}
                variants={variants["excel_icon"]}
                transition={{ duration }}
                src={excel_icon}
                alt="excel"
              />
            </div>
            <div className="icon-boxes icon-box-blue"></div>
            <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
              <motion.img
                animate={animation}
                variants={variants["code_icon"]}
                transition={{ duration }}
                src={code_icon}
                alt="code"
              />
            </div>
          </div>
          <div className="row">
            <div className="icon-boxes icon-box-red d-flex justify-content-center align-items-center">
              <motion.img
                animate={animation}
                variants={variants["laptop_icon"]}
                transition={{ duration }}
                src={laptop_icon}
                alt="laptop"
              />
            </div>
            <div className="icon-boxes icon-box-red d-flex justify-content-center align-items-center">
              <motion.img
                animate={animation}
                variants={variants["bulb_icon"]}
                transition={{ duration }}
                src={bulb_icon}
                alt="bulb"
              />
            </div>
            <div className="icon-boxes icon-box-red d-flex justify-content-center align-items-center">
              <motion.img
                animate={animation}
                variants={variants["html_icon"]}
                transition={{ duration }}
                src={html_icon}
                alt="html"
              />
            </div>
          </div>
          <div className="row">
            <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
              <motion.img
                animate={animation}
                variants={variants["html_icon_1"]}
                transition={{ duration }}
                src={report_icon}
                alt="html"
              />
            </div>
            <div className="icon-boxes icon-box-blue d-flex justify-content-center align-items-center">
              <motion.img
                animate={animation}
                variants={variants["code_icon_1"]}
                transition={{ duration }}
                src={js_icon}
                alt="code"
              />
            </div>
            <div className="icon-boxes icon-box-blue"></div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-2 pt-3 text-color-dark-1 pb-3">
        <div
          className="content-text-1"
          style={{
            maxWidth: 450,
            height: 72,
          }}
        >
          At Venzo, we recognize the individuality of each product vision and
          create a tailored roadmap with a series of carefully framed steps that
          help achieve your business goals.
        </div>
        <motion.div
          key={page}
          custom={direction}
          variants={sliderVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="y"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          className="d-flex justify-content-between align-items-center gap-3"
        >
          <div className="font-size-66 font-weight-500">
            {content[contentIndex].number}
          </div>
          <div
            className="content-text-1"
            style={{
              width: 160,
            }}
          >
            {content[contentIndex].text}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}
