import { useState } from "react";
import _ from "lodash";

import Slide1 from "../components/Company/CompanySection/Slide1";
import Slide2 from "../components/DigitalTrans/Slide2";
import Slide3 from "../components/DigitalTrans/Slide3";
import { AnimatePresence } from "framer-motion";

import { animation_direction } from "../utils/constants";
import { Helmet } from "react-helmet";

const MAX_SLIDES = 1;

const slides = {
  1: <Slide1 />,
  2: <Slide2 />,
  3: <Slide3 />,
};
function Company() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [startAnimation, setStartAnimation] = useState("initial");
  const [inProgress, setInProgress] = useState(false);
  const [slideInOut, setSlideInOut] = useState("");
  const [phase, setPhase] = useState(1);

  const changeSlide = (e) => {
    console.log(phase, currentSlide);
    if (inProgress || (currentSlide === 3 && phase >= 1)) {
      e.preventDefault();
      return;
    }
    setInProgress(true);
    const deltaY = e.deltaY;

    if (deltaY > 0 && currentSlide < MAX_SLIDES) {
      setStartAnimation("forward");
      if (currentSlide === 1) {
        setSlideInOut("in");
      } else {
        setSlideInOut("out");
      }
      setTimeout(() => setCurrentSlide(currentSlide + 1), 500);
    } else if (deltaY < 0 && currentSlide > 1) {
      setStartAnimation("backward");
      if (currentSlide === 3) {
        setSlideInOut("in");
      } else {
        setSlideInOut("out");
      }
      setTimeout(() => setCurrentSlide(currentSlide - 1), 600);
    }
    setTimeout(() => setInProgress(false), 1500);
  };

  const getSlide = () => {
    switch (currentSlide) {
      case 1:
        return <Slide1 animation={startAnimation} direction={slideInOut} />;
      case 2:
        return <Slide2 animation={startAnimation} direction={slideInOut} />;
      case 3:
        return (
          <Slide3
            animation={startAnimation}
            direction={slideInOut}
            setPhase={setPhase}
          />
        );
      default:
        return <Slide1 animation={startAnimation} />;
    }
  };

  const debounceSlideChange = _.throttle(changeSlide, 1000);

  return (
    <AnimatePresence>
      <div className="" onWheel={debounceSlideChange}>
      <Helmet>
        <title>About Venzo Technologies</title>
        <meta name="description" content="Venzo Technologies mission is to help businesses leverage the power of technology to drive their growth and success" />
      </Helmet>
        {getSlide()}
      </div>
    </AnimatePresence>
  );
}

export default Company;
